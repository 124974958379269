import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTabBar,
  IonTabButton,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Button from '../Buttons/Button/Button';
import { TextContentContextType } from './context';

import s from '../ConfirmationModal/Common.module.scss';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

export const TextContentModal: React.FC<{
  textConentContextInfo: TextContentContextType;
}> = ({ textConentContextInfo }) => {
  useEffect(() => {
    if (textConentContextInfo?.info?.content)
      (async function () {
        await trackEvent(AnalyticEventsEnum.VIEW__TEXT_CONTENT, {
          placement: textConentContextInfo.info?.placement,
          block: textConentContextInfo.info?.block,
        });
      })();
  }, [textConentContextInfo]);

  if (!textConentContextInfo) return null;

  const { info, setInfo } = textConentContextInfo;
  const close = () => setInfo(undefined);

  return (
    <IonModal
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={!!info?.content}
      onWillDismiss={close}
      className={clsx(s.IonModal, s.IonModal__fullHeight)}
    >
      <IonHeader>
        <IonToolbar mode="ios">
          {/* <p className={clsx(s.Text__Header)}>{info?.header}</p> */}
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>{info?.content}</IonContent>

      <IonTabBar slot="bottom" mode="ios" style={{ border: 'none' }}>
        <IonTabButton tab="no-move">
          <div style={{ height: '10px' }} />
          <Button
            width="230px"
            fill="solid"
            text="Okay"
            color="secondary"
            onClick={close}
          />
        </IonTabButton>
      </IonTabBar>
    </IonModal>
  );
};
