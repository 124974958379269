import React from 'react';
import { IonPage } from '@ionic/react';
import Centralizer from '../components/Centralizer/Centralizer';
import Button from '../components/Buttons/Button/Button';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <Centralizer enableVerticalCentralization>
        <p>404 Page not found</p>

        <Link
          to={{ pathname: '/', state: { allowVisitLandning: true } }}
          style={{ marginTop: '10px' }}
        >
          <Button
            text="Main Page"
            fill="solid"
            type="button"
            color="secondary"
            width="200px"
          />
        </Link>
      </Centralizer>
    </IonPage>
  );
};

export default NotFoundPage;
