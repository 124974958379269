import { createContext, useContext, useState } from 'react';
import { TextContentModal } from './TextContentModal';

export type TextContentType = {
  content: React.ReactNode;
  block: 'reviews' | 'prices' | 'explanations';
  placement: 'landing' | 'product' | 'assistance';
  noReadNext?: boolean;
};

/* Setup */
const useTextContentSetup = () => {
  const [info, setInfo] = useState<TextContentType>();
  return { info, setInfo };
};

/* Context */
export type TextContentContextType =
  | ReturnType<typeof useTextContentSetup>
  | undefined;

const ContactInfoContext = createContext<TextContentContextType>(undefined);

/* Provider */
export const TextContentContextProvider: React.FC<any> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const textConentContextInfo = useTextContentSetup();

  return (
    <ContactInfoContext.Provider value={textConentContextInfo}>
      <TextContentModal textConentContextInfo={textConentContextInfo} />
      {children}
    </ContactInfoContext.Provider>
  );
};

/* Usage */
export const useTextContentContext = () => {
  const context = useContext(ContactInfoContext);

  if (!context) {
    throw new Error('Context is not provided');
  }

  return context;
};
