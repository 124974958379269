import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { IonModal } from '@ionic/react';
import s from '../ContactInfoModal/ContactInfoModal.module.scss';
import { IS_INSTAGRAM } from '../../utils/browser';
import { PhaseOne } from './PhaseOne';
import { PhaseTwo } from './PhaseTwo';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

export const LocationUnavailableModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const [phase, setPhase] = useState(1);
  useEffect(() => {
    setPhase(1);
  }, [isOpen]);

  const modalRef = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal
      ref={modalRef}
      isOpen={isOpen}
      animated={!IS_INSTAGRAM}
      backdropDismiss={false}
      keepContentsMounted
      handle={false}
      onWillDismiss={event => {
        close();
        if (event.detail.role !== 'submit') {
          trackEvent(AnalyticEventsEnum.MODAL_CLOSED);
        }
      }}
      className={clsx(s.IonModal)}
    >
      {phase === 1 ? (
        <PhaseOne modalRef={modalRef} nextPhase={() => setPhase(2)} />
      ) : (
        <PhaseTwo modalRef={modalRef} />
      )}
    </IonModal>
  );
};
