import { AlertOptions, ToastOptions } from '@ionic/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastPriorityEnum } from '../../../utils/enums';

export interface ErrorTextable {
  header?: string;
  message?: string;
}

export type ToastType = Omit<ToastOptions, 'positionAnchor'> & {
  priority?: ToastPriorityEnum;
};

interface GlobalStoreState {
  error: ErrorTextable | null;
  alert: AlertOptions | null;
  toastQueue: {
    [ToastPriorityEnum.HIGH]: ToastType[];
    [ToastPriorityEnum.MEDIUM]: ToastType[];
    [ToastPriorityEnum.LOW]: ToastType[];
  };
  screenLock: boolean;
  isHiderVisible: boolean;
}

const initialGlobalState: GlobalStoreState = {
  error: null,
  alert: null,
  toastQueue: {
    [ToastPriorityEnum.HIGH]: [],
    [ToastPriorityEnum.MEDIUM]: [],
    [ToastPriorityEnum.LOW]: [],
  },
  screenLock: false,
  isHiderVisible: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState,
  reducers: {
    setAlert(
      state: GlobalStoreState,
      action: PayloadAction<AlertOptions | null>
    ) {
      state.alert = action.payload;
    },
    pushToastToQueue(
      state: GlobalStoreState,
      action: PayloadAction<ToastType>
    ) {
      const toast = action.payload;
      const priority = toast.priority || ToastPriorityEnum.LOW;

      state.toastQueue = {
        ...state.toastQueue,
        [priority]: [
          ...state.toastQueue[priority],
          toast, // push to the end of queue
        ],
      };
    },
    shiftToastFormQueue(
      state: GlobalStoreState,
      action: PayloadAction<ToastPriorityEnum>
    ) {
      const priority = action.payload;

      state.toastQueue = {
        ...state.toastQueue,
        [priority]: state.toastQueue[priority].slice(1), // shift from the start of queue
      };
    },
    /* used only for errors which could not be handled with 'useAlert' hook from component */
    setError(
      state: GlobalStoreState,
      action: PayloadAction<ErrorTextable | null>
    ) {
      if (JSON.stringify(state.error) === JSON.stringify(action.payload)) {
        return;
      }
      state.error = action.payload;
    },
    changeScreenLock(state: GlobalStoreState, action: PayloadAction<void>) {
      state.screenLock = !state.screenLock;
    },
    setModalHiderVisibility(
      state: GlobalStoreState,
      action: PayloadAction<boolean>
    ) {
      state.isHiderVisible = action.payload;
    },
  },
});
