import React from 'react';
import s from './ActionButton.module.scss';
import clsx from 'clsx';
import { AnalyticEventsEnum, trackEvent } from '../../../../Analytics';

export const InstagramButton = ({ isMini }: { isMini?: boolean }) => {
  return (
    <a
      data-animation
      className={s.InstagramButton__link}
      href="https://instagram.com/eventmaker.app"
      target="_blank"
      rel="noopener noreferrer"
      onClick={async () => {
        await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
          redirectTo: 'instagram',
        });
      }}
    >
      <button
        data-animation
        className={clsx(
          s.ActionButton,
          isMini ? s.ActionButton__mini : undefined,
          s.InstagramButton__background
        )}
      >
        <img
          src="./svg/instagram.svg"
          alt="instagram"
          style={{ width: '24px', height: '24px' }}
        />
        eventmaker.app <img src="./svg/arrowAction_Common.svg" alt="" />
      </button>
    </a>
  );
};
