import React, { useEffect, useRef, useState } from 'react';
import s from './EventTypes.module.scss';
import clsx from 'clsx';
import { ActionButton } from '../../ActionButton/ActionButton';
import { eventTypes } from './config';
import useOnScreen from '../../../../../hooks/hook.observe';
import { useLandingPageContext } from '../../../context';
import Centralizer from '../../../../../components/Centralizer/Centralizer';

export const EventTypes = () => {
  const [activeItem, setActiveItem] = useState(0);

  const ref = useRef<HTMLUListElement>(null);

  const isOnScreen = useOnScreen(ref);

  useEffect(() => {
    if (isOnScreen) {
      // setInterval(() => {
      //   setActiveItem(item => {
      //     if (item === eventTypes.length - 1) {
      //       return 0;
      //     } else {
      //       return item + 1;
      //     }
      //   });
      // }, 3000);
    }
  }, [isOnScreen]);

  const text = '• ' + eventTypes[activeItem].services.join('\n• ');

  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section section-order={2}>
      <div className={s.section}>
        <div className={s.section__left}>
          <h2
            data-animation
            style={{ maxWidth: '700px', whiteSpace: 'pre-wrap' }}
          >
            {/* All event industry in single catalog. Create <span>any event</span>{' '}
            you want. */}
            {/* Single catalog to plan anything from <span>wedding</span> to{' '}
            <span>corporate</span> events */}
            {/* Create any event you want with full access to service providers */}
            {/* Find reliable service providers and create <span>any event</span>{' '}
            you want */}
            {/* ==== */}
            {/* One catalog with many offerings for your upcoming event */}
            {/* Explore offerings from <span>various businesses</span> and create
            the event you want */}
            {/* Everything for <span>your event</span> in one place  */}
            {/* Book all event <span>vendors</span> and <span>professionals</span>{' '}
            in one place */}
            Plan any event <span>yourself</span>
            {`\n`}or contact <span>experts</span> for help
          </h2>
          <ul data-animation ref={ref}>
            {eventTypes.map((item, index) => (
              <li
                key={index}
                className={clsx(index === activeItem && s.activeItem)}
                onMouseEnter={() => setActiveItem(index)}
              >
                <span />
                {item.text}
              </li>
            ))}
          </ul>
          <span className={s.animation} data-animation>
            <ActionButton />
          </span>
        </div>

        <div
          className={s.slideImageContainer}
          style={{
            backgroundImage: `url(${eventTypes[activeItem].src})`,
            width: '500px',
            height: '500px',
          }}
        >
          <Centralizer enableVerticalCentralization>
            <p className={s.slideOverlappedText} data-text={text}>
              {text}
            </p>
          </Centralizer>
        </div>
      </div>
    </section>
  );
};
