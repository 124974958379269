import { Stack } from '@mui/material';
import { Text } from '../atoms/Text/Text';
import Button from '../Buttons/Button/Button';
import s from './CookiesBanner.module.scss';
import { useIsBigScreen } from '../../hooks/hook.big-screen';
import clsx from 'clsx';
import { TextLink } from '../TextLink/TextLink';
import { useState } from 'react';
import { CONSENT_KEY, maximumConsent, onConsentChange } from './helpers';
import { useTypedSelector } from '../../redux/hooks';
import { COUNTRIES_REQUIRES_CONSENT } from './config';
import { useIonRouter } from '@ionic/react';
import { CookiesModal } from './CookiesModal';

export const CookiesBanner = () => {
  const { isBigScreen } = useIsBigScreen('900px');

  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  const { detectedLocation } = useTypedSelector(s => s.location);
  const countryCode = detectedLocation?.countryCode;

  const [confirmed, setConfirmed] = useState(false);
  const consetProvided = !!localStorage.getItem(CONSENT_KEY);
  const consentRequired = COUNTRIES_REQUIRES_CONSENT.includes(
    countryCode || '??'
  );

  const router = useIonRouter();
  if (
    router.routeInfo.pathname === '/privacy' ||
    router.routeInfo.pathname === '/terms'
  )
    return null;

  if (!consetProvided && consentRequired)
    return (
      <div
        className={clsx(
          s.CookiesBanner,
          !isBigScreen ? s.CookiesBanner__mobile : undefined
        )}
      >
        {isOpen && (
          <CookiesModal
            isOpen={isOpen}
            close={close}
            consentRequired={consentRequired}
          />
        )}

        <Text fontWeight={600} fontSize="16px" color="black">
          We use cookies
        </Text>
        <Text color="black" fontSize="14px">
          It helps to improve your experience and personalize ads. By clicking
          OK, you accept all cookies and our{' '}
          <TextLink
            color="black"
            title="privacy policy"
            onClick={() => {
              window.open('/privacy', '_blank', 'noopener,noreferrer');
            }}
          />
          .
        </Text>

        <Stack
          direction={isBigScreen ? 'row' : 'column'}
          spacing={isBigScreen ? 1 : 0}
        >
          <Button
            color="secondary"
            text="OK"
            width="100%"
            fill="solid"
            onClick={() => {
              onConsentChange(maximumConsent);
              setConfirmed(true);
            }}
          />
          <Button
            color="primary"
            text="Cookie Settings"
            width="100%"
            fill="solid"
            onClick={() => setIsOpen(true)}
          />
        </Stack>
      </div>
    );

  return null;
};
