import { LocationFullType } from '../../components/Location/config';

export const isSubarray = (parentArray: string[], childArray: string[]) =>
  childArray.every(val => parentArray.includes(val));

export const convertLocationToBackendData = (location: LocationFullType) => {
  return {
    location_place_id: location.place_id,
    location_point: {
      type: 'Point',
      coordinates: [location.coordinates.lng, location.coordinates.lat], // PostGIS: ST_POINT(longitude, latitude)
    },
    location_main_text: location.structured_formatting.main_text || '',
    location_secondary_text:
      location.structured_formatting.secondary_text || '',
    location_country_code: location.countryCode || '',
  };
};
