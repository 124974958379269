export type Coordinates = { lat: number; lng: number };

export type Place = {
  name: string;
  location: Coordinates;
};

export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type LocationFullType = AutocompletePrediction & {
  seoLine: string;
  coordinates: Coordinates;
  countryCode: string;
};

export const getSeoString = ({
  mainText = '',
  secondaryText = '',
}: {
  mainText?: string;
  secondaryText?: string;
}) => {
  return (
    mainText.replace(/\s+/g, '-') +
    '--' +
    secondaryText.replace(/\s+/g, '-')
  ).replace(',', '');
};

export const COUNTRIES: { [key: string]: Place[] } = {
  // World
  Indonesia: [{ name: 'Bali', location: { lat: -8.4095, lng: 115.1889 } }],
  Russia: [
    { name: 'Moscow', location: { lat: 55.7558, lng: 37.6176 } },
    { name: 'Saint Petersburg', location: { lat: 59.9343, lng: 30.3351 } },
  ],
  'United Arab Emirates': [
    { name: 'Dubai', location: { lat: 25.276987, lng: 55.296249 } },
  ],
  'United Kingdom': [
    { name: 'London', location: { lat: 51.5074, lng: -0.1278 } },
    { name: 'Birmingham', location: { lat: 52.4862, lng: -1.8904 } },
    { name: 'Manchester', location: { lat: 53.4808, lng: -2.2426 } },
    { name: 'Liverpool', location: { lat: 53.4084, lng: -2.9916 } },
    { name: 'Edinburgh', location: { lat: 55.9533, lng: -3.1883 } },
  ],
  // Europe
  Spain: [
    { name: 'Madrid', location: { lat: 40.4168, lng: -3.7038 } },
    { name: 'Barcelona', location: { lat: 41.3874, lng: 2.1686 } },
    { name: 'Valencia', location: { lat: 39.4699, lng: -0.3763 } },
    { name: 'Ibiza', location: { lat: 38.9067, lng: 1.4321 } },
    { name: 'Palma de Mallorca', location: { lat: 39.5696, lng: 2.6502 } },
    { name: 'Menorca', location: { lat: 40.0027, lng: 4.2125 } },
    { name: 'Bilbao', location: { lat: 43.263, lng: -2.934 } },
    { name: 'Seville', location: { lat: 37.3886, lng: -5.9823 } },
    { name: 'Marbella', location: { lat: 36.5101, lng: -4.8826 } },
    { name: 'Tenerife', location: { lat: 28.2916, lng: -16.6291 } },
    { name: 'Gran Canaria', location: { lat: 28.1235, lng: -15.4361 } },
  ],
  Portugal: [
    { name: 'Lisbon', location: { lat: 38.7169, lng: -9.139 } },
    { name: 'Porto', location: { lat: 41.1579, lng: -8.6291 } },
    { name: 'Madeira', location: { lat: 32.7607, lng: -16.9595 } },
  ],
  France: [
    { name: 'Paris', location: { lat: 48.8566, lng: 2.3522 } },
    { name: 'Marseille', location: { lat: 43.2965, lng: 5.3698 } },
    { name: 'Lyon', location: { lat: 45.764, lng: 4.8357 } },
  ],
  Germany: [
    { name: 'Berlin', location: { lat: 52.52, lng: 13.405 } },
    { name: 'Munich', location: { lat: 48.1351, lng: 11.582 } },
    { name: 'Hamburg', location: { lat: 53.5511, lng: 9.9937 } },
  ],
  Italy: [
    { name: 'Rome', location: { lat: 41.9028, lng: 12.4964 } },
    { name: 'Milan', location: { lat: 45.4642, lng: 9.19 } },
    { name: 'Naples', location: { lat: 40.8518, lng: 14.2681 } },
  ],
  Poland: [
    { name: 'Warsaw', location: { lat: 52.2297, lng: 21.0122 } },
    { name: 'Krakow', location: { lat: 50.0647, lng: 19.945 } },
    { name: 'Wroclaw', location: { lat: 51.1079, lng: 17.0385 } },
    { name: 'Poznan', location: { lat: 52.4064, lng: 16.9252 } },
    { name: 'Gdansk', location: { lat: 54.352, lng: 18.6466 } },
  ],
  Greece: [
    { name: 'Athens', location: { lat: 37.9838, lng: 23.7275 } },
    { name: 'Thessaloniki', location: { lat: 40.6401, lng: 22.9444 } },
    { name: 'Patras', location: { lat: 38.2466, lng: 21.7346 } },
    { name: 'Mykonos', location: { lat: 37.4467, lng: 25.3289 } },
    { name: 'Santorini', location: { lat: 36.3932, lng: 25.4615 } },
    { name: 'Zakynthos', location: { lat: 37.787, lng: 20.899 } },
    { name: 'Corfu', location: { lat: 39.6243, lng: 19.9217 } },
  ],
  Croatia: [
    { name: 'Zagreb', location: { lat: 45.815, lng: 15.9819 } },
    { name: 'Split', location: { lat: 43.5081, lng: 16.4402 } },
    { name: 'Rijeka', location: { lat: 45.3271, lng: 14.4422 } },
    { name: 'Dubrovnik', location: { lat: 42.6507, lng: 18.0944 } },
  ],
  Netherlands: [
    { name: 'Amsterdam', location: { lat: 52.3676, lng: 4.9041 } },
    { name: 'Rotterdam', location: { lat: 51.9225, lng: 4.4792 } },
    { name: 'The Hague', location: { lat: 52.0705, lng: 4.3007 } },
  ],
  Belgium: [
    { name: 'Brussels', location: { lat: 50.8503, lng: 4.3517 } },
    { name: 'Antwerp', location: { lat: 51.2194, lng: 4.4025 } },
    { name: 'Ghent', location: { lat: 51.0543, lng: 3.7174 } },
  ],
  Luxembourg: [
    { name: 'Luxembourg City', location: { lat: 49.6117, lng: 6.1319 } },
  ],
  Sweden: [{ name: 'Stockholm', location: { lat: 59.3293, lng: 18.0686 } }],
  Austria: [{ name: 'Vienna', location: { lat: 48.2082, lng: 16.3738 } }],
  Ireland: [{ name: 'Dublin', location: { lat: 53.3498, lng: -6.2603 } }],
  Finland: [{ name: 'Helsinki', location: { lat: 60.1699, lng: 24.9384 } }],
  Denmark: [{ name: 'Copenhagen', location: { lat: 55.6761, lng: 12.5683 } }],
  Czechia: [{ name: 'Prague', location: { lat: 50.0755, lng: 14.4378 } }],
  Hungary: [{ name: 'Budapest', location: { lat: 47.4979, lng: 19.0402 } }],
  Romania: [{ name: 'Bucharest', location: { lat: 44.4268, lng: 26.1025 } }],
  Bulgaria: [{ name: 'Sofia', location: { lat: 42.6977, lng: 23.3219 } }],
  Estonia: [{ name: 'Tallinn', location: { lat: 59.437, lng: 24.7536 } }],
  Latvia: [{ name: 'Riga', location: { lat: 56.9496, lng: 24.1052 } }],
  Lithuania: [{ name: 'Vilnius', location: { lat: 54.6872, lng: 25.2797 } }],
  Slovakia: [{ name: 'Bratislava', location: { lat: 48.1486, lng: 17.1077 } }],
  Slovenia: [{ name: 'Ljubljana', location: { lat: 46.0569, lng: 14.5058 } }],
  Malta: [{ name: 'Malta', location: { lat: 35.8997, lng: 14.5146 } }],
  Cyprus: [{ name: 'Cyprus', location: { lat: 35.1264, lng: 33.4299 } }],
  // Rest
  Singapore: [{ name: 'Singapore', location: { lat: 1.3521, lng: 103.8198 } }],
  Georgia: [
    { name: 'Tbilisi', location: { lat: 41.7151, lng: 44.8271 } },
    { name: 'Batumi', location: { lat: 41.6402, lng: 41.6367 } },
    { name: 'Kutaisi', location: { lat: 42.26, lng: 42.7012 } },
  ],
  Turkey: [
    { name: 'Istanbul', location: { lat: 41.0082, lng: 28.9784 } },
    { name: 'Antalya', location: { lat: 36.8856, lng: 30.7038 } },
    { name: 'Bodrum', location: { lat: 37.0382, lng: 27.429 } },
    { name: 'Kaş', location: { lat: 36.202, lng: 29.6395 } },
  ],
  Thailand: [
    { name: 'Bangkok', location: { lat: 13.7563, lng: 100.5018 } },
    { name: 'Chiang Mai', location: { lat: 18.7877, lng: 98.9937 } },
    { name: 'Phuket', location: { lat: 7.8804, lng: 98.3923 } },
    { name: 'Pattaya', location: { lat: 12.9276, lng: 100.877 } },
    { name: 'Phangan', location: { lat: 9.7065, lng: 100.1292 } },
    { name: 'Samui', location: { lat: 9.5125, lng: 100.0134 } },
  ],
};

export const UNITED_STATES: { [key: string]: Place[] } = {
  Alabama: [{ name: 'Birmingham', location: { lat: 33.5186, lng: -86.8104 } }],
  Alaska: [{ name: 'Anchorage', location: { lat: 61.2181, lng: -149.9003 } }],
  Arizona: [{ name: 'Phoenix', location: { lat: 33.4484, lng: -112.074 } }],
  Arkansas: [
    { name: 'Little Rock', location: { lat: 34.7465, lng: -92.2896 } },
  ],
  California: [
    { name: 'Los Angeles', location: { lat: 34.0522, lng: -118.2437 } },
    { name: 'San Francisco', location: { lat: 37.7749, lng: -122.4194 } },
    { name: 'San Diego', location: { lat: 32.7157, lng: -117.1611 } },
    { name: 'Sacramento', location: { lat: 38.5816, lng: -121.4944 } },
    { name: 'San Jose', location: { lat: 37.3382, lng: -121.8863 } },
  ],
  Colorado: [{ name: 'Denver', location: { lat: 39.7392, lng: -104.9903 } }],
  Connecticut: [
    { name: 'Hartford', location: { lat: 41.7637, lng: -72.6851 } },
  ],
  Delaware: [{ name: 'Wilmington', location: { lat: 39.7391, lng: -75.5398 } }],
  Florida: [{ name: 'Miami', location: { lat: 25.7617, lng: -80.1918 } }],
  Georgia: [{ name: 'Atlanta', location: { lat: 33.749, lng: -84.388 } }],
  Hawaii: [{ name: 'Honolulu', location: { lat: 21.3069, lng: -157.8583 } }],
  Idaho: [{ name: 'Boise', location: { lat: 43.615, lng: -116.2023 } }],
  Illinois: [{ name: 'Chicago', location: { lat: 41.8781, lng: -87.6298 } }],
  Indiana: [{ name: 'Indianapolis', location: { lat: 39.7684, lng: -86.158 } }],
  Iowa: [{ name: 'Des Moines', location: { lat: 41.5868, lng: -93.625 } }],
  Kansas: [{ name: 'Wichita', location: { lat: 37.6872, lng: -97.3301 } }],
  Kentucky: [{ name: 'Louisville', location: { lat: 38.2527, lng: -85.7585 } }],
  Louisiana: [
    { name: 'New Orleans', location: { lat: 29.9511, lng: -90.0715 } },
  ],
  Maine: [{ name: 'Portland', location: { lat: 43.6615, lng: -70.2553 } }],
  Maryland: [{ name: 'Baltimore', location: { lat: 39.2904, lng: -76.6122 } }],
  Massachusetts: [
    { name: 'Boston', location: { lat: 42.3601, lng: -71.0589 } },
  ],
  Michigan: [{ name: 'Detroit', location: { lat: 42.3314, lng: -83.0458 } }],
  Minnesota: [
    { name: 'Minneapolis', location: { lat: 44.9778, lng: -93.265 } },
  ],
  Mississippi: [{ name: 'Jackson', location: { lat: 32.2988, lng: -90.1848 } }],
  Missouri: [{ name: 'St. Louis', location: { lat: 38.627, lng: -90.1994 } }],
  Montana: [{ name: 'Billings', location: { lat: 45.7833, lng: -108.5007 } }],
  Nebraska: [{ name: 'Omaha', location: { lat: 41.2565, lng: -95.9345 } }],
  Nevada: [{ name: 'Las Vegas', location: { lat: 36.1699, lng: -115.1398 } }],
  'New Hampshire': [
    {
      name: 'Manchester',
      location: { lat: 42.9956, lng: -71.4548 },
    },
  ],
  'New Jersey': [{ name: 'Newark', location: { lat: 40.7357, lng: -74.1724 } }],
  'New Mexico': [
    {
      name: 'Albuquerque',
      location: { lat: 35.0841, lng: -106.6504 },
    },
  ],
  'New York': [
    {
      name: 'New York',
      location: { lat: 40.7128, lng: -74.006 },
    },
  ],
  'North Carolina': [
    {
      name: 'Charlotte',
      location: { lat: 35.2271, lng: -80.8431 },
    },
  ],
  'North Dakota': [
    { name: 'Fargo', location: { lat: 46.8772, lng: -96.7898 } },
  ],
  Ohio: [{ name: 'Columbus', location: { lat: 39.9612, lng: -82.9988 } }],
  Oklahoma: [
    {
      name: 'Oklahoma City',
      location: { lat: 35.4676, lng: -97.5164 },
    },
  ],
  Oregon: [{ name: 'Portland', location: { lat: 45.5051, lng: -122.675 } }],
  Pennsylvania: [
    {
      name: 'Philadelphia',
      location: { lat: 39.9526, lng: -75.1652 },
    },
  ],
  'Rhode Island': [
    {
      name: 'Providence',
      location: { lat: 41.824, lng: -71.4128 },
    },
  ],
  'South Carolina': [
    {
      name: 'Columbia',
      location: { lat: 34.0007, lng: -81.0348 },
    },
  ],
  'South Dakota': [
    {
      name: 'Sioux Falls',
      location: { lat: 43.5491, lng: -96.7003 },
    },
  ],
  Tennessee: [{ name: 'Nashville', location: { lat: 36.1627, lng: -86.7816 } }],
  Texas: [
    { name: 'Houston', location: { lat: 29.7604, lng: -95.3698 } },
    { name: 'Dallas', location: { lat: 32.7767, lng: -96.797 } },
    { name: 'Austin', location: { lat: 30.2672, lng: -97.7431 } },
    { name: 'San Antonio', location: { lat: 29.4241, lng: -98.4936 } },
  ],
  Utah: [{ name: 'Salt Lake City', location: { lat: 40.7608, lng: -111.891 } }],
  Vermont: [{ name: 'Burlington', location: { lat: 44.4759, lng: -73.2121 } }],
  Virginia: [
    {
      name: 'Virginia Beach',
      location: { lat: 36.8529, lng: -75.9783 },
    },
  ],
  Washington: [{ name: 'Seattle', location: { lat: 47.6062, lng: -122.3321 } }],
  'West Virginia': [
    {
      name: 'Charleston',
      location: { lat: 38.3498, lng: -81.6326 },
    },
  ],
  Wisconsin: [{ name: 'Milwaukee', location: { lat: 43.0389, lng: -87.9065 } }],
  Wyoming: [{ name: 'Cheyenne', location: { lat: 41.14, lng: -104.8202 } }],
};
