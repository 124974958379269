import React, { useEffect, useState } from 'react';
import s from './HowItWorks.module.scss';
import clsx from 'clsx';
import { getHowItWorksConfig, getIconPath } from './config';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { ActionButton } from '../../ActionButton/ActionButton';
import Centralizer from '../../../../../components/Centralizer/Centralizer';
import { useLandingPageContext } from '../../../context';
import { useIsBigScreen } from '../../../../../hooks/hook.big-screen';

const HEADER_HEIGHT = '100px';

export const HowItWorks = () => {
  const { source } = useLandingPageContext();

  const [activeItem, setActiveItem] = useState(0);

  const config = getHowItWorksConfig({ source });

  useEffect(() => {
    const Animation: IntersectionObserverCallback = entry => {
      entry.forEach(change => {
        if (change.isIntersecting) {
          setActiveItem(Number(change.target?.id));
        }
      });
    };

    let HowItWork = document.getElementsByClassName('data-HowItWork');
    let observer2 = new IntersectionObserver(Animation, {
      threshold: [1],
    });
    Array.from(HowItWork).forEach(elm => observer2.observe(elm));
  }, []);

  const { isBigScreen } = useIsBigScreen();

  if (source === 'customer') return null;

  return (
    <section
      section-order={3}
      className={s.section}
      id={buildAnchorId(AnchorEnum.HOW_WORKS)}
    >
      <h3 data-animation className={s.section__title}>
        How does it work?
      </h3>
      <div className={s.section__row}>
        <div data-animation className={s.section__right}>
          {config.map((cfg, index) => (
            <div
              key={index}
              id={String(index)}
              data-animation
              onClick={() => setActiveItem(index)}
              className={clsx(
                s.item,
                isBigScreen && activeItem === index ? s.active : '',
                'data-HowItWork'
              )}
            >
              {!isBigScreen && (
                <img
                  src={getIconPath({ source, index })}
                  className={s.icon}
                  alt=""
                />
              )}

              <p className={s.index}>{index + 1}</p>

              <div>
                <p className={s.item__title}>{cfg.title}</p>
                <p className={s.item__body}>{cfg.description}</p>
              </div>
            </div>
          ))}

          <Centralizer>
            <ActionButton />
          </Centralizer>
        </div>

        {isBigScreen && (
          <div data-animation className={s.section__left}>
            <div
              style={{
                height: `calc(100vh - ${HEADER_HEIGHT} - 80px)`,
                position: 'sticky',
                top: `calc(${HEADER_HEIGHT} + 20px)`,
              }}
            >
              <img src={config[activeItem].img} alt="" />

              <div className={s.nav}>
                {config.map((_, index) => (
                  <div
                    key={index}
                    className={clsx(
                      s.navItem,
                      activeItem === index && s.activeNav
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
