import React from 'react';
import s from './Footer.module.scss';
import {
  AnchorEnum,
  clickAnchor,
  processAnchorClick,
} from '../HeaderMobile/config';
import { useIonRouter } from '@ionic/react';
import { useLandingPageContext } from '../../../context';
import { Link } from 'react-router-dom';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';

export const Footer = () => {
  const { source } = useLandingPageContext();
  const router = useIonRouter();

  const linkProps = { target: '_blank', rel: 'noopener noreferrer' } as const;

  return (
    <footer className={s.footer}>
      <div className={s.container}>
        <div className={s.count2}>
          <div onClick={() => processAnchorClick(AnchorEnum.ABOUT)}>
            <img src="./svg/appLogo_White.svg" className={s.logo} alt="logo" />
          </div>
          <div className={s.links}>
            <a
              href="https://www.instagram.com/eventmaker.app/"
              target="_blank"
              rel="noreferrer"
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'instagram',
                });
              }}
            >
              <img src="./svg/instagram.svg" alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/eventmakerapp"
              target="_blank"
              rel="noreferrer"
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'facebook',
                });
              }}
            >
              <img src="./svg/facebook.svg" alt="facebook" />
            </a>
            <a
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'twitter',
                });
              }}
            >
              <img src="./svg/twitter.svg" alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/eventmaker-app/"
              target="_blank"
              rel="noreferrer"
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'linkedin',
                });
              }}
            >
              <img src="./svg/linkedin.svg" alt="linkedin" />
            </a>
          </div>
          <div className={s.download}>
            <a
              href="https://apps.apple.com/app/eventmaker-vendors/id6479720031"
              target="_blank"
              rel="noreferrer"
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'appstore',
                });
              }}
            >
              <img src="./svg/appstoreWhite.svg" alt="appstore" />
            </a>
            <a
              onClick={async () => {
                await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                  redirectTo: 'googleplay',
                });
              }}
            >
              <img src="./svg/googleplayWhite.svg" alt="googleplay" />
            </a>
          </div>
        </div>

        {/* Customers */}
        <ul>
          <li className={s.nav}>
            <p>Clients</p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.ABOUT,
                  source,
                  toSource: 'customer',
                  placement: 'footer',
                })
              }
            >
              About
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.REVIEWS,
                  source,
                  toSource: 'customer',
                  placement: 'footer',
                })
              }
            >
              Reviews
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.FORM_REQUEST,
                  source,
                  toSource: 'customer',
                  placement: 'footer',
                })
              }
            >
              Consultation
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.FAQ,
                  source,
                  toSource: 'customer',
                  placement: 'footer',
                })
              }
            >
              FAQ
            </p>
          </li>
        </ul>

        {/* Businesses */}
        <ul>
          <li className={s.nav}>
            <p>Businesses</p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.ABOUT,
                  source,
                  toSource: 'business',
                  placement: 'footer',
                })
              }
            >
              Introduction
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.HOW_WORKS,
                  source,
                  toSource: 'business',
                  placement: 'footer',
                })
              }
            >
              How it works
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.FORM_REQUEST,
                  source,
                  toSource: 'business',
                  placement: 'footer',
                })
              }
            >
              Transfer your catalog
            </p>
          </li>
          <li>
            <p
              onClick={async () =>
                await clickAnchor({
                  anchor: AnchorEnum.FAQ,
                  source,
                  toSource: 'business',
                  placement: 'footer',
                })
              }
            >
              Business FAQ
            </p>
          </li>
        </ul>

        {/* Desktop */}
        <ul className={s.nav_desktop}>
          <li className={s.nav}>
            <p>Company</p>
          </li>
          <li onClick={() => router.push('/about')}>
            <p>About company</p>
          </li>

          <Link to="/terms" {...linkProps}>
            <li>
              <p>Terms of usage</p>
            </li>
          </Link>

          <Link to="/privacy" {...linkProps}>
            <li>
              <p>Privacy policy</p>
            </li>
          </Link>

          <li onClick={() => router.push('/support')}>
            <p>Contact support</p>
          </li>
        </ul>

        {/* Mobile */}
        <ul className={s.nav_mobile}>
          <li className={s.nav}>
            <p>Company</p>
          </li>
          <li
            onClick={async () => {
              router.push('/about');
              await trackEvent(AnalyticEventsEnum.OPEN__LANDING_INFO, {
                source,
                toSource: '/about',
              });
            }}
          >
            <p>About company</p>
          </li>
          <li
            onClick={async () => {
              router.push('/support');
              await trackEvent(AnalyticEventsEnum.OPEN__LANDING_INFO, {
                source,
                toSource: '/support',
              });
            }}
          >
            <p>Contact support</p>
          </li>
        </ul>

        <ul className={s.nav_mobile}>
          <li className={s.empty}>
            <p></p>
          </li>

          <Link
            to="/terms"
            {...linkProps}
            onClick={async () => {
              await trackEvent(AnalyticEventsEnum.OPEN__LANDING_INFO, {
                source,
                toSource: '/terms',
              });
            }}
          >
            <li>
              <p>Terms of usage</p>
            </li>
          </Link>

          <Link
            to="/privacy"
            {...linkProps}
            onClick={async () => {
              await trackEvent(AnalyticEventsEnum.OPEN__LANDING_INFO, {
                source,
                toSource: '/privacy',
              });
            }}
          >
            <li>
              <p>Privacy policy</p>
            </li>
          </Link>
        </ul>
      </div>
    </footer>
  );
};
