import { Stack } from '@mui/material';
import Centralizer from '../Centralizer/Centralizer';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { useEffect } from 'react';

import s from './Content.module.scss';

export const ContentPrices = () => {
  // useEffect(() => {
  //   (async function () {
  //     await trackEvent(AnalyticEventsEnum.VIEW__ASSISTANCE_PRICES);
  //   })();
  // });

  return (
    <Centralizer pageLimitSize="500px" enablePagePadding>
      <h3 className={s.HeaderColored}>
        No overpayment — 0% commission for clients
      </h3>

      <Stack direction="column" spacing={2} style={{ lineHeight: '150%' }}>
        <p className={s.RoundParagraph}>
          Only direct prices from service providers, with no hidden fees or
          extra charges.
        </p>

        <p className={s.RoundParagraph}>
          Even if you contact service providers or event managers outside the
          platform — the price stays the same. It's our agreement with
          registered businesses, and we strictly control it.
        </p>
      </Stack>

      <h3 className={s.HeaderColored} style={{ marginTop: '40px' }}>
        How do we make money?
      </h3>

      <p className={s.RoundParagraph}>
        Each business operating on platform paying us 10% comission. For them
        it's cheaper than marketing.
      </p>
    </Centralizer>
  );
};
