import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import BusinessDetail from '../../../components/BusinessDetail/BusinessDetail';
import Page from '../../../components/Page/Page';
import { useGetBusinessQuery } from '../../../redux/api';
import Spinner from '../../../components/Spinner/Spinner';
import { useTypedDispatch } from '../../../redux/hooks';
import { choicesSlice } from '../../../redux/slices/choices/slice';

const UserBusinessPage: React.FC = () => {
  const match = useRouteMatch<{ businessId: string }>();
  const businessId = match.params.businessId;

  const {
    data: business,
    isLoading,
    isFetching,
  } = useGetBusinessQuery({ id: businessId });

  // TODO ASAP: Same as UserProductPage
  // const dispatch = useTypedDispatch();
  // useEffect(() => {
  //   if (business?.city)
  //     dispatch(
  //       choicesSlice.actions.setSelectedCityIfNotExists(business?.city.id)
  //     );
  // }, [business?.city, dispatch]);

  return (
    <Page headerProps={{}} pageLimitDisabled disableDefaultIonContent>
      {isFetching || isLoading || !business ? (
        <Spinner hasCountdown />
      ) : (
        <BusinessDetail business={business} />
      )}
    </Page>
  );
};

export default React.memo(UserBusinessPage);
