import React, { useState } from 'react';
import clsx from 'clsx';
import Page from '../../../components/Page/Page';
import { TextLink } from '../../../components/TextLink/TextLink';
import { useTextContentContext } from '../../../components/TextContentModal/context';
import { ContentReviews } from '../../../components/ContentText/ContentReviews';
import { ContentExplanation } from '../../../components/ContentText/ContentExplanation';
import { ContentPrices } from '../../../components/ContentText/ContentPrices';
import { useIonViewDidEnter } from '@ionic/react';
import { AnalyticEventsEnum, trackEvent } from '../../../Analytics';
import { CustomerHelpModal } from '../../../components/ConfirmationModal/CustomerHelpModal';

import s from '../../landing/Components/Sections/Form/Form.module.scss';
import sg from '../../../styles/global.module.scss';

type Props = {};

const UserAssistancePage: React.FC<Props> = () => {
  const { setInfo } = useTextContentContext();

  useIonViewDidEnter(() => {
    (async function () {
      await trackEvent(AnalyticEventsEnum.VIEW__ASSISTANCE);
    })();
  });

  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  return (
    <Page headerProps={{ backButtonText: 'To Catalog' }}>
      <div className={s.inapp_block} style={{ marginBottom: '30px' }}>
        <h3
          className={clsx(s.sectionHeader)}
          style={{ whiteSpace: 'pre-wrap' }}
          data-animation
        >
          The fastest way to organize event
        </h3>

        <span className={clsx(s.sectionSubheader)}>
          In 2024 we helped organize 120+ events in Bali. Contact us if you need
          assistance with your event planning.
          <div
            className={sg.Stack_Vertical}
            style={{ gap: '10px', marginTop: '15px' }}
          >
            <TextLink
              title="▸ How it works"
              onClick={() =>
                setInfo({
                  block: 'explanations',
                  content: <ContentExplanation />,
                  placement: 'assistance',
                })
              }
            />
            <TextLink
              title="▸ Reviews & Instagram"
              onClick={() =>
                setInfo({
                  block: 'reviews',
                  content: <ContentReviews />,
                  placement: 'assistance',
                })
              }
            />
            <TextLink
              title="▸ Prices"
              onClick={() =>
                setInfo({
                  block: 'prices',
                  content: <ContentPrices />,
                  placement: 'assistance',
                })
              }
            />
          </div>
        </span>
      </div>

      <button className={s.whatsAppButton} onClick={() => setIsOpen(true)}>
        Get FREE Consultation
      </button>

      <CustomerHelpModal isOpen={isOpen} close={close} placement="assistance" />

      <div style={{ height: '70px' }} />
    </Page>
  );
};

export default UserAssistancePage;
