import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { animationSlide } from '../../../utils/animation';
import UserFavouritesPage from './UserFavouritesPage';

const UserFavouritesRoutes: React.FC = () => {
  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path="/favourites">
        <UserFavouritesPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserFavouritesRoutes);
