import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { convertToOtherCase } from '../@shared/helpers/case';
import { Product } from '../models/product';
import type { ReduxRootState } from './store';
import { Business } from '../models/business';
import { HelpRequest } from '../models/helpRequest';
import { LocationRequest } from '../models/locationRequest';
import { SearchArgsType } from './_types';
import { SearchTypeEnum } from '../components/SearchBlock/interface';
import { AnalyticEventsEnum, trackEvent } from '../Analytics';

const bq = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/api`,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as ReduxRootState;
    const token = state.auth._token;
    const selectedLanguage = state.auth.selectedLanguage;

    if (token) {
      headers.set('Authorization', `Token ${token}`);
    }

    if (selectedLanguage) {
      headers.set('Selected-Language', selectedLanguage);
    }

    headers.set('Content-Type', 'application/json');

    return headers;
  },
});

export const api = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    if (args.body) args.body = convertToOtherCase(args.body, 'snake');
    const res = await bq(args, api, extraOptions);
    return convertToOtherCase(res, 'camel');
  },
  tagTypes: ['Product', 'Business'],
  endpoints: builder => ({
    // === Product ===
    getProductsBySearch: builder.query<
      Product[],
      {
        searchArgs?: SearchArgsType; // never passed to BE
        search: string;
        offset: number;
        limit: number;
      }
    >({
      onQueryStarted: async ({ searchArgs, offset }, api) => {
        await trackEvent(AnalyticEventsEnum.SEARCH);

        // We don't want to track pagination in details
        if (offset !== 0 || !searchArgs) return;

        // We don't want to track business "services" tab
        if (
          searchArgs.type === SearchTypeEnum.BY_KEYWORDS &&
          !!searchArgs.businessId
        )
          return;

        if (
          searchArgs.type === SearchTypeEnum.BY_EVENT_CATEGORY &&
          !searchArgs.eventCategory
        ) {
          await trackEvent(AnalyticEventsEnum.SEARCH__EXECUTE_DEFAULT);
        } else {
          const categoryName =
            searchArgs.type === SearchTypeEnum.BY_EVENT_CATEGORY
              ? searchArgs.eventCategory?.name
              : searchArgs.type === SearchTypeEnum.BY_PRODUCT_CATEGORY
              ? searchArgs.productCategory?.name
              : undefined;
          await trackEvent(AnalyticEventsEnum.SEARCH__EXECUTE_CUSTOM, {
            searchArgs,
            tab: searchArgs.type,
            categoryName,
          });
        }
      },
      query: ({ search, offset, limit }) =>
        `products/product/?${search}&offset=${offset}&limit=${limit}`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Product' as const, id })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
    getProduct: builder.query<Product, { id: string }>({
      query: ({ id }) => `products/product/${id}`,
      providesTags: (result, error, { id }) => [
        { type: 'Product' as const, id },
      ],
    }),
    getProductExclusive: builder.query<Business, { id: string }>({
      query: ({ id }) => `products/exclusive-access/${id}`,
    }),
    addProduct: builder.mutation<Product, { body: Product<{ plain: true }> }>({
      query: ({ body }) => ({
        url: 'products/product/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Product', id: 'LIST' }],
    }),
    editProduct: builder.mutation<
      Product,
      { id: string; body: Product<{ plain: true }> }
    >({
      query: ({ id, body }) => ({
        url: `products/product/${id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Product', id }],
    }),
    // === Business ===
    getBusiness: builder.query<Business, { id: string }>({
      query: ({ id }) => `businesses/business/${id}`,
      providesTags: (result, error, { id }) => [
        { type: 'Business' as const, id },
      ],
    }),
    // NOTE: edit business and create business are under slices
    // which is incorrect and should be here
    //
    // === HelpRequest ===
    addHelpRequest: builder.mutation<HelpRequest, { body: HelpRequest }>({
      query: ({ body }) => ({
        url: 'users/help_request/',
        method: 'POST',
        body,
      }),
    }),
    // === LocationRequest ===
    addLocationRequest: builder.mutation<
      LocationRequest,
      { body: LocationRequest }
    >({
      query: ({ body }) => ({
        url: 'users/location_request/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  // === Product ===
  useGetProductsBySearchQuery,
  useLazyGetProductsBySearchQuery,
  useGetProductQuery,
  useGetProductExclusiveQuery,
  useAddProductMutation,
  useEditProductMutation,
  // === Business ===
  useGetBusinessQuery,
  // === HelpRequest ===
  useAddHelpRequestMutation,
  // === LocationRequest ===
  useAddLocationRequestMutation,
} = api;
