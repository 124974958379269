import { Product } from './models/product';
import { AuthStorageKeys } from './redux/slices/auth/slice';
import { Business } from './models/business';
import { IS_PROD } from './firebase';
import { CONSENT_KEY, ConsentState } from './components/CookiesBanner/helpers';

export const getProductAnalyticData = (product: Product) => {
  return {
    categoryName: product.category.name,
    productId: product.id,
    productTitle: product.title,
    productPriceFrom: product.priceFrom,
    productPriceCurrency: product.priceCurrency,
    businessId: product.business,
  };
};

export const getBusinessAnalyticData = (business: Business) => {
  return {
    categoryName: business.category?.name || business.categoryCustom,
    businessId: business.id,
    businessTitle: business.name,
  };
};

export enum AnalyticEventsEnum {
  /* STANDARD FACEBOOK EVENTS */
  VIEW_CONTENT = 'ViewContent', // Same as "VIEW__CATALOG"
  SEARCH = 'Search', // Any search in catalog performed - including even pagination
  INITIATE_CHEKOUT = 'InitiateCheckout', //  Same as "CLICK__PRODUCT_BOOKING" (may be not finished if "INTERRUPTED" happens)
  CONTACT = 'Contact', // Same as "CLICK__PRODUCT_CONTACT" + "CLICK__BUSINESS_CONTACT" (may be not finished if "INTERRUPTED" happens)
  LEAD = 'Lead', // Same as "WHATS_APP_LEAD"

  // Catalog
  VIEW__CATALOG = 'VIEW__CATALOG',

  // Search
  SEARCH__EXECUTE_DEFAULT = 'SEARCH__EXECUTE_DEFAULT',
  SEARCH__EXECUTE_CUSTOM = 'SEARCH__EXECUTE_CUSTOM', // { searchArgs^, tab, categoryName }
  SEARCH__CLICK_TAB = 'SEARCH__CLICK_TAB', // { tab }
  SEARCH__CLICK_CATEGORY = 'SEARCH__CLICK_CATEGORY', // { tab, categoryName }

  // Product
  CLICK__PRODUCT = 'CLICK__PRODUCT', // { ...product^ }
  CLICK__PRODUCT_CONTACT = 'CLICK__PRODUCT_CONTACT', // { ...product^ }
  CLICK__PRODUCT_BOOKING = 'CLICK__PRODUCT_BOOKING', // { ...product^ }

  // Business
  CLICK__BUSINESS = 'CLICK__BUSINESS', // { ...business^ }
  CLICK__BUSINESS_CONTACT = 'CLICK__BUSINESS_CONTACT', // { ...business^ }
  CLICK__BUSINESS_TAB = 'CLICK__BUSINESS_TAB', // { tab }

  // Assistance
  VIEW__ASSISTANCE = 'VIEW__ASSISTANCE',
  VIEW__TEXT_CONTENT = 'VIEW__TEXT_CONTENT', // { placement, block }

  // Consultation
  VIEW__CONSULTATION_MODAL = 'VIEW__CONSULTATION_MODAL', // { placement }
  CLICK__CONSULTATION_TAB = 'CLICK__CONSULTATION_TAB', // { formId }

  // General
  FORM_FIELD_TOUCHED = 'FORM_FIELD_TOUCHED', // Form field blur: { formId, fieldName, fieldValue, fieldDirty }
  LOCATION_CHANGED = 'LOCATION_CHANGED', // Location changed anywhere: { location }
  WHATS_APP_LEAD = 'WHATS_APP_LEAD', // "Text us" button clicked
  WHATS_APP_INTERNAL = 'WHATS_APP_INTERNAL', // "Text vendor" button clicked
  INVALID_SUBMIT = 'INVALID_SUBMIT', // Form invalid: { formId, formErrors^ }
  MODAL_CLOSED = 'MODAL_CLOSED', // Important flow interrupted

  // Location lock
  LOCATION__LOCK_CLICKED = 'LOCATION__LOCK_CLICKED', // { location }
  LOCATION__VOTE_SUBMITTED = 'LOCATION__VOTE_SUBMITTED', // { location }
  LOCATION__EMAIL_SUBMITTED = 'LOCATION__EMAIL_SUBMITTED',

  // Landings
  VIEW__LANDING_SECTION = 'VIEW__LANDING_SECTION', // { source, sectionOrder }
  VIEW__LANDING_FAQ = 'VIEW__LANDING_FAQ', // { source, placement }
  CLICK__LANDING_CTA = 'CLICK__LANDING_CTA', // { source , sectionOrder, placement }
  CLICK__LANDING_ANCHOR = 'CLICK__LANDING_ANCHOR', // { source, toSource, anchor, placement }
  OPEN__MOBILE_MENU = 'OPEN__MOBILE_MENU', // { source }
  OPEN__LANDING_INFO = 'OPEN__LANDING_INFO', // { source, toSource }

  // Redirects
  REDIRECT_TO = 'REDIRECT_TO', // { redirectTo }
  REDIRECT_FROM = 'REDIRECT_FROM', // { redirectFrom, redirectType }
}

export const trackEvent = async (
  eventName: AnalyticEventsEnum,
  eventData?: Record<string, any>
) => {
  let action: 'track' | 'trackCustom';

  if (
    [
      // Standard META events
      AnalyticEventsEnum.CONTACT,
      AnalyticEventsEnum.INITIATE_CHEKOUT,
      AnalyticEventsEnum.LEAD,
      AnalyticEventsEnum.SEARCH,
      AnalyticEventsEnum.VIEW_CONTENT,
    ].includes(eventName)
  ) {
    action = 'track';
  } else {
    action = 'trackCustom';
  }

  if (action === 'trackCustom') {
    // console.log(
    //   '\x1b[36m%s\x1b[0m',
    //   `[Analytic] ${eventName}`,
    //   eventData || ''
    // );
  }

  if (
    !IS_PROD ||
    localStorage.getItem(AuthStorageKeys.USER)?.includes('@eventmaker.app')
  ) {
    return;
  }

  const savedConsetMode = JSON.parse(
    localStorage.getItem(CONSENT_KEY) || '{}'
  ) as Partial<ConsentState>;

  if (savedConsetMode.analytics_storage)
    (window as any).dataLayer.push({
      event: eventName,
      data: eventData,
    });
};
