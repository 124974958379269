import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { animationSlide } from '../../../utils/animation';
import { Route, useRouteMatch } from 'react-router';
import EventsPage from '../../EventsPage';
import { MessagesPage } from '../../MessagesPage';

const BusinessEventsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path={`${match.path}/messages`}>
        <MessagesPage />
      </Route>

      <Route exact path={match.path}>
        <EventsPage isBusiness />
      </Route>
    </IonRouterOutlet>
  );
};

export default BusinessEventsRoutes;
