import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page/Page';
import {
  BaseQueryParamsType,
  ProductCategoriesQueryParamsType,
  SearchArgsType,
} from '../../../redux/_types';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { getSearchParameters } from '../../../utils/queryParams';
import { convertQueryParamsToSearchArgs } from '../../../redux/_helpers';
import SearchContent from '../../../components/SearchContent/SearchContent';
import Spinner from '../../../components/Spinner/Spinner';
import { AnalyticEventsEnum, trackEvent } from '../../../Analytics';
import { useIonViewDidEnter } from '@ionic/react';
import { LocationFullType } from '../../../components/Location/config';
import { usePointLocation } from '../../../hooks/hook.location';

const UserCatalogPage = ({
  urlPointLocation,
  shouldUseUrlPointLocation,
}: {
  urlPointLocation: LocationFullType;
  shouldUseUrlPointLocation: React.MutableRefObject<boolean>;
}) => {
  const dispatch = useTypedDispatch();
  const pointLocation = usePointLocation();

  const eventCategories = useTypedSelector(s => s.choices.eventCategorySet);
  const productCategories = useTypedSelector(s => s.choices.productCategorySet);

  const [initialSearchArgs, setInitialSearchArgs] = useState<SearchArgsType>();

  useEffect(() => {
    if (
      !eventCategories.length ||
      !productCategories.length ||
      !pointLocation ||
      !urlPointLocation
    ) {
      return;
    }

    const queryParams: BaseQueryParamsType & ProductCategoriesQueryParamsType =
      getSearchParameters();

    const providedSearchArgs = convertQueryParamsToSearchArgs(
      queryParams,
      eventCategories,
      productCategories,
      pointLocation,
      urlPointLocation,
      shouldUseUrlPointLocation.current
    );

    setInitialSearchArgs(providedSearchArgs);
    shouldUseUrlPointLocation.current = false;
  }, [
    eventCategories,
    productCategories,
    pointLocation,
    urlPointLocation,
    shouldUseUrlPointLocation,
    dispatch,
  ]);

  useIonViewDidEnter(() => {
    (async function () {
      await Promise.all([
        trackEvent(AnalyticEventsEnum.VIEW_CONTENT),
        trackEvent(AnalyticEventsEnum.VIEW__CATALOG),
      ]);
    })();
  }, []);

  return (
    <Page
      disableDefaultIonContent
      pageLimitDisabled
      headerProps={{
        hideBackButton: true,
        showLocationPicker: true,
      }}
    >
      {!initialSearchArgs ? (
        <Spinner hasCountdown />
      ) : (
        <SearchContent initialSearchArgs={initialSearchArgs} />
      )}
    </Page>
  );
};

export default React.memo(UserCatalogPage);
