import { ReviewsBlock } from '../../pages/landing/Components/Sections/Reviews/ReviewsBlock';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { useEffect } from 'react';

export const ContentReviews = () => {
  // useEffect(() => {
  //   (async function () {
  //     await trackEvent(AnalyticEventsEnum.VIEW__ASSISTANCE_REVIEWS);
  //   })();
  // });

  return <ReviewsBlock />;
};
