import React, { useState } from 'react';
import clsx from 'clsx';
import s_Customer from './FAQ_Customer.module.scss';
import s_Business from './FAQ_Business.module.scss';
import { getFAQConfig } from './config';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { ActionButton } from '../../ActionButton/ActionButton';
import Centralizer from '../../../../../components/Centralizer/Centralizer';
import { useLandingPageContext } from '../../../context';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';

export const FAQ = () => {
  const { source } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const [activeItemId, setActiveItemId] = useState<number>();

  const click = async (questionId: number, question: string) => {
    if (activeItemId === questionId) {
      setActiveItemId(undefined);
    } else {
      setActiveItemId(questionId);
      await trackEvent(AnalyticEventsEnum.VIEW__LANDING_FAQ, {
        source,
        placement: question.slice(0, 10) + '...',
      });
    }
  };

  const questions = getFAQConfig({ source });

  return (
    <section
      section-order={source === 'customer' ? 7 : 5}
      className={s.section}
      id={buildAnchorId(AnchorEnum.FAQ)}
    >
      <div id="FAQ" className={s.container}>
        <h3 data-animation className={s.section__title}>
          FAQ
        </h3>
        <div className={s.FAQ}>
          {questions.map((question, index) => (
            <div
              key={index}
              data-animation
              onClick={async () => await click(question.id, question.question)}
              className={s.FAQ__item}
            >
              <div className={s.FAQ__row}>
                <p className={s.question}>{question.question}</p>
                <img
                  alt=""
                  src={
                    source === 'customer'
                      ? './svg/arrowFAQ_White.svg'
                      : './svg/arrowFAQ_Black.svg'
                  }
                  className={clsx(
                    s.FAQ__arrow,
                    activeItemId === question.id && s.arrow_active
                  )}
                />
              </div>
              {activeItemId === question.id && (
                <p className={s.answer}>{question.answer}</p>
              )}
            </div>
          ))}
        </div>

        <Centralizer>
          <ActionButton animation />
        </Centralizer>
      </div>
    </section>
  );
};
