import React, { useCallback, useMemo } from 'react';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { useLandingPageContext } from '../../../context';
import { v4 as uuidv4 } from 'uuid';
import { BusinessHelpForm, BusinessHelpFormValues } from './BusinessHelpForm';
import { useAddHelpRequestMutation } from '../../../../../redux/api';
import { CustomerHelpForm } from './CustomerHelpForm';
import s from './Form.module.scss';
import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const Form = () => {
  const { source } = useLandingPageContext();

  const [addTrigger, addMetadata] = useAddHelpRequestMutation();
  const id = useMemo(() => uuidv4(), []);

  const submitCallbackBusinessHelpForm = useCallback(
    async (values: BusinessHelpFormValues) => {
      const body = {
        ...values,
        id,
        source: 'landing_business',
      } as const;

      await addTrigger({ body });
    },
    [id, addTrigger]
  );

  return (
    <section
      section-order={source === 'customer' ? 6 : 4}
      className={s.section}
      id={buildAnchorId(AnchorEnum.FORM_REQUEST)}
    >
      <div className={s.container}>
        <div className={s.left}>
          <h3 className={s.sectionHeader} data-animation>
            {source === 'customer'
              ? 'Get your FREE consultation today!'
              : 'Not enough time for self-registration?'}
          </h3>

          <p className={s.sectionSubheader} data-animation>
            {source === 'customer'
              ? 'We can plan your event or find specific event services based on your needs'
              : 'We can transfer your catalog to our platform'}
          </p>

          {source === 'customer' ? (
            <CustomerHelpForm applyCustomStyles />
          ) : (
            <BusinessHelpForm
              initialFormValues={{ text: '', phone: '' }}
              submitCallback={submitCallbackBusinessHelpForm}
              isLoading={addMetadata.isLoading}
            />
          )}
        </div>
        <img
          loading="lazy"
          data-animation
          src={
            source === 'customer'
              ? getStorageUrl('landing/work.webp')
              : getStorageUrl('landing/blocknote.webp')
          }
          alt=""
        />
      </div>
    </section>
  );
};
