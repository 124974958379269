type Props = {
  children?: React.ReactNode;
  color?: string;
  fontWeight?: number;
  fontSize?: string;
  extraStyle?: React.CSSProperties;
};

export const Text = ({
  children,
  color,
  fontWeight,
  fontSize,
  extraStyle,
}: Props) => {
  return (
    <p style={{ ...extraStyle, fontWeight, fontSize, color }}>{children}</p>
  );
};
