import { useForm } from 'react-hook-form';
import Form from '../../../../../../components/Form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationErrors } from '../../../../../../utils/validators';
import * as Yup from 'yup';
import { useSumbitFormCallback } from '../../../../../../components/Form/submit.hook';
import Input from '../../../../../../components/Inputs/Input/Input';
import InputArea from '../../../../../../components/Inputs/InputArea/InputArea';
import { OrganizeEventFormValues } from './OrganizeEventForm';
import { ContactUsButton } from './ContactUsButton';
import clsx from 'clsx';
import s from '../Form.module.scss';
import { useEffect } from 'react';
import { useTrackOnBlur } from '../../../../../../components/Inputs/helpers';

export type FindProductsFormValues = Omit<OrganizeEventFormValues, 'type'>;

const validationOrdered = {
  date: Yup.string().required(validationErrors.REQUIRED),
  text: Yup.string().required(validationErrors.REQUIRED),
};

const validationSchema: Yup.SchemaOf<Partial<FindProductsFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

type Props = {
  initialFormValues: Partial<FindProductsFormValues>;
  submitCallback: (values: FindProductsFormValues) => Promise<void>;
  applyCustomStyles?: boolean;
};

const FORM_ID = 'FindProductsForm';

export const FindProductsForm: React.FC<Props> = ({
  initialFormValues,
  submitCallback,
  applyCustomStyles,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitted, errors, touchedFields },
    reset,
    resetField,
    trigger,
    getFieldState,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const { onSubmit } = useSumbitFormCallback<OrganizeEventFormValues>({
    submitCallback,
    handleSubmit,
    reset,
    trigger,
    setValue,
    isSubmitted,
    trackSubmitError: true,
    hideToast: true,
    formId: FORM_ID,
  });

  // Analytics
  const date = watch('date');
  const text = watch('text');

  const { trackField } = useTrackOnBlur({
    resetField,
    getFieldState,
    getValues,
    setValue,
  });

  useEffect(() => {
    trackField('date', FORM_ID);
  }, [trackField, date, touchedFields.date]);

  useEffect(() => {
    trackField('text', FORM_ID);
  }, [trackField, text, touchedFields.text]);

  return (
    <Form onSubmit={onSubmit} hidePrompt>
      <Input
        label="Date of event"
        type="text"
        inputmode="text"
        labelClassName={applyCustomStyles ? s.IonInputLabel__custom : ''}
        className={applyCustomStyles ? s.IonInput__custom : ''}
        className__active={applyCustomStyles ? s.IonInput__custom__active : ''}
        _name={'date' satisfies FieldNameType}
        _register={register}
        _errorText={errors.date?.message}
      />

      <InputArea
        label="What are you looking for?"
        labelClassName={
          applyCustomStyles
            ? clsx(s.IonInputLabel__custom, s.IonInputLabel__custom__margin)
            : ''
        }
        className={clsx(applyCustomStyles ? s.IonTextarea__custom : undefined)}
        className__active={
          applyCustomStyles ? s.IonTextarea__custom__active : ''
        }
        minRows={5}
        _name={'text' satisfies FieldNameType}
        _register={register}
        _errorText={errors.text?.message}
      />

      <ContactUsButton
        style={{ marginTop: '25px' }}
        applyCustomStyles={applyCustomStyles}
      />
    </Form>
  );
};
