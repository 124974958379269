import maxBy from 'lodash/maxBy';

function getVisiblePercentage(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;

  const elementHeight = rect.height;
  const elementWidth = rect.width;

  const visibleHeight = Math.max(
    0,
    Math.min(windowHeight, rect.bottom) - Math.max(0, rect.top)
  );

  const visibleWidth = Math.max(
    0,
    Math.min(windowWidth, rect.right) - Math.max(0, rect.left)
  );

  const visibleArea = visibleHeight * visibleWidth;
  const totalArea = elementHeight * elementWidth;
  const visiblePercentage = (visibleArea / totalArea) * 100;

  return visiblePercentage;
}

export function findMostVisibleSection() {
  const sections = document.querySelectorAll('section');

  const sectionsPecentages: {
    [key: string]: { percentage: number; section: HTMLElement };
  } = {};

  sections.forEach(section => {
    const sectionId = section.getAttribute('section-order');
    if (!sectionId) return;
    sectionsPecentages[sectionId] = {
      percentage: getVisiblePercentage(section),
      section,
    };
  });

  const mostVisibleSection = maxBy(
    Object.entries(sectionsPecentages),
    ([key, value]) => value.percentage
  );

  return mostVisibleSection?.[1].section;
}
