import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { globalSlice } from './slices/global/slice';
import { ToastPriorityEnum } from '../utils/enums';

// this middleware handles all "RTK query" errors
// so we don't need to show errors inside <Toast /> on component level
export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  next =>
  action => {
    if (isRejectedWithValue(action)) {
      console.log('[Redux RTK Query]', action);
      dispatch(
        globalSlice.actions.pushToastToQueue({
          message:
            action.payload.status === 'FETCH_ERROR'
              ? 'Internet connection error'
              : action?.payload?.data?.detail || 'Error has occured.',
          color: 'danger',
          priority: ToastPriorityEnum.HIGH,
        })
      );
    }

    return next(action);
  };
