const dataPointsCustomer = [
  {
    value: '2500+',
    title: 'SERVICES',
    body: 'from different vendors',
  },
  {
    value: '300+',
    title: 'BUSINESSES',
    body: 'offer their services',
  },
  {
    value: '15',
    title: 'MINUTES',
    body: 'to fully plan your event',
  },
  {
    value: '0%',
    title: 'COMISSION',
    body: 'only direct prices',
  },
];

const dataPointsBusiness = [
  {
    value: '14k',
    title: 'CLIENTS',
    body: 'use Eventmaker every month',
  },
  {
    value: '~3',
    title: 'NEW CLIENTS',
    body: "on average you'll get in first month",
  },
  {
    value: '10%',
    title: 'COMISSION',
    body: 'only when you sell',
  },
  {
    value: '5',
    title: 'MINUTES',
    body: 'to register and start earning',
  },
];

export const getAchievementsConfig = ({
  source,
}: {
  source: 'customer' | 'business';
}) => (source === 'customer' ? dataPointsCustomer : dataPointsBusiness);
