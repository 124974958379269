export const CONSENT_KEY = 'consent_mode'; // Local Storage

export type ConsentState = {
  ad_storage: 'granted' | 'denied';
  ad_user_data: 'granted' | 'denied';
  ad_personalization: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  functionality_storage: 'granted' | 'denied';
  personalization_storage: 'granted' | 'denied';
  security_storage: 'granted' | 'denied';
};

export const essentialConsent: ConsentState = {
  functionality_storage: 'granted',
  security_storage: 'granted',
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
  personalization_storage: 'denied',
};

export const maximumConsent: ConsentState = {
  functionality_storage: 'granted',
  security_storage: 'granted',
  ad_storage: 'granted',
  ad_user_data: 'granted',
  ad_personalization: 'granted',
  analytics_storage: 'granted',
  personalization_storage: 'granted',
};

export const removeCookies = (pattern: (cookieName: string) => boolean) => {
  document.cookie.split(';').forEach(cookie => {
    let cookieName = cookie.split('=')[0].trim();
    if (pattern(cookieName)) {
      document.cookie =
        cookieName +
        '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' +
        '.eventmaker.app';
    }
  });
};

// Related with "index.html" and GTM Template
export const onConsentChange = (consent: ConsentState) => {
  localStorage.setItem(CONSENT_KEY, JSON.stringify(consent));
  (window as any).consentListeners.forEach((callback: any) => {
    callback(consent);
  });

  if (consent.analytics_storage !== 'granted') {
    removeCookies(cookieName => cookieName.startsWith('_ga'));
  }
  if (consent.ad_storage !== 'granted') {
    // TODO: Remove marketing cookie
  }
};
