import { IonCard, IonIcon, IonToggle } from '@ionic/react';
import { Stack } from '@mui/material';
import { Text } from '../atoms/Text/Text';
import { lockClosed } from 'ionicons/icons';

type Props = {
  title: string;
  description: string;
  onChange?: (value: boolean) => void;
  checked: boolean;
};

export const CookieCategoryToggle = ({
  title,
  description,
  onChange,
  checked,
}: Props) => {
  return (
    <IonCard color="primary">
      <Stack spacing={1}>
        <IonToggle
          value={checked ? 'on' : 'off'}
          checked={checked}
          disabled={!onChange ? true : undefined}
          onIonChange={e => onChange?.(e.target.checked)}
          style={{
            '--handle-background': 'grey',
            '--handle-background-checked': '#696ff1',
            '--track-background': '#ccc',
            '--track-background-checked': '#b8baf5',
            opacity: 1,
          }}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Text fontWeight={500} fontSize="20px" color="black">
              {title}
            </Text>
            {!onChange && <IonIcon src={lockClosed} />}
          </Stack>
        </IonToggle>
        <Text color="grey">{description}</Text>
      </Stack>
    </IonCard>
  );
};
