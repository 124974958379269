import React, { useRef } from 'react';
import s from './ActionButton.module.scss';
import clsx from 'clsx';
import { useLandingPageContext } from '../../context';
import { navigateCircleOutline } from 'ionicons/icons';
import { AnalyticEventsEnum, trackEvent } from '../../../../Analytics';

interface Props {
  text?: string;
  arrow?: boolean;
  animation?: boolean;
  skipDefaultAction?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  ctaDetail?: string;
}

export const ActionButton: React.FC<Props> = ({
  text,
  arrow = true,
  animation,
  skipDefaultAction,
  onClick,
  style,
  ctaDetail,
}) => {
  const { actionButtonName, onActionClick, source } = useLandingPageContext();

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={buttonRef}
      style={style}
      onClick={async e => {
        if (!skipDefaultAction) {
          onActionClick();
        }
        onClick?.();

        const closestSection = buttonRef.current?.closest('section');
        const sectionOrder = closestSection?.getAttribute('section-order');

        await trackEvent(AnalyticEventsEnum.CLICK__LANDING_CTA, {
          source,
          sectionOrder,
          placement: ctaDetail,
        });
      }}
      data-animation
      className={clsx(
        s.ActionButton,
        animation ? s.ActionButton__animation : ''
      )}
    >
      {text || actionButtonName}{' '}
      {arrow && <img src="./svg/arrowAction_Common.svg" alt="" />}
    </button>
  );
};
