import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const dev_firebaseConfig = {
  apiKey: 'AIzaSyCDUVGOUvnjaK-mmwDjPGs3Xq851gj4VQQ',
  authDomain: 'event-maker-dev.firebaseapp.com',
  projectId: 'event-maker-dev',
  storageBucket: 'event-maker-dev.appspot.com',
  messagingSenderId: '322504411016',
  appId: '1:322504411016:web:93e6df08c50f77d6b81c3d',
  measurementId: 'G-QGTHPBX1NL',
  databaseURL: 'https://event-maker-dev.firebaseio.com',
};

const prod_firebaseConfig = {
  apiKey: 'AIzaSyBOjgyMvUImyf7kOQGtDfcTO8c8nVjHd6o',
  authDomain: 'event-maker-production.firebaseapp.com',
  projectId: 'event-maker-production',
  storageBucket: 'event-maker-production.appspot.com',
  messagingSenderId: '1093245589989',
  appId: '1:1093245589989:web:c8336ad815de335f7bb70c',
  measurementId: 'G-BPDXSVG8E1',
  databaseURL: 'https://event-maker-production.firebaseio.com',
};

export const IS_PROD = process.env.REACT_APP_ENVIRONMENT === 'prod';

const app = initializeApp(IS_PROD ? prod_firebaseConfig : dev_firebaseConfig);
export const firestore = getFirestore(app);
