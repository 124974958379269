import { IonCard } from '@ionic/react';
import { Text } from '../atoms/Text/Text';
import s from './AboutBlock.module.scss';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import React from 'react';

type Props = {
  icon: React.ReactNode;
  title: string;
  details: string;
  onClick: () => void;
};

export const AboutCard = ({ icon, title, details, onClick }: Props) => {
  return (
    <IonCard className={clsx(s.AboutCard)} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        <Text fontSize="18px" fontWeight={500} color="white">
          {title}
        </Text>
      </Stack>

      <Text
        color="var(--color-main-medium)"
        fontSize="14px"
        extraStyle={{ marginTop: '5px' }}
      >
        {details}
      </Text>
    </IonCard>
  );
};
