import { useState } from 'react';
import { Chip } from './Chip/Chip';
import {
  FindProductsForm,
  FindProductsFormValues,
} from './Subforms/FindProductsForm';
import {
  OrganizeEventForm,
  OrganizeEventFormValues,
} from './Subforms/OrganizeEventForm';
import { calendar, search } from 'ionicons/icons';
import s from './Form.module.scss';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../utils/phoneNumber';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';

export enum HelpUserFormType {
  FindProducts = 'findProducts',
  OrganizeEvent = 'organizeEvent',
}

type Props = {
  applyCustomStyles?: boolean;
};

export const CustomerHelpForm: React.FC<Props> = ({ applyCustomStyles }) => {
  const [activeForm, setActiveForm] = useState<HelpUserFormType | null>(
    HelpUserFormType.OrganizeEvent
  );

  const submitCallbackUserHelpForm = async (
    values: OrganizeEventFormValues | FindProductsFormValues
  ) => {
    await trackEvent(AnalyticEventsEnum.LEAD);
    await trackEvent(AnalyticEventsEnum.WHATS_APP_LEAD);

    const type = 'type' in values ? values.type : null;
    const date = `${values.date}`;
    const text = `${values.text}`;

    let content;

    switch (activeForm) {
      case HelpUserFormType.OrganizeEvent:
        content = `Hey, Eventmaker! Can you organize event for me?\n
Event type: ${type}
Date: ${date}
Details: ${text}.`;
        break;
      case HelpUserFormType.FindProducts:
        content = `Hey, Eventmaker! Can you find event services for me?\n
Date: ${date}
Details: ${text}`;
        break;
    }

    const link = `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(
      content || ''
    )}`;

    window.open(link);
  };

  return (
    <div className={s.inapp_block}>
      <div className={s.Chips_container}>
        <Chip
          applyCustomStyles={applyCustomStyles}
          label={`Organize\nevent for me`}
          isActive={activeForm === HelpUserFormType.OrganizeEvent}
          icon={calendar}
          onClick={async () => {
            setActiveForm(HelpUserFormType.OrganizeEvent);
            await trackEvent(AnalyticEventsEnum.CLICK__CONSULTATION_TAB, {
              formId: 'OrganizeEventForm',
            });
          }}
        />

        <Chip
          applyCustomStyles={applyCustomStyles}
          label={`Find services\nfor my event`}
          isActive={activeForm === HelpUserFormType.FindProducts}
          icon={search}
          onClick={async () => {
            setActiveForm(HelpUserFormType.FindProducts);
            await trackEvent(AnalyticEventsEnum.CLICK__CONSULTATION_TAB, {
              formId: 'FindProductsForm',
            });
          }}
        />
      </div>

      {activeForm === HelpUserFormType.OrganizeEvent ? (
        <OrganizeEventForm
          initialFormValues={{ type: '', date: '', text: '' }}
          submitCallback={submitCallbackUserHelpForm}
          applyCustomStyles={applyCustomStyles}
        />
      ) : activeForm === HelpUserFormType.FindProducts ? (
        <FindProductsForm
          initialFormValues={{ date: '', text: '' }}
          submitCallback={submitCallbackUserHelpForm}
          applyCustomStyles={applyCustomStyles}
        />
      ) : null}
    </div>
  );
};
