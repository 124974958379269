import { useCallback } from 'react';
import {
  FieldValues,
  Path,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

// Used for forms which should be tracked
// Triggered once on .blur()
export const useTrackOnBlur = <FT extends FieldValues>({
  resetField,
  getFieldState,
  getValues,
  setValue,
}: {
  resetField: UseFormResetField<FT>;
  getFieldState: UseFormGetFieldState<FT>;
  getValues: UseFormGetValues<FT>;
  setValue: UseFormSetValue<FT>;
}) => {
  const trackField = useCallback(
    (fieldName: Path<FT>, formId: string) => {
      const { isDirty, isTouched } = getFieldState(fieldName);

      // field became touched on blur
      if (isTouched) {
        const value = getValues()[fieldName];

        // reset "isTouched" to false, to prevent .trackEvent() on every value change
        resetField(fieldName, { keepDirty: true, keepError: true });
        setValue(fieldName, value);

        (async function () {
          await trackEvent(AnalyticEventsEnum.FORM_FIELD_TOUCHED, {
            formId,
            fieldName,
            fieldValue: value,
            fieldDirty: isDirty,
          });
        })();
      }
    },
    [getFieldState, getValues, setValue, resetField]
  );

  return { trackField };
};
