type Question = {
  id: number;
  question: string;
  answer: string;
};

const questionsCustomer: Question[] = [
  {
    id: 1,
    question: 'What is Eventmaker?',
    answer:
      'Eventmaker is a marketplace to find all the event services you need, with the best possible prices directly from service providers.',
  },
  {
    id: 2,
    question: 'Can you organize an event for me?',
    answer:
      'Absolutely! Based on your requirements, we can find the most suitable planning agency in your city to organize your event. Be sure you’ll get exceptional service without overpaying.',
  },
  {
    id: 3,
    question: 'How can you guarantee the best pricing?',
    answer: `"By booking with Eventmaker, each client gets the same pricing as booking directly with the Service Provider." - It's our agreement with each business registered on the platform. \n\nAnd how do we make money? Businesses are happy to pay us a reasonable commission, because we bring them clients and reduce marketing costs.`,
  },
  {
    id: 4,
    question: 'Are you an event agency?',
    answer:
      "Nope, we're a startup that connects event industry. Our goal - to give you the most comfortable and modern event planning experience. However, if you prefer to work with event agencies, you can find their services in our catalog.",
  },
  {
    id: 5,
    question: 'Other questions',
    answer: 'Contact us by email: support@eventmaker.app',
  },
];

const questionsBusiness: Question[] = [
  {
    id: 1,
    question: 'What is Eventmaker?',
    answer:
      'Eventmaker is a platform that connects the event industry and gives clients access to all kinds of event services.',
  },
  {
    id: 2,
    question: 'How can I add my services to the platform?',
    answer:
      'Simply register your business and add photo and descriptions of your services. Clients will contact you if they would be interested in it.',
  },
  {
    id: 3,
    question: 'How much does it cost?',
    answer:
      'You can add your event services to the platform for free. After client makes a booking - we will charge a 10% fee from total bill.',
  },
  {
    id: 4,
    question: 'I represent an event agency, can I register?',
    answer:
      'Absolutely! Feel free to add your offerings to the platform. Many of our clients are looking for all-inclusive event planning services.',
  },
  {
    id: 5,
    question: 'Other questions',
    answer: 'Contact us by email: support@eventmaker.app',
  },
];

export const getFAQConfig = ({ source }: { source: 'customer' | 'business' }) =>
  source === 'customer' ? questionsCustomer : questionsBusiness;
