import { Stack } from '@mui/material';
import Centralizer from '../Centralizer/Centralizer';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { useEffect } from 'react';

import s from './Content.module.scss';

export const ContentExplanation = () => {
  // useEffect(() => {
  //   (async function () {
  //     await trackEvent(AnalyticEventsEnum.VIEW__ASSISTANCE_EXPLANATION);
  //   })();
  // });

  return (
    <Centralizer pageLimitSize="500px" enablePagePadding>
      <h3 className={s.HeaderColored}>
        Our mission is to make event planning simple and transparent
      </h3>

      <Stack direction="column" spacing={2} style={{ lineHeight: '150%' }}>
        <p className={s.RoundParagraph}>
          Not sure where to start? We’ll connect you with event managers or
          service providers who can bring your ideas to life in the best way
          possible.
        </p>

        <p className={s.RoundParagraph}>
          You’ll get personalized recommendations based on your real needs. Only
          direct prices from service providers, with no hidden fees or extra
          charges.
        </p>
      </Stack>
    </Centralizer>
  );
};
