import React, { useState } from 'react';
import clsx from 'clsx';
import s_Customer from './HeaderMobile_Customer.module.scss';
import s_Business from './HeaderMobile_Business.module.scss';
import {
  getHeaderConfig,
  clickAnchor,
  isClientOnBusinessPortal,
} from './config';
import ButtonMicroWrapper from '../../../../../components/Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { useLandingPageContext } from '../../../context';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';

export const HeaderMobile = () => {
  const { source, actionButtonName, onActionClick } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const [menuVisible, setMenuVisible] = useState(false);

  const config = getHeaderConfig({ source });

  return (
    <>
      <div className={clsx(s.fixed, 'disableIosSafariSwipeBlocker')}>
        <header className={s.nav}>
          <ButtonMicroWrapper
            className={s.ButtonMicroWrapper}
            noRipple
            onClick={async () => {
              setMenuVisible(true);
              await trackEvent(AnalyticEventsEnum.OPEN__MOBILE_MENU, {
                source,
              });
            }}
          >
            <div className={clsx(s.left, 'disableIosSafariSwipeBlocker')}>
              <div className={clsx(s.burger, 'disableIosSafariSwipeBlocker')}>
                <img
                  className="disableIosSafariSwipeBlocker"
                  src={
                    source === 'customer'
                      ? './svg/appMenuBurger_White.svg'
                      : './svg/appMenuBurger_Black.svg'
                  }
                  alt=""
                />
              </div>
              <div className={s.logo}>
                <img
                  src={
                    source === 'customer'
                      ? './svg/appLogo_White.svg'
                      : './svg/appLogo_Black.svg'
                  }
                  alt=""
                />
              </div>
            </div>
          </ButtonMicroWrapper>

          <button
            className={s.button}
            onClick={async () => {
              onActionClick();
              await trackEvent(AnalyticEventsEnum.CLICK__LANDING_CTA, {
                source,
                sectionOrder: '',
                placement: 'header',
              });
            }}
          >
            {actionButtonName}
            <IonIcon
              src={chevronForwardOutline}
              style={{
                color: source === 'customer' ? 'black' : 'white',
                fontSize: '16px',
              }}
            />
          </button>
        </header>

        {/* ==== Opened menu ===== */}
        <div className={clsx(s.menu, menuVisible && s.menuActive)}>
          <div className={s.navMenu}>
            <ButtonMicroWrapper
              className={s.ButtonMicroWrapper}
              noRipple
              onClick={() => setMenuVisible(false)}
            >
              <div className={clsx(s.left, 'disableIosSafariSwipeBlocker')}>
                <div className={clsx(s.close, 'disableIosSafariSwipeBlocker')}>
                  <img
                    src="./svg/appMenuBurger_Close.svg"
                    alt="close"
                    className="disableIosSafariSwipeBlocker"
                  />
                </div>
                <div className={s.logo}>
                  <img src="./svg/appLogo_White.svg" alt="" />
                </div>
              </div>
            </ButtonMicroWrapper>
            <button
              className={s.button}
              onClick={async () => {
                onActionClick();
                await trackEvent(AnalyticEventsEnum.CLICK__LANDING_CTA, {
                  source,
                  sectionOrder: '',
                  placement: 'menu',
                });
              }}
            >
              {actionButtonName}
              <IonIcon
                src={chevronForwardOutline}
                style={{
                  color: source === 'customer' ? 'black' : 'white',
                  fontSize: '16px',
                }}
              />
            </button>
          </div>

          <ul>
            {config.map((item, index) => (
              <li key={index}>
                <p
                  style={
                    isClientOnBusinessPortal({ source, item })
                      ? { fontStyle: 'italic' }
                      : {}
                  }
                  onClick={async () => {
                    await clickAnchor({
                      anchor: item.anchor,
                      source,
                      toSource: item.toSource,
                      placement: 'menu',
                    });
                    setMenuVisible(false);
                  }}
                >
                  {item.name}
                </p>
              </li>
            ))}
          </ul>

          <div className={s.bottom}>
            <div className={s.links}>
              <a
                href="https://www.instagram.com/eventmaker.app/"
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'instagram',
                  });
                }}
              >
                <img src="./svg/instagram.svg" alt="instagram" />
              </a>
              <a
                href="https://www.facebook.com/eventmakerapp"
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'facebook',
                  });
                }}
              >
                <img src="./svg/facebook.svg" alt="facebook" />
              </a>
              <a
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'twitter',
                  });
                }}
              >
                <img src="./svg/twitter.svg" alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/eventmaker-app/"
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'linkedin',
                  });
                }}
              >
                <img src="./svg/linkedin.svg" alt="linkedin" />
              </a>
            </div>
            <div className={s.download}>
              <a
                href="https://apps.apple.com/app/eventmaker-vendors/id6479720031"
                target="_blank"
                rel="noreferrer"
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'appstore',
                  });
                }}
              >
                <img src="./svg/appstoreWhite.svg" alt="appstore" />
              </a>
              <a
                onClick={async () => {
                  await trackEvent(AnalyticEventsEnum.REDIRECT_TO, {
                    redirectTo: 'googleplay',
                  });
                }}
              >
                <img src="./svg/googleplayWhite.svg" alt="googleplay" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
