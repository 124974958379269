import React from 'react';
import s_Customer from './About_Customer.module.scss';
import s_Business from './About_Business.module.scss';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { productTypes } from './config';
import { useLandingPageContext } from '../../../context';
import { getStorageUrl } from '../../../Utils/getStorageUrl';
import { PlacesAutocomplete } from '../../../../../components/Location/PlacesAutocomplete';
import clsx from 'clsx';
import { useIsBigScreen } from '../../../../../hooks/hook.big-screen';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';

export const About = () => {
  const { source, actionButtonShortName, onActionClick } =
    useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const { isBigScreen } = useIsBigScreen();

  return (
    <section
      section-order={1}
      className={s.section}
      id={buildAnchorId(AnchorEnum.ABOUT)}
    >
      <div className={s.sectionH1}>
        <div className={s.sectionH1__left}>
          <h1 data-animation style={{ whiteSpace: 'pre-wrap' }}>
            {source === 'customer'
              ? 'Easily plan\nyour next event'
              : 'Working in\nevent industry?'}
          </h1>
          <p data-animation style={{ whiteSpace: 'pre-wrap' }}>
            {source === 'customer'
              ? 'Find venues, rentals, organizers \nand other event services'
              : 'Add your services to our platform\nand start earning more!'}
          </p>

          <div data-animation className={s.sectionH1__btns}>
            <div style={{ flexGrow: '1', maxWidth: '260px' }}>
              <PlacesAutocomplete
                mode={
                  source === 'customer' ? 'landingCustomer' : 'landingBusiness'
                }
              />
            </div>

            <button
              className={clsx(s.button, s.explore)}
              onClick={async () => {
                onActionClick();
                await trackEvent(AnalyticEventsEnum.CLICK__LANDING_CTA, {
                  source,
                  sectionOrder: '1',
                  placement: 'main',
                });
              }}
            >
              {source === 'customer' && isBigScreen && (
                <img alt="" src="./svg/searchIcon_Common.svg" />
              )}
              {actionButtonShortName}{' '}
              <img
                alt=""
                src="./svg/arrowAction_Common.svg"
                style={{ width: isBigScreen ? '26px' : '20px' }}
              />
            </button>
          </div>
        </div>
        <div className={s.sectionH1__right}>
          <img
            loading="lazy"
            data-animation
            className={
              source === 'customer' ? s.img__customer : s.img__business
            }
            src={
              source === 'customer'
                ? getStorageUrl('landing/phone/main_preview.png')
                : getStorageUrl('landing/ladys.webp')
            }
            alt=""
          />
        </div>
      </div>
      <div data-animation className={s.sectionH1__categories}>
        {productTypes.map((category, index) => (
          <div
            key={index}
            className={s.category}
            data-animation
            onClick={async () => {
              onActionClick(); // TODO: Make detailed redirects
              await trackEvent(AnalyticEventsEnum.CLICK__LANDING_CTA, {
                source,
                sectionOrder: '1',
                placement: category.title,
              });
            }}
          >
            <img
              loading="lazy"
              alt={category.title}
              className={s.sectionH1__categoryImg}
              src={category.src}
            />
            <div className={s.category__bg} />
            <p className={s.category__title}>{category.title}</p>
            <div className={s.sectionH1__explore}>
              <p>{actionButtonShortName}</p>
              <img src="./svg/arrowBold_Common.svg" alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
