import { useMedia } from './hook.media';

export const useIsBigScreen = (breakpoint?: string) => {
  // $mobile-max-width
  const isBigScreen = useMedia(
    [`(min-width: ${breakpoint || '1150px'})`],
    [true],
    false
  );
  return { isBigScreen };
};
