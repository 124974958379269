import { CommonLanding } from './CommonLanding';
import { LandingPageContextProvider } from './context';

const LandingPageBusiness = () => {
  return (
    <LandingPageContextProvider source="business">
      <CommonLanding />
    </LandingPageContextProvider>
  );
};

export default LandingPageBusiness;
