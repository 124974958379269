import { IonContent, IonPage } from '@ionic/react';
import Centralizer from '../Centralizer/Centralizer';
import Header, { HeaderProps } from '../Header/Header';
import { useTypedSelector } from '../../redux/hooks';
import s from './Page.module.scss';
import clsx from 'clsx';
import React from 'react';
import BuyBlock, { BuyBlockProps } from '../BuyBlock/BuyBlock';

type Props = {
  children?: React.ReactNode;
  enableVerticalCentralization?: boolean;
  enableImageBackground?: boolean;
  pageLimitDisabled?: boolean;
  pageLimitSize?: string;
  headerProps?: HeaderProps;
  disableDefaultIonContent?: boolean;
  buyBlockProps?: BuyBlockProps;
};

const Page: React.FC<Props> = ({
  children,
  enableVerticalCentralization,
  enableImageBackground,
  pageLimitSize,
  pageLimitDisabled,
  headerProps,
  disableDefaultIonContent,
  buyBlockProps,
}) => {
  const isHeaderPresented = !!headerProps;
  const isScreenLocked = useTypedSelector(s => s.global.screenLock)!;

  return (
    <IonPage className="myIonPage">
      {isHeaderPresented && <Header {...headerProps} />}

      {disableDefaultIonContent ? (
        <Centralizer
          fullHeight
          enablePagePadding
          pageLimitSize={
            pageLimitSize || (!pageLimitDisabled ? '500px' : undefined)
          }
          enableVerticalCentralization={enableVerticalCentralization}
        >
          {isScreenLocked && <div className={clsx(s.ScreenLock)} />}
          {children}
        </Centralizer>
      ) : (
        <>
          {buyBlockProps && <BuyBlock {...buyBlockProps} />}

          <IonContent
            className={clsx(
              enableImageBackground ? 'photo_background' : undefined,
              'myIonContent'
            )}
            style={{
              '--overflow': isScreenLocked ? 'hidden' : 'auto',
            }}
          >
            <Centralizer
              enablePagePadding
              pageLimitSize={
                pageLimitSize || (!pageLimitDisabled ? '500px' : undefined)
              }
              enableVerticalCentralization={enableVerticalCentralization}
            >
              {isScreenLocked && <div className={clsx(s.ScreenLock)} />}
              {children}

              {/* Compensate absolute tabbar */}
              {!enableVerticalCentralization && (
                <div style={{ height: '70px' }} />
              )}
            </Centralizer>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Page;
