import { useCallback, useEffect, useRef, useState } from 'react';
import { Product, ProductCategory } from '../../models/product';
import { useLazyGetProductsBySearchQuery } from '../../redux/api';

export const ONE_CATEGORY_LIMIT = 30; // Could be 30, 60, 90...
export const INITIAL_PER_CATEGORY_LIMIT = 10; // Could be 10

export type StatusType = 'error' | 'loading' | 'finish' | undefined;

export type FetchMoreType = (category: ProductCategory) => Promise<StatusType>;

/*
 * Relevant for search by EVENT category
 */
function groupByCategory(products: Product[], categories: ProductCategory[]) {
  const productsByCategories: { [key: string]: Product[] } = {};

  if (categories.length === 0) return {};

  for (const category of categories) {
    productsByCategories[category.id] = [];
  }

  for (const product of products) {
    if (product.category.id in productsByCategories) {
      productsByCategories[product.category.id].push(product);
    } else {
      console.warn(
        'Category missed on user side, but exist on backend.' +
          'Probably category list was updated'
      );
      productsByCategories[product.category.id] = [product];
    }
  }

  return productsByCategories;
}

function useFetchMoreByCategory({
  endIsReachedCategories,
  search,
  groupedProducts,
  setGroupedProducts,
  skipInitialization,
}: {
  endIsReachedCategories: React.MutableRefObject<string[]>;
  search: string;
  groupedProducts: { [key: string]: Product[] };
  setGroupedProducts: React.Dispatch<
    React.SetStateAction<{ [key: string]: Product[] }>
  >;
  skipInitialization: boolean;
}): FetchMoreType {
  const [trigger] = useLazyGetProductsBySearchQuery();

  const statuses = useRef<{ [categoryId: string]: StatusType }>({});

  const fetchMore = useCallback(
    (category: ProductCategory) => {
      return new Promise<StatusType>(async (resolve, reject) => {
        if (skipInitialization) {
          return reject(new Error('This method should not be called'));
        }

        if (statuses.current[category.id] === 'loading') {
          return resolve('loading');
        }

        if (endIsReachedCategories.current.includes(category.id)) {
          return resolve('finish');
        }

        statuses.current = { ...statuses.current, [category.id]: 'loading' };

        const productsFetchedBefore = groupedProducts[category.id].filter(
          p => typeof p !== 'function' // this is necessary because we pushed ctx to array
        );

        const { data, error } = await trigger({
          search: `${search}&product_category_id=${category.id}`,
          offset: productsFetchedBefore.length,
          limit: ONE_CATEGORY_LIMIT,
        });

        if (!data || error) {
          statuses.current = { ...statuses.current, [category.id]: 'error' };
          return resolve('error');
        }

        if (data.length) {
          setGroupedProducts(groupedProducts => ({
            ...groupedProducts,
            [category.id]: [
              ...groupedProducts[category.id].slice(0, -1),
              ...data,
            ],
          }));
        }

        if (data.length < ONE_CATEGORY_LIMIT) {
          endIsReachedCategories.current.push(category.id);
          statuses.current = { ...statuses.current, [category.id]: 'finish' };
          return resolve('finish');
        } else {
          statuses.current = { ...statuses.current, [category.id]: undefined };
          return resolve(undefined);
        }
      });
    },
    [
      groupedProducts,
      search,
      trigger,
      endIsReachedCategories,
      setGroupedProducts,
      skipInitialization,
    ]
  );

  return fetchMore;
}

export const useGroupedByCategoryProducts = ({
  productCategories,
  initialData,
  search,
  skipInitialization,
}: {
  productCategories: ProductCategory[];
  initialData: Product[] | undefined;
  search: string;
  skipInitialization: boolean;
}) => {
  const endIsReachedCategories = useRef<string[]>([]);

  const [groupedProducts, setGroupedProducts] = useState<{
    [key: string]: Product[];
  }>({});

  useEffect(() => {
    if (skipInitialization) return;
    if (!initialData) return;

    endIsReachedCategories.current = [];

    const initiallyGroupedProducts = groupByCategory(
      initialData,
      productCategories
    );

    for (const [categoryId, products] of Object.entries(
      initiallyGroupedProducts
    )) {
      if (products.length < INITIAL_PER_CATEGORY_LIMIT) {
        endIsReachedCategories.current.push(categoryId);
      }
    }

    setGroupedProducts(initiallyGroupedProducts);
  }, [initialData, productCategories, skipInitialization]);

  const fetchMore = useFetchMoreByCategory({
    endIsReachedCategories,
    search,
    groupedProducts,
    setGroupedProducts,
    skipInitialization,
  });

  return { groupedProducts, fetchMore };
};

/*
 *
 * Relevant for search by PRODUCT category
 */
function groupByGrid(products: Product[], productsInOneRow: number) {
  const productsByGrid: { [rowNumber: string]: Product[] } = {};

  let i = 0;
  while (i < products.length) {
    let rowProducts = [];
    for (let j = 0; j < productsInOneRow && i < products.length; j++) {
      rowProducts.push(products[i]);
      i++;
    }
    productsByGrid[i.toString()] = rowProducts;
  }

  return productsByGrid;
}

function useFetchMoreByGrid({
  productCategory,
  endIsReached,
  fetchedProducts,
  setFetchedProducts,
  search,
  status,
  setStatus,
  skipInitialization,
}: {
  endIsReached: React.MutableRefObject<boolean>;
  productCategory: ProductCategory;
  fetchedProducts: Product[];
  setFetchedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  search: string;
  status: StatusType;
  setStatus: React.Dispatch<React.SetStateAction<StatusType>>;
  skipInitialization: boolean;
}): FetchMoreType {
  const [trigger] = useLazyGetProductsBySearchQuery();

  const fetchMore = useCallback(
    async (category: ProductCategory) => {
      return new Promise<StatusType>(async (resolve, reject) => {
        if (skipInitialization) {
          return reject(new Error('This method should not be called'));
        }

        if (category !== productCategory) {
          return reject('Provided category does not match with expected.');
        }

        if (status === 'loading') {
          return resolve('loading');
        }

        if (endIsReached.current) {
          setStatus('finish');
          return resolve('finish');
        }

        setStatus('loading');

        const { data, error } = await trigger({
          search,
          offset: fetchedProducts.length,
          limit: ONE_CATEGORY_LIMIT,
        });

        if (!data || error) {
          setStatus('error');
          return resolve('error');
        }

        if (data.length) {
          setFetchedProducts(fetchedProducts => [...fetchedProducts, ...data]);
        }

        if (data.length < ONE_CATEGORY_LIMIT) {
          endIsReached.current = true;
          setStatus('finish');
          return resolve('finish');
        } else {
          setStatus(undefined);
          return resolve(undefined);
        }
      });
    },
    [
      endIsReached,
      search,
      trigger,
      fetchedProducts,
      productCategory,
      setFetchedProducts,
      status,
      setStatus,
      skipInitialization,
    ]
  );

  return fetchMore;
}

export const useGroupedByGridProducts = ({
  productCategory,
  initialData,
  search,
  productsInOneRow,
  skipInitialization,
}: {
  productCategory: ProductCategory;
  initialData: Product[] | undefined;
  search: string;
  productsInOneRow: number;
  skipInitialization: boolean;
}) => {
  const endIsReached = useRef(false);

  const [status, setStatus] = useState<StatusType>();
  useEffect(() => {
    setStatus(undefined);
  }, [search]);

  const [fetchedProducts, setFetchedProducts] = useState<Product[]>([]);
  const [groupedProducts, setGroupedProducts] = useState<{
    [key: string]: Product[];
  }>({});

  useEffect(() => {
    if (skipInitialization) return;
    if (!initialData) return;

    endIsReached.current = false;
    setStatus(undefined);

    if (initialData.length < ONE_CATEGORY_LIMIT) {
      endIsReached.current = true;
      setTimeout(() => setStatus('finish'));
    }

    setFetchedProducts(initialData);
  }, [initialData, productCategory, skipInitialization]);

  useEffect(() => {
    if (skipInitialization) return;
    setGroupedProducts(groupByGrid(fetchedProducts, productsInOneRow));
  }, [fetchedProducts, productsInOneRow, skipInitialization]);

  const fetchMore = useFetchMoreByGrid({
    productCategory,
    endIsReached,
    fetchedProducts,
    setFetchedProducts,
    search,
    status,
    setStatus,
    skipInitialization,
  });

  return {
    groupedProducts,
    fetchMore,
    productsInOneRow,
    status,
  };
};
