import { CombinedState } from '@reduxjs/toolkit';
import { convertToOtherCase } from '../../../@shared/helpers/case';
import { SocialProvidersSignInParams } from '../../../components/Auth/SocialProviders/SocialProviders';
import { Business, BusinessCategory } from '../../../models/business';
import { EventCategory } from '../../../models/event';
import { Organizer } from '../../../models/organizer';
import { ProductCategory } from '../../../models/product';
import { User } from '../../../models/user';
import { ReduxActionCreator, ReduxRootState } from '../../store';
import { choicesSlice } from '../choices/slice';
import { globalActionCreators } from '../global/actionCreator';
import { subjectsSlice, SubjectsStorageKeys } from '../subjects/slice';
import { AuthPhaseType, authSlice, AuthStorageKeys, ExtraData } from './slice';
import { ToastPriorityEnum } from '../../../utils/enums';

const clearStorage = () => {
  // localStorage.clear(); -- Before it was this
  localStorage.removeItem(AuthStorageKeys.TOKEN);
  localStorage.removeItem(AuthStorageKeys.USER);
  localStorage.removeItem(SubjectsStorageKeys.ACTIVE_SUBJECT);
  localStorage.removeItem(SubjectsStorageKeys.BUSINESS_SET);
  localStorage.removeItem(SubjectsStorageKeys.ORGANIZER_SET);
};

const getHeaders = (getState: () => CombinedState<ReduxRootState>) => {
  const state = getState();

  return {
    'Content-Type': 'application/json',
    'Selected-Language': state.auth.selectedLanguage || 'en',
    'Return-URL': encodeURI(state.auth.loginRedirectUrl || ''),
  };
};

const saveCredentialsToBrowser = async (email: string, password: string) => {
  try {
    if ('credentials' in navigator && 'PasswordCredential' in window) {
      const credentials = new (window.PasswordCredential as any)({
        id: email,
        password,
      });
      await navigator.credentials.store(credentials);
    }
  } catch (error) {
    console.error('Error saving credentials:', error);
  }
};

const signup: ReduxActionCreator<{
  email: string;
  password1: string;
  password2: string;
}> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/registration/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({
            email: variables.email,
            password1: variables.password1,
            password2: variables.password2,
          }),
        }
      );

      if (response.status === 201) {
        dispatch(
          updateExtraData({
            email: variables.email,
            password: variables.password1,
          })
        );
        dispatch(changeAuthPhase('emailVerificationRequire_Page'));
        await saveCredentialsToBrowser(variables.email, variables.password1);
      } else {
        const res = convertToOtherCase(await response.json(), 'camel');
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const signin: ReduxActionCreator<{
  email: string;
  password: string;
  throwErrorThatEmailIsNotConfirmed?: boolean;
}> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/login/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({
            email: variables.email,
            password: variables.password,
          }),
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      if (response.status === 200) {
        const token = res.key as string;
        dispatch(authSlice.actions.setToken(token));
        await dispatch(
          fetchAllPrivateData({ nextAuthPhase: 'loginCompleted' })
        );
      } else if (
        response.status === 400 &&
        res.detail === 'E-mail is not verified.'
      ) {
        dispatch(
          updateExtraData({
            email: variables.email,
            password: variables.password,
          })
        );
        dispatch(changeAuthPhase('emailVerificationRequire_Page'));
        await saveCredentialsToBrowser(variables.email, variables.password);
        if (variables.throwErrorThatEmailIsNotConfirmed) {
          throw new Error('E-mail is not confirmed.');
        }
      } else {
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const signInBySocialProvider: ReduxActionCreator<
  SocialProvidersSignInParams
> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      let body;
      if (variables.provider === 'google') {
        body = { access_token: variables.googleAccessToken };
      } else if (variables.provider === 'apple' && !variables.isIosNative) {
        body = {
          code: variables.appleCode,
          id_token: variables.appleIdToken,
        };
      } else if (variables.provider === 'apple' && variables.isIosNative) {
        body = {
          access_token: variables.appleCode,
          id_token: variables.appleIdToken,
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/login-${variables.provider}/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify(body),
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      if (response.status === 200) {
        const token = res.key as string;
        dispatch(authSlice.actions.setToken(token));
        await dispatch(
          fetchAllPrivateData({ nextAuthPhase: 'loginCompleted' })
        );
      } else {
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const emailVerificationResend: ReduxActionCreator = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      const extraData = getState().auth.extraData!;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/registration/resend-email/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({ email: extraData.email }),
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      if (response.status === 200) {
        return;
      } else if (
        response.status === 400 &&
        res[0] === 'Account is already verified'
      ) {
        dispatch(
          updateExtraData({
            emailIsVerified: true,
          })
        );
      } else {
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const emailVerificationProcess: ReduxActionCreator = () => {
  return async (dispatch, getState) => {
    try {
      const extraData = getState().auth.extraData!;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/registration/confirm-email/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({
            key: extraData.emailVerificationKey,
          }),
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      if (res.status === 'confirmed') {
        dispatch(
          globalActionCreators.setToastObject({
            message: 'Email verified.',
            color: 'danger',
            priority: ToastPriorityEnum.HIGH,
          })
        );
        const token = res.key as string;
        dispatch(authSlice.actions.setToken(token));
        await dispatch(
          fetchAllPrivateData({ nextAuthPhase: 'loginCompleted' })
        );
      } else if (res.status === 'confirmed_in_past') {
        throw new Error(
          'Email adress already was confirmed in past. Link is not valid anymore.'
        );
      } else {
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    }
  };
};

const fetchAllPrivateData: ReduxActionCreator<{
  nextAuthPhase: 'loginCompleted' | null;
}> = variables => {
  return async (dispatch, getState) => {
    try {
      const token = getState().auth._token;

      if (!token) {
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/get-all-private-data`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      const user = res.user as User;
      const businessSet = res.businessSet as Business[];
      const organizerSet = res.organizerSet as Organizer[];

      const isUserStillAuthenticated = !!getState().auth._token;
      if (!isUserStillAuthenticated) {
        return; // if auth token lost in the middle of process
      }

      dispatch(subjectsSlice.actions.setBusinessSet(businessSet));
      dispatch(subjectsSlice.actions.setOrganizerSet(organizerSet));
      dispatch(authSlice.actions.setUser(user));

      if (variables.nextAuthPhase === 'loginCompleted') {
        dispatch(changeAuthPhase('loginCompleted'));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

const fetchAllPublicData: ReduxActionCreator = () => {
  return async dispatch => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/get-all-public-data`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const res = convertToOtherCase(await response.json(), 'camel');

      // const countrySet = res.countrySet as Country[];
      // const citySet = res.citySet as City[];
      const businessCategorySet = res.businessCategorySet as BusinessCategory[];
      const eventCategorySet = res.eventCategorySet as EventCategory[];
      const productCategorySet = res.productCategorySet as ProductCategory[];

      // dispatch(choicesSlice.actions.setCitySet(citySet));
      // dispatch(choicesSlice.actions.setCountrySet(countrySet));
      dispatch(
        choicesSlice.actions.setBusinessCategorySet(businessCategorySet)
      );
      dispatch(choicesSlice.actions.setEventCategorySet(eventCategorySet));
      dispatch(choicesSlice.actions.setProductCategorySet(productCategorySet));
      dispatch(choicesSlice.actions.setReceivedAt(String(new Date())));
    } catch (err) {
      console.error(err);
    }
  };
};

const passwordReset: ReduxActionCreator<{ email: string }> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/password/reset/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({
            email: variables.email,
          }),
        }
      );

      if (response.status === 200) {
        dispatch(changeAuthPhase('passwordResetConfirmRequired_Page'));
      } else {
        const res = convertToOtherCase(await response.json(), 'camel');
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const passwordResetConfirmationProcess: ReduxActionCreator<{
  password1: string;
  password2: string;
}> = variables => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));
      await dispatch(
        updateExtraData({
          password: variables.password1,
        })
      );

      const extraData = getState().auth.extraData!;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/password/reset/confirm/`,
        {
          method: 'POST',
          headers: getHeaders(getState),
          body: JSON.stringify({
            new_password1: variables.password1,
            new_password2: variables.password2,
            token: extraData.passwordResetToken,
            uid: extraData.passwordResetUid,
          }),
        }
      );

      if (response.status == 200) {
        await dispatch(loginBySavedExtraData());
      } else {
        const res = convertToOtherCase(await response.json(), 'camel');
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const loginBySavedExtraData: ReduxActionCreator = () => {
  return async (dispatch, getState) => {
    const extraData = getState().auth.extraData!;
    await dispatch(
      signin({
        email: extraData.email!,
        password: extraData.password!,
        throwErrorThatEmailIsNotConfirmed: true,
      })
    );
  };
};

const deleteSelfAccount: ReduxActionCreator = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(authSlice.actions.setLoading(true));

      const token = getState().auth._token;

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/authentication/delete-self-account/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 204) {
        dispatch(logout());
      } else {
        const res = convertToOtherCase(await response.json(), 'camel');
        throw new Error(res.detail);
      }
    } catch (err: any) {
      dispatch(globalActionCreators.setErrorRaw(err));
    } finally {
      dispatch(authSlice.actions.setLoading(false));
    }
  };
};

const changeAuthPhase: ReduxActionCreator<AuthPhaseType> = authPhase => {
  return async dispatch => {
    if (authPhase === 'signIn_Page' || authPhase === 'signUp_Page') {
      clearStorage();
    }
    dispatch(authSlice.actions.setAuthPhase(authPhase));
  };
};

const updateExtraData: ReduxActionCreator<ExtraData> = inputedExtraData => {
  return async (dispatch, getState) => {
    const oldExtraData = getState().auth.extraData || {};
    const newExtraData = { ...oldExtraData, ...inputedExtraData };
    dispatch(authSlice.actions.setExtraData(newExtraData));
  };
};

const goBackToMainLoginPage: ReduxActionCreator = () => {
  return async dispatch => {
    dispatch(changeAuthPhase('signIn_Page'));
  };
};

const logout: ReduxActionCreator = () => {
  return async dispatch => {
    clearStorage();
    dispatch(changeAuthPhase('logoutCompleted'));
  };
};

const checkConsistencyOfLocalStorageData: ReduxActionCreator = () => {
  return async (dispatch, getState) => {
    const authState = getState().auth;
    const subjectsState = getState().subjects;

    const savedToken = authState._token;
    const savedUser = authState.user;

    const savedBusinessSet = subjectsState.businessSet;
    const savedOrganizerSet = subjectsState.organizerSet;

    if (savedToken && savedUser && savedBusinessSet && savedOrganizerSet) {
      // alright
    } else if (
      !savedToken &&
      !savedUser &&
      !savedBusinessSet &&
      !savedOrganizerSet
    ) {
      // alright
    } else {
      // something broken
      dispatch(logout());
    }
  };
};

export const authActionCreators = {
  signInBySocialProvider,
  signin,
  signup,
  emailVerificationResend,
  emailVerificationProcess,
  loginBySavedExtraData,
  logout,
  goBackToMainLoginPage,
  passwordReset,
  passwordResetConfirmationProcess,
  updateExtraData,
  changeAuthPhase,
  fetchAllPrivateData,
  fetchAllPublicData,
  checkConsistencyOfLocalStorageData,
  deleteSelfAccount,
};
