import usePlacesAutocomplete, { getLatLng } from 'use-places-autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment, TextField } from '@mui/material';
import { IonIcon } from '@ionic/react';
import s from './PlacesAutocomplete.module.scss';
import { locationOutline } from 'ionicons/icons';
import clsx from 'clsx';
import {
  AutocompletePrediction,
  getSeoString,
  LocationFullType,
} from './config';
import { useIsBigScreen } from '../../hooks/hook.big-screen';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { locationActionCreators } from '../../redux/slices/location/actionCreators';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

const fixedData: AutocompletePrediction[] = [
  {
    place_id: 'ChIJVXealLU_xkcRja_At0z9AGY',
    description: 'Amsterdam, Netherlands',
    structured_formatting: {
      main_text: 'Amsterdam',
      secondary_text: 'Netherlands',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  {
    place_id: 'ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ',
    description: 'Bali, Indonesia',
    structured_formatting: {
      main_text: 'Bali',
      secondary_text: 'Indonesia',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  // {
  //   place_id: 'ChIJ5TCOcRaYpBIRCmZHTz37sEQ',
  //   description: 'Barcelona, Spain',
  //   structured_formatting: {
  //     main_text: 'Barcelona',
  //     secondary_text: 'Spain',
  //     main_text_matched_substrings: [],
  //   },
  //   matched_substrings: [],
  //   terms: [],
  //   types: [],
  // },
  {
    place_id: 'ChIJPV4oX_65j4ARVW8IJ6IJUYs',
    description: 'California, USA',
    structured_formatting: {
      main_text: 'California',
      secondary_text: 'USA',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
  {
    place_id: 'ChIJRcbZaklDXz4RYlEphFBu5r0',
    description: 'Dubai - United Arab Emirates',
    structured_formatting: {
      main_text: 'Dubai',
      secondary_text: 'United Arab Emirates',
      main_text_matched_substrings: [],
    },
    matched_substrings: [],
    terms: [],
    types: [],
  },
];

const fixedDataPlacesIds = fixedData.map(d => d.place_id);
export const lockedLocation: AutocompletePrediction = fixedData.find(
  d => d.description === 'Bali, Indonesia'
)!;

export const PlacesAutocomplete = ({
  mode,
  height,
  openLocationVoter,
  controlledValue,
  onChange,
}: {
  mode?: 'landingCustomer' | 'landingBusiness' | 'header' | 'form';
  height?: string;
  openLocationVoter?: () => void;
  controlledValue?: LocationFullType; // form
  onChange?: (location: LocationFullType) => void; // form
}) => {
  const { isBigScreen } = useIsBigScreen();

  const {
    ready,
    value,
    suggestions: { data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // callbackName: 'googleMapsCallback',
    requestOptions: {
      types: ['(regions)'],
    },
    debounce: 300,
  });

  const dispatch = useTypedDispatch();
  const { selectedLocation, detectedLocation } = useTypedSelector(
    s => s.location
  );
  const location = controlledValue || selectedLocation || detectedLocation;

  const handleSelect = (value: AutocompletePrediction) => {
    trackEvent(AnalyticEventsEnum.LOCATION_CHANGED, {
      location: value.description,
      previousLocation: selectedLocation?.description,
      detectedLocation: detectedLocation?.description,
    });

    setValue(value.description, false);

    clearSuggestions();

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: value.description }, (results, status) => {
      if (status !== 'OK' || !results || !results[0]) {
        throw new Error('Can not detect place.');
      }

      // Detect country
      let countryName = '';
      let countryCode = '';
      const countryComponent = results[0].address_components.find(d =>
        d.types.includes('country')
      );

      if (countryComponent && countryComponent.types.includes('country')) {
        countryName = countryComponent.long_name;
        countryCode = countryComponent.short_name;
      }

      const { lat, lng } = getLatLng(results[0]);

      const location: LocationFullType = {
        ...value,
        coordinates: { lat, lng },
        countryCode,
        seoLine: getSeoString({
          mainText: value.structured_formatting.main_text,
          secondaryText: value.structured_formatting.secondary_text,
        }),
      };

      onChange?.(location);
      if (mode !== 'form')
        dispatch(locationActionCreators.selectLocation(location));
    });
  };

  const isLocked =
    !!openLocationVoter && location?.place_id !== lockedLocation?.place_id;

  // TODO ASAP: Remove when multiple locations will be enabled
  const preventOpening = (e: any) => {
    if (openLocationVoter) {
      if (e.preventDefault) e.preventDefault();
      if (e.stopPropagation) e.stopPropagation();
      if (e.stopImmediatePropagation) e.stopImmediatePropagation();
      openLocationVoter();
    }
  };

  return (
    <Autocomplete
      value={isLocked ? lockedLocation : location || (null as any)}
      slotProps={{
        popupIndicator: {
          style: {
            display: mode === 'form' ? 'none' : 'flex',
          },
        },
        paper: {
          style: {
            maxHeight: '140px',
            overflow: 'auto',
          },
        },
      }}
      disableClearable
      sx={{
        width: '100%',
        height: '100%',
        '& fieldset': {
          borderRadius: mode === 'header' || mode === 'form' ? '10px' : '30px',
        },
        '& .MuiSvgIcon-root': {
          color: mode !== 'landingBusiness' ? 'white' : 'black',
        },

        // No text elipsis
        '& .MuiInputBase-input': {
          textOverflow: 'clip',
          whiteSpace: 'normal',
          overflow: 'visible',
          display: 'block',
        },

        '.MuiInputBase-root': {
          fontSize: mode === 'header' ? '16px' : isBigScreen ? '20px' : '16px',
          height: height || (isBigScreen ? '56px' : '48px'),
          paddingRight: '30px !important',
          paddingLeft: mode === 'form' ? '7px !important' : '12px !important',
        },

        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor:
              mode === 'landingCustomer'
                ? 'white'
                : mode === 'form'
                ? 'var(--color-main-low)'
                : '#333131',
          },
          '&:hover fieldset': {
            borderColor:
              mode === 'form' ? 'var(--color-main-low)' : 'rgb(150, 150, 150)',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(150, 150, 150)',
          },
        },
      }}
      options={[
        ...fixedData.filter(d =>
          d.description.toLowerCase().startsWith(value.toLowerCase())
        ),
        ...data.filter(d => !fixedDataPlacesIds.includes(d.place_id)),
      ]}
      getOptionKey={option => option.place_id}
      getOptionLabel={option => option?.structured_formatting?.main_text}
      loading={!ready || loading}
      onChange={(_, v) => (v ? handleSelect(v) : null)}
      onInputChange={(_, newValue) => setValue(newValue)}
      renderOption={({ key, ...props }, option: AutocompletePrediction) => (
        <li key={key} {...props}>
          <p>
            <span style={{ color: 'black' }}>
              {option.structured_formatting?.main_text}
            </span>
            <span
              style={{
                color: 'grey',
                marginLeft: '6px',
                fontSize: '13px',
                marginBottom: '-2px',
              }}
            >
              {option.structured_formatting?.secondary_text}
            </span>
          </p>
        </li>
      )}
      noOptionsText={null}
      renderInput={params => (
        <TextField
          {...params}
          //
          onMouseDownCapture={preventOpening}
          onTouchStartCapture={preventOpening}
          onPointerDownCapture={preventOpening}
          onClickCapture={preventOpening}
          //
          sx={{
            input: {
              color: isLocked
                ? 'var(--ion-color-secondary-tint)'
                : mode !== 'landingBusiness'
                ? 'white'
                : 'black',
            },
          }}
          fullWidth
          slotProps={{
            input: {
              ...params.InputProps,
              startAdornment:
                mode !== 'form' ? (
                  <InputAdornment position="start" sx={{ marginRight: 0 }}>
                    <IonIcon
                      icon={locationOutline}
                      color={isLocked ? 'secondary' : undefined}
                      className={clsx(
                        s.LocationIcon,
                        mode === 'header' ? s.LocationIcon__mini : undefined,
                        mode === 'landingBusiness'
                          ? s.LocationIcon__black
                          : undefined
                      )}
                    />
                  </InputAdornment>
                ) : undefined,
            },
          }}
        />
      )}
    />
  );
};
