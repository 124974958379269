import React from 'react';
import s from './AreYouBusiness.module.scss';
import { ActionButton } from '../../ActionButton/ActionButton';
import { useLandingPageContext } from '../../../context';
import { getStorageUrl } from '../../../Utils/getStorageUrl';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';

export const AreYouBusiness = () => {
  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section
      id={buildAnchorId(AnchorEnum.JOIN)}
      section-order={8}
      className={s.section}
    >
      <div data-animation className={s.left}>
        <h3 className={s.section__title} style={{ whiteSpace: 'pre-wrap' }}>
          Are you a <span>business</span>
          {`\n`}providing event services?
        </h3>
        <p className={s.section__text}>
          Join the platform and gain new clients. Only for officially registered
          event businesses.
        </p>
        <span>
          <ActionButton
            onClick={() => (window.location.href = `/join`)}
            skipDefaultAction
            text="Know More"
            ctaDetail="business"
          />
        </span>
      </div>
      <img
        loading="lazy"
        data-animation
        alt=""
        className={s.img}
        src={getStorageUrl('landing/busy.webp')}
      />
    </section>
  );
};
