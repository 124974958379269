import React, { useCallback, useMemo } from 'react';
import BusinessDataForm, {
  BusinessFormValues,
} from '../../../components/BusinessForm/BusinessForm';
import Page from '../../../components/Page/Page';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { subjectsActionCreators } from '../../../redux/slices/subjects/actionCreators';
import { StorageDirectoryData, StorageFolderEnum } from '../../../@shared/file';
import { ToastPriorityEnum } from '../../../utils/enums';
import { globalActionCreators } from '../../../redux/slices/global/actionCreator';
import { convertLocationToBackendData } from '../../../@shared/helpers/utils';

// TODO: useUpdateBusiness mutation
// then we would not need "convertLocationToBackendData" and "subjectsActionCreators.updateBusiness"
const BusinessAccountDataPage: React.FC = () => {
  const dispatch = useTypedDispatch();

  const businessSet = useTypedSelector(s => s.subjects.businessSet)!;
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject)!;

  const business = businessSet.find(
    business => business.id === activeSubject.objectId
  )!;

  const storageDirectoryData: StorageDirectoryData = useMemo(() => {
    return {
      storageFolder: StorageFolderEnum.BUSINESS_AVATAR,
      businessId: business.id,
    };
  }, [business.id]);

  const submitCallback = useCallback(
    async (values: BusinessFormValues) => {
      const body = {
        name: values.name,
        category: values.category?.id || null,
        category_custom: values.categoryCustom,
        phone_number: values.phoneNumber,
        uploaded_files: values.uploadedFiles.map(x => x.id),
        details: values.details,
        ...convertLocationToBackendData(values.location),
      };

      await dispatch(
        subjectsActionCreators.updateBusiness({ businessId: business.id, body })
      );

      dispatch(
        globalActionCreators.setToastObject({
          message: 'Successfully saved',
          priority: ToastPriorityEnum.HIGH,
          color: 'success',
        })
      );
    },
    [dispatch, business.id]
  );

  return (
    <Page
      disableDefaultIonContent
      headerProps={{ title: 'Business information' }}
    >
      <BusinessDataForm
        action="edit"
        initialFormValues={business}
        submitCallback={submitCallback}
        storageDirectoryData={storageDirectoryData}
      />
    </Page>
  );
};

export default BusinessAccountDataPage;
