import React from 'react';
import EventShortcut, {
  EventShortcutProps,
} from '../components/EventShortcut/EventShortcut';
import Page from '../components/Page/Page';
import { IonCard } from '@ionic/react';
import { useRouteMatch } from 'react-router';

const eventShortcutProps: EventShortcutProps = {
  title: 'Вечеринка в честь день рождения',
  dateFull: '15 декабря 18:00 - 16 декабря 23:00',
  image: undefined,
  badgeCount: 97,
  status: {
    label: 'Статус',
    value: 'в работе!!',
  },
  paid: {
    label: 'Оплачено',
    value: '12 000 из 30 000 Р',
  },
  nextPayment: {
    label: 'Следующий',
    labelSecond: 'платеж',
    value: '10 000 Р',
    valueSecond: '12 декабря 2021',
  },
};

type Props = {
  isBusiness?: boolean;
};

const EventsPage: React.FC<Props> = ({ isBusiness }) => {
  const match = useRouteMatch();

  return (
    <Page
      // pageLimitSize="400px"
      headerProps={{
        title: 'My events',
        hideBackButton: true,
        routerLink__message: `${match.url}/messages`,
        // routerLink__add: '++ todo ++',
      }}
    >
      {/* <EventShortcut {...eventShortcutProps} />
      <EventShortcut {...eventShortcutProps} />
      <EventShortcut {...eventShortcutProps} />
      <EventShortcut {...eventShortcutProps} />
      <EventShortcut {...eventShortcutProps} />
      <EventShortcut {...eventShortcutProps} /> */}

      <IonCard style={{ margin: '10px' }}>
        <p>Here you will see the list of your upcoming events.</p>
      </IonCard>
    </Page>
  );
};

export default EventsPage;
