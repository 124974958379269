import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { animationSlide } from '../../../utils/animation';
import { Route, useRouteMatch } from 'react-router';
import BusinessFaqPage from './BusinessFaqPage';

const BusinessFaqRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path={match.path}>
        <BusinessFaqPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default BusinessFaqRoutes;
