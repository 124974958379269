import clsx from 'clsx';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { useCallback } from 'react';
import { ToastPriorityEnum } from '../../utils/enums';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { useTypedDispatch } from '../../redux/hooks';
import { copyOutline, logoWhatsapp } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import s from './ContactInfoModal.module.scss';
import sg from '../../styles/global.module.scss';
import Button from '../Buttons/Button/Button';
import { WHATS_APP_PHONE_NUMBER } from '../../utils/phoneNumber';

// Used only on "Business" FAQ page
export const PhoneNumber = () => {
  const dispatch = useTypedDispatch();

  const onPhoneCopy = useCallback(() => {
    navigator.clipboard.writeText(WHATS_APP_PHONE_NUMBER);
    dispatch(
      globalActionCreators.setToastObject({
        message: 'Phone number copied to clipboard',
        color: 'success',
        priority: ToastPriorityEnum.HIGH,
      })
    );
  }, [dispatch]);

  return (
    <div className={clsx(sg.Stack_Horizontal, sg.Gap_10)}>
      <div
        className={clsx(
          sg.Stack_Horizontal,
          sg.Justify_Center,
          sg.Gap_10,
          s.Text__dotted_border
        )}
        style={{ width: '100%' }}
      >
        <p className={clsx(s.Text__Content)}>{WHATS_APP_PHONE_NUMBER}</p>

        <ButtonMicroWrapper onClick={onPhoneCopy}>
          <IonIcon src={copyOutline} className={clsx(s.IonIcon__copy)} />
        </ButtonMicroWrapper>
      </div>

      <Button
        text="Whats App"
        color="secondary"
        className={clsx(s.WhatsApp_button)}
        fill="solid"
        logo={logoWhatsapp}
        onClick={() => {
          window.open(`https://wa.me/${WHATS_APP_PHONE_NUMBER}`);
        }}
      />
    </div>
  );
};
