import { CommonLanding } from './CommonLanding';
import { LandingPageContextProvider } from './context';

const LandingPageCustomer = () => {
  return (
    <LandingPageContextProvider source="customer">
      <CommonLanding />
    </LandingPageContextProvider>
  );
};

export default LandingPageCustomer;
