import clsx from 'clsx';
import s from './Button.module.scss';
import Spinner from '../../Spinner/Spinner';
import Centralizer from '../../Centralizer/Centralizer';
import { IonButton, IonIcon } from '@ionic/react';

interface ButtonProps {
  id?: string;
  text: string;
  loading?: boolean;
  disabled?: boolean;
  fill?: 'outline' | 'solid' | 'clear';
  type?: 'submit' | 'button';
  form?: string;
  color: 'primary' | 'secondary' | 'success';
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  routerLink?: string;
  maxWidth?: '200px';
  width?: string;
  className?: string;
  skipCentralizer?: boolean;
  logo?: string;
}

export const Button: React.FC<ButtonProps> = props => {
  return (
    <Centralizer skip={props.skipCentralizer}>
      <IonButton
        mode="md"
        style={{
          width: props.width,
          maxWidth: props.maxWidth,
        }}
        className={clsx(s.IonButton, props.className)}
        id={props.id}
        disabled={props.loading || props.disabled}
        fill={props.fill || 'outline'}
        type={props.type}
        form={props.form}
        onClick={e => {
          if (props.onClick) props.onClick(e);
        }}
        color={props.color}
        routerLink={props.routerLink}
      >
        {props.logo && (
          <IonIcon src={props.logo} style={{ marginRight: '10px' }} />
        )}
        <div>
          {props.loading ? (
            <Spinner />
          ) : (
            <p
              style={{
                color:
                  props.color === 'primary' && props.fill === 'solid'
                    ? 'black'
                    : undefined,
              }}
            >
              {props.text}
            </p>
          )}
        </div>
      </IonButton>
    </Centralizer>
  );
};

export default Button;
