import { NullableNumber } from '../../utils/types';
import { CheckedCategoriesType } from '../ProductCategories/dispatcher';

export enum SearchTypeEnum {
  BY_EVENT_CATEGORY = 'event',
  BY_PRODUCT_CATEGORY = 'product',
  BY_KEYWORDS = 'search',
}

export const searchTypeMapper: { [key in SearchTypeEnum]: string } = {
  [SearchTypeEnum.BY_EVENT_CATEGORY]: 'Event',
  [SearchTypeEnum.BY_PRODUCT_CATEGORY]: 'Services',
  [SearchTypeEnum.BY_KEYWORDS]: 'Search',
};

/* Careful: it's related with backend */
export enum SortByEnum {
  DEFAULT = 'default',
  BY_PRICE = 'price',
  BY_RATING = 'rating',
  BY_NEW = 'new',
}

export interface SearchFormValues {
  sortBy: `${SortByEnum}`;
  peopleCapacity: NullableNumber;
  eventDuration: NullableNumber;
  applySubCategoriesFilter: boolean;
  productCategories: CheckedCategoriesType;

  // ---- specific Venue filters -----
  // allowedWithAnimals?: boolean;
  // allowedToNoise?: boolean;
  // allowedWithOwnDrinksAndFood?: boolean;
  // accessibleForDisabledPeople?: boolean;
  // alcoholOption?: boolean;
  // vegeterianOption?: boolean;
  // isCakeBakery?: boolean;
}
