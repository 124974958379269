import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { animationSlide } from '../../../utils/animation';
import UserNotificationsPage from '../../NotificationsPage';

const UserNotificationsRoutes: React.FC = () => {
  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path="/notifications">
        <UserNotificationsPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserNotificationsRoutes);
