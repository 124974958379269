import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { PlacesAutocomplete } from '../../Location/PlacesAutocomplete';
import { useEffect, useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import InputYAnchor from '../InputYAnchor/InputYAnchor';
import { usePointLocation } from '../../../hooks/hook.location';
import { LocationFullType } from '../../Location/config';

interface InputLocationProps {
  label?: string;
  /* react-hook-form */
  _name: string;
  _control: Control<any, any>;
  _register: UseFormRegister<any>;
  _setValue: UseFormSetValue<any>;
  _errorText?: string;
}

export const InputLocation: React.FC<InputLocationProps> = props => {
  const _registered = props._register(props._name);

  const pointLocation = usePointLocation();

  const _initialValue = useWatch({
    control: props._control,
    name: props._name,
    disabled: true,
  }) as LocationFullType;

  const [value, setValue] = useState<LocationFullType>(_initialValue);

  useEffect(() => {
    props._setValue(props._name, value || pointLocation, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [value]);

  return (
    <InputWrapper label={props.label} errorText={props._errorText}>
      <PlacesAutocomplete
        height="43px"
        mode="form"
        onChange={location => setValue(location)}
        controlledValue={value}
      />

      <InputYAnchor refCallback={_registered.ref} />
    </InputWrapper>
  );
};
