import { IonCard, IonIcon, useIonRouter } from '@ionic/react';
import { Text } from '../atoms/Text/Text';
import { Stack } from '@mui/material';
import { TextLink } from '../TextLink/TextLink';
import { AboutCard } from './AboutCard';
import Centralizer from '../Centralizer/Centralizer';
import { Icons } from '../../icons';
import { InstagramButton } from '../../pages/landing/Components/ActionButton/InstagramButton';
import { logoWhatsapp } from 'ionicons/icons';
import { useState } from 'react';
import { CustomerHelpModal } from '../ConfirmationModal/CustomerHelpModal';
import { useTextContentContext } from '../TextContentModal/context';
import { ContentReviews } from '../ContentText/ContentReviews';
import { ContentPrices } from '../ContentText/ContentPrices';
import { Link } from 'react-router-dom';

export const AboutBlock = () => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);

  const { setInfo } = useTextContentContext();

  const router = useIonRouter();

  return (
    <>
      <CustomerHelpModal isOpen={isOpen} close={close} placement="product" />

      <IonCard
        style={{
          borderRadius: '20px',
          margin: '50px 5px',
          padding: '20px 10px',
          background:
            'radial-gradient(circle, #242227 0%, rgba(92, 98, 212, 0.1) 100%)',
          boxShadow: '0px 0px 10px 5px rgba(92, 98, 212, 0.1)',
        }}
      >
        <Stack spacing={2} width="100%">
          <img
            alt=""
            src={Icons.logo}
            style={{
              width: '170px',
              height: '100%',
              maxWidth: 'unset',
              paddingBottom: '5px',
            }}
          />
          <Text
            fontSize="16px"
            extraStyle={{
              marginTop: '10px',
              marginBottom: '5px',
              whiteSpace: 'pre-wrap',
              fontFamily: 'Montserrat',
            }}
          >
            All event services in one place.{`\n`}Visit our{' '}
            <Link to={{ pathname: '/', state: { allowVisitLandning: true } }}>
              <TextLink color="white" title="welcome page" />
            </Link>{' '}
            for details.
          </Text>

          <Stack spacing={2} direction="row">
            <AboutCard
              onClick={() =>
                setInfo({
                  block: 'prices',
                  content: <ContentPrices />,
                  placement: 'product',
                })
              }
              icon={
                <IonIcon
                  icon={Icons.dollar}
                  style={{
                    fontSize: '16px',
                    color: '#dec745',
                    strokeWidth: '6px',
                  }}
                />
              }
              title="About prices"
              details="Why you get best possible offers →"
            />
            <AboutCard
              onClick={() =>
                setInfo({
                  block: 'reviews',
                  content: <ContentReviews />,
                  placement: 'product',
                })
              }
              icon={
                <IonIcon
                  icon={Icons.star}
                  style={{
                    fontSize: '18px',
                    color: '#dec745',
                  }}
                />
              }
              title="Reviews"
              details="Photos and customer opinions →"
            />
          </Stack>
          <Stack spacing={1} direction="row">
            <AboutCard
              onClick={() => setIsOpen(true)}
              icon={
                <IonIcon
                  icon={logoWhatsapp}
                  style={{
                    fontSize: '20px',
                    color: '#0ec254',
                    strokeWidth: '8px',
                  }}
                />
              }
              title="Get FREE consultation"
              details="We can organize your event or find specific event services based on your needs →"
            />
          </Stack>

          <Centralizer>
            <InstagramButton isMini />
            <Text
              fontSize="15px"
              color="var(--color-main-high)"
              extraStyle={{ marginTop: '5px', fontFamily: 'Montserrat' }}
            >
              Subscribe to stay in touch
            </Text>
          </Centralizer>
        </Stack>
      </IonCard>
    </>
  );
};
