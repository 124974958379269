import Centralizer from '../../../../../components/Centralizer/Centralizer';
import { getStorageUrl } from '../../../Utils/getStorageUrl';
import { InstagramButton } from '../../ActionButton/InstagramButton';
import s from './Reviews.module.scss';

type slide = {
  category: string;
  subcategory: string;
  src: string;
  desc: {
    text: string;
    author: string;
    date: string;
    authorPic: string;
  };
};

const reviewSlides: slide[] = [
  {
    category: 'Wedding',
    subcategory: 'Bali, Uluwatu',
    src: getStorageUrl('landing/reviews/1_wedding.webp'),
    desc: {
      text: 'It was perfect celebration, thank you! Event vendors and venue which you suggested 100% match with our vision and budget... Our families and friends were excited about the wedding ceremony and how beatiful and professionally organized it was. Venue was beautifully decorated and staff were polite and friendly. Thank you for make this day very special and unforgettable for us!',
      author: 'Luiza Alviar',
      date: 'June 2024',
      authorPic: getStorageUrl('landing/avatars/1_luiza.webp'),
    },
  },
  {
    category: 'Commercial festival',
    subcategory: 'Bali, Ubud',
    src: getStorageUrl('landing/reviews/2_festival.webp'),
    desc: {
      text: 'We doing international festivals and we tried Eventmaker to find sound and lighting for stage setup in Bali. As a result, we got probably the best possible equipment setup on the island, so our festival goes to another level. Guests were really surprised and said it was true magic evening ✨',
      author: 'Nullah Oladoyinbo',
      date: 'December 2023',
      authorPic: getStorageUrl('landing/avatars/2_nullah.webp'),
    },
  },

  {
    category: 'Birthday party',
    subcategory: 'Bali, Canggu',
    src: getStorageUrl('landing/reviews/3_birthday.webp'),
    desc: {
      text: 'My girlfriend had a birthday and I was wondering how to make it rememberable, then I found Eventmaker. I booked nice decor, villa, dj sound, catering and alcohol bar in one website. Very recommend.',
      author: 'Evgeniy Delyanin',
      date: 'May 2024',
      authorPic: getStorageUrl('landing/avatars/3_evgenii.webp'),
    },
  },
  {
    category: 'Corporate event',
    subcategory: 'Bali, Ubud',
    src: getStorageUrl('landing/reviews/4_corporate.webp'),
    desc: {
      text: 'I never see this level of service in Bali before!!! I organized team building event for our company in Bali. It was really simple with this service, guys lead all the event management process, so I used to only confirm options they suggest. Special thanks to musicians for their rockstar show 🤘',
      author: 'Ratna Wirata',
      date: 'July 2024',
      authorPic: getStorageUrl('landing/avatars/4_ratna.webp'),
    },
  },
  {
    category: 'Private festival',
    subcategory: 'Bali, Canggu',
    src: getStorageUrl('landing/reviews/5_private.webp'),
    desc: {
      text: 'After I got an idea to celebrate my 35’s birthday in Bali really great, I started to research options. This guys were awesome! They asked me about all my preferences and after that suggest a lot of venues and entertainments available in the whole Bali. Result was perfect, it was really best celebration in my life!',
      author: 'Dmitry Ugaharov',
      date: 'July 2024',
      authorPic: getStorageUrl('landing/avatars/5_dmitry.webp'),
    },
  },
  {
    category: 'Project Presentation',
    subcategory: 'Bali',
    src: getStorageUrl('landing/reviews/6_presentation.webp'),
    desc: {
      text: 'Our company contacted Eventmaker to plan presentation event for one of ours project. Collaboration was simple and always in time. Definitely will be working with them again.',
      author: 'Development Company',
      date: 'November 2023',
      authorPic: getStorageUrl('landing/avatars/6_anonym.jpg'),
    },
  },
];

export const ReviewsBlock = ({
  withAnimation,
}: {
  withAnimation?: boolean;
}) => {
  return (
    <div
      data-animation
      className={s.slider}
      // style={
      //   withAnimation
      //     ? {
      //         transform: 'translateY(100px)',
      //         transition: '1s',
      //         opacity: 0,
      //       }
      //     : undefined
      // }
    >
      {reviewSlides.map((slide, index) => (
        <div key={index} className={s.slide}>
          <span className={s.slide__categoty}>
            {slide.category}
            {slide.subcategory && (
              <p className={s.slide__subcategory}>{slide.subcategory}</p>
            )}
          </span>

          <img
            loading="lazy"
            src={slide.src}
            className={s.slidePic}
            alt={slide.src}
          />

          <div className={s.slide__desc}>
            <span className={s.slide__text}>{slide.desc.text}</span>
            <div className={s.slide__author}>
              <img className={s.authorPic} src={slide.desc.authorPic} alt="" />

              <div>
                <p className={s.autor}>{slide.desc.author}</p>
                <p className={s.date}>{slide.desc.date}</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Instagram */}
      <div key="instagram" className={s.slide}>
        <div
          className={s.slidePic}
          style={{
            width: '100%',
            padding: '20px',
            background: `linear-gradient(45deg,  
              #ffffff,  
              #f8f8f8,  
              #e0e0e0,  
              #c8c8c8,  
              #bfbfbf,  
              #d6d6d6,  
              #f0f0f0,  
              #ffffff  
          )`,
          }}
        >
          <Centralizer enableVerticalCentralization>
            <p
              style={{
                textAlign: 'center',
                margin: '5px 20px',
                color: 'black',
                fontSize: '18px',
                fontWeight: 600,
                fontFamily: 'Montserrat',
                whiteSpace: 'pre-wrap',
              }}
            >
              FIND MORE ON{`\n`}INSTAGRAM
            </p>{' '}
            <InstagramButton />
          </Centralizer>
        </div>

        <div
          className={s.slide__desc}
          style={{
            border: 'solid 2px #d6d6d6',
          }}
        >
          <span className={s.slide__text}>
            Hot offers, event overviews, video reports, industry news, and more
            — subscribe to stay updated!
          </span>
        </div>
      </div>
    </div>
  );
};
