import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import clsx from 'clsx';
import { closeOutline } from 'ionicons/icons';
import { PlacesAutocomplete } from '../Location/PlacesAutocomplete';
import Button from '../Buttons/Button/Button';

import sg from '../../styles/global.module.scss';
import s from './LocationUnavailable.module.scss';
import { useTypedDispatch } from '../../redux/hooks';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { usePointLocation } from '../../hooks/hook.location';

export const PhaseOne = ({
  modalRef,
  nextPhase,
}: {
  modalRef: React.RefObject<HTMLIonModalElement>;
  nextPhase: () => void;
}) => {
  const dispatch = useTypedDispatch();
  const location = usePointLocation();
  const isBali = location?.structured_formatting?.main_text === 'Bali';

  return (
    <>
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                modalRef.current?.dismiss(undefined, 'close');
              }}
            >
              <IonIcon
                icon={closeOutline}
                className={clsx(s.Modal__CloseButton)}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
        <p
          style={{
            fontWeight: 700,
            fontSize: '30px',
            width: '100%',
            textAlign: 'center',
            color: 'grey',
          }}
        >
          Wait...
        </p>

        <p
          className={clsx(s.Text__Content)}
          style={{ padding: '10px 0', lineHeight: '30px' }}
        >
          Right now we operate only in Bali 🌴 {'\n'}
          Where else would you like to use our platform?
        </p>

        <div
          style={{
            width: '300px',
            maxWidth: '100%',
            marginTop: '10px',
            marginBottom: '15px',
            alignSelf: 'center',
          }}
        >
          <PlacesAutocomplete mode="header" />
        </div>

        <div className={clsx(sg.Stack_Horizontal)}>
          <Button
            maxWidth="200px"
            text="Vote"
            color="secondary"
            fill="solid"
            onClick={async () => {
              // TODO: Intermediate vote
              if (isBali) {
                modalRef.current?.dismiss(undefined, 'submit');
                dispatch(
                  globalActionCreators.setToastObject({
                    message: 'Our platform available in Bali, enjoy!',
                    color: 'success',
                  })
                );
              } else {
                nextPhase();
              }

              await trackEvent(AnalyticEventsEnum.LOCATION__VOTE_SUBMITTED, {
                location: location?.description,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
