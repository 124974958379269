import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';
import { BUSINESS_LANDING_PAGE_URL } from '../../../contstants';

export enum AnchorEnum {
  ABOUT = 'ABOUT',
  REVIEWS = 'REVIEWS',
  HOW_WORKS = 'HOW_WORKS',
  FORM_REQUEST = 'FORM_REQUEST',
  FAQ = 'FAQ',
  JOIN = 'JOIN',
}

export const buildAnchorId = (v: AnchorEnum) => `navigation_anchor_${v}`;

export type Item = {
  name: string;
  anchor: AnchorEnum;
  toSource: 'customer' | 'business';
};

export const processAnchorClick = (
  anchor: AnchorEnum,
  behavior?: 'auto' | 'smooth'
) => {
  const el = document.getElementById(buildAnchorId(anchor));
  el?.scrollIntoView({
    behavior: behavior || 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

export const clickAnchor = async ({
  anchor,
  source,
  toSource,
  placement,
}: {
  anchor: AnchorEnum;
  source: 'customer' | 'business';
  toSource: 'customer' | 'business';
  placement: 'footer' | 'header' | 'menu';
}) => {
  await trackEvent(AnalyticEventsEnum.CLICK__LANDING_ANCHOR, {
    anchor,
    source,
    toSource,
    placement,
  });

  if (source === toSource) {
    processAnchorClick(anchor, placement === 'menu' ? 'auto' : 'smooth');
  } else {
    const nextUrl = source === 'customer' ? BUSINESS_LANDING_PAGE_URL : '/';
    window.location.href = `${nextUrl}?allowVisitLanding=1#${anchor}`;
  }
};

const configCustomer: Item[] = [
  {
    name: 'About',
    anchor: AnchorEnum.ABOUT,
    toSource: 'customer',
  },
  {
    name: 'Reviews',
    anchor: AnchorEnum.REVIEWS,
    toSource: 'customer',
  },
  {
    name: 'Consultation',
    anchor: AnchorEnum.FORM_REQUEST,
    toSource: 'customer',
  },
  {
    name: 'FAQ',
    anchor: AnchorEnum.FAQ,
    toSource: 'customer',
  },
  {
    name: 'Join',
    anchor: AnchorEnum.JOIN,
    toSource: 'customer',
  },
];

const configBusiness: Item[] = [
  {
    name: 'Introduction',
    anchor: AnchorEnum.ABOUT,
    toSource: 'business',
  },
  {
    name: 'How it works',
    anchor: AnchorEnum.HOW_WORKS,
    toSource: 'business',
  },
  {
    name: 'Transfer catalog',
    anchor: AnchorEnum.FORM_REQUEST,
    toSource: 'business',
  },
  {
    name: 'FAQ',
    anchor: AnchorEnum.FAQ,
    toSource: 'business',
  },
  {
    name: '↖️ For clients',
    anchor: AnchorEnum.ABOUT,
    toSource: 'customer',
  },
];

export const getHeaderConfig = ({
  source,
}: {
  source: 'customer' | 'business';
}) => (source === 'customer' ? configCustomer : configBusiness);

export const isClientOnBusinessPortal = ({
  source,
  item,
}: {
  item: Item;
  source: 'customer' | 'business';
}) => {
  return (
    item.anchor === AnchorEnum.ABOUT &&
    item.toSource === 'customer' &&
    source === 'business'
  );
};
