import React, { useState } from 'react';
import Page from '../components/Page/Page';
import { TextLink } from '../components/TextLink/TextLink';
import { CookiesModal } from '../components/CookiesBanner/CookiesModal';
import { useTypedSelector } from '../redux/hooks';
import { COUNTRIES_REQUIRES_CONSENT } from '../components/CookiesBanner/config';

const sxH2 = {
  marginTop: '20px',
  marginBottom: '10px',
  fontSize: '20px',
  fontWeight: 600,
};

const sxLinkColor = { color: '#8d8df2' };

const PrivacyPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const { detectedLocation } = useTypedSelector(s => s.location);
  const countryCode = detectedLocation?.countryCode;
  const consentRequired = COUNTRIES_REQUIRES_CONSENT.includes(
    countryCode || '??'
  );

  return (
    <Page>
      {isOpen && (
        <CookiesModal
          isInsidePrivacyPolicy
          isOpen={isOpen}
          close={close}
          consentRequired={consentRequired}
        />
      )}

      <h1 style={{ color: 'white', fontSize: '24px', fontWeight: 600 }}>
        Privacy Policy
      </h1>
      <p style={{ margin: '15px 0' }}>
        <strong>Effective Date:</strong> 19 March 2025
      </p>

      <p>
        At Eventmaker ("we," "us," or "our"), we are committed to protecting
        your privacy and personal data. This Privacy Policy outlines how we
        collect, use, disclose, and safeguard your personal information in
        compliance with the{' '}
        <strong>General Data Protection Regulation (GDPR)</strong> and the{' '}
        <strong>California Consumer Privacy Act (CCPA)</strong>.
      </p>
      <p>
        By using our website{' '}
        <a
          href="https://eventmaker.app"
          rel="external nofollow noopener noreferrer"
          target="_blank"
          style={sxLinkColor}
        >
          https://eventmaker.app
        </a>{' '}
        ("Site") and services, you agree to the collection and use of your
        personal information as described in this Privacy Policy.
      </p>

      <h2 style={sxH2}>1. Information We Collect</h2>
      <p>We collect the following types of personal information:</p>
      <ul>
        <li>
          <strong>Personal Identification Information:</strong> Name, email
          address, phone number, billing address, payment information, etc.
        </li>
        <li>
          <strong>Non-Personal Identification Information:</strong> IP address,
          browser type, device information, usage data, etc.
        </li>
        <li>
          <strong>Sensitive Data:</strong> If applicable, we may process
          sensitive data with explicit consent (e.g., health data, racial or
          ethnic data).
        </li>
      </ul>
      <p>
        Under the <strong>CCPA</strong>, personal information may include:
      </p>
      <ul>
        <li>Identifiers (e.g., name, email address, IP address).</li>
        <li>Commercial information (e.g., purchase history, preferences).</li>
        <li>Internet activity (e.g., browsing history, search history).</li>
        <li>Geolocation data.</li>
      </ul>

      <h2 style={sxH2}>2. Legal Basis for Processing Personal Data (GDPR)</h2>
      <p>
        Under the GDPR, we process your personal data based on the following
        legal grounds:
      </p>
      <ul>
        <li>
          <strong>Consent:</strong> You have given your explicit consent to
          process your data (e.g., subscribing to newsletters).
        </li>
        <li>
          <strong>Contractual Necessity:</strong> The processing is necessary to
          fulfill a contract (e.g., processing payments, delivering services).
        </li>
        <li>
          <strong>Legal Obligation:</strong> We may process personal data to
          comply with legal obligations (e.g., tax reporting).
        </li>
        <li>
          <strong>Legitimate Interests:</strong> We process data based on our
          legitimate interests (e.g., marketing, fraud prevention).
        </li>
      </ul>
      <p>
        Under the <strong>CCPA</strong>, we process your data for legitimate
        business purposes, including providing our services, improving user
        experience, processing payments, and communicating with you.
      </p>

      <h2 style={sxH2}>3. How We Use Your Information</h2>
      <p>We use your personal data for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our services.</li>
        <li>To personalize your experience and respond to your inquiries.</li>
        <li>To process transactions and manage billing.</li>
        <li>
          To send promotional materials, newsletters, and other communications
          (with your consent).
        </li>
        <li>To monitor usage and perform internal analytics.</li>
        <li>To comply with legal obligations.</li>
      </ul>
      <p>
        Under the <strong>CCPA</strong>, we may also use your information for
        the following additional purposes:
      </p>
      <ul>
        <li>To conduct research and analyze trends.</li>
        <li>For fraud detection and prevention.</li>
        <li>
          To comply with California law and requests from law enforcement.
        </li>
      </ul>

      <h2 style={sxH2}>4. Data Retention</h2>
      <p>
        We will retain your personal data for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy, or as required by law.
      </p>

      <h2 style={sxH2}>5. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal data. However, we may share your
        information with third parties in the following circumstances:
      </p>
      <ul>
        <li>
          With service providers who assist us in running our business (e.g.,
          payment processors, marketing platforms).
        </li>
        <li>
          To comply with legal obligations or protect our rights, property, and
          safety.
        </li>
      </ul>
      <p>
        Under <strong>CCPA</strong>, we do not "sell" your personal information
        in exchange for money, but we may share it with third parties for other
        business purposes (such as advertising and analytics). You can opt-out
        of such "sales" under the CCPA.
      </p>

      <h2 style={sxH2}>6. Data Security</h2>
      <p>
        We take reasonable measures to protect your personal data from
        unauthorized access, alteration, disclosure, or destruction. However, no
        method of transmission over the internet is 100% secure, and we cannot
        guarantee absolute security.
      </p>

      <h2 style={sxH2}>7. Your Rights Under GDPR and CCPA</h2>

      <h3>GDPR Rights</h3>
      <p>
        Under the <strong>GDPR</strong>, you have the following rights:
      </p>
      <ul>
        <li>
          <strong>Right of Access:</strong> You can request access to the
          personal data we hold about you.
        </li>
        <li>
          <strong>Right to Rectification:</strong> You can request the
          correction of any inaccuracies in your personal data.
        </li>
        <li>
          <strong>Right to Erasure (Right to be Forgotten):</strong> You can
          request the deletion of your personal data under certain conditions.
        </li>
        <li>
          <strong>Right to Restriction of Processing:</strong> You can request
          that we restrict the processing of your personal data under certain
          conditions.
        </li>
        <li>
          <strong>Right to Data Portability:</strong> You can request to receive
          your personal data in a structured, commonly used format and transmit
          it to another data controller.
        </li>
        <li>
          <strong>Right to Object:</strong> You can object to the processing of
          your personal data based on legitimate interests or for direct
          marketing purposes.
        </li>
        <li>
          <strong>Right to Withdraw Consent:</strong> If you have provided
          consent for specific data processing, you can withdraw it at any time.
        </li>
      </ul>

      <h3>CCPA Rights</h3>
      <p>
        Under the <strong>CCPA</strong>, if you are a California resident, you
        have the following additional rights:
      </p>
      <ul>
        <li>
          <strong>Right to Know:</strong> You can request information about the
          personal data we have collected, including the categories of personal
          information, the sources, and the purposes for which we use the data.
        </li>
        <li>
          <strong>Right to Deletion:</strong> You can request that we delete
          your personal data, subject to certain exceptions.
        </li>
        <li>
          <strong>Right to Opt-Out:</strong> You can opt-out of the "sale" of
          your personal information to third parties (if applicable).
        </li>
        <li>
          <strong>Right to Non-Discrimination:</strong> You have the right not
          to be discriminated against for exercising any of your CCPA rights.
        </li>
      </ul>
      <p>
        To exercise your rights under the GDPR or CCPA, please contact us using
        the details below.
      </p>

      <h2 style={sxH2}>8. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies on our website to
        improve your experience and analyze how our site is used. Below are the
        third-party services we use that place cookies on your device:
      </p>
      <ul>
        <li>
          <strong>Google Analytics:</strong> We use Google Analytics to collect
          data about how visitors interact with our website. This includes
          information such as pages visited, time spent on the site, and
          browsing patterns. This helps us analyze website traffic and improve
          user experience. For more information about how Google collects and
          processes data, please visit{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
            style={sxLinkColor}
          >
            Google’s Privacy Policy
          </a>
          .
        </li>
        <li>
          <strong>Google Ads:</strong> Google Ads cookies help us track the
          effectiveness of our advertising campaigns and display relevant ads to
          you based on your interests. These cookies may also help us retarget
          visitors who have previously visited our website. For more details,
          you can visit{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
            style={sxLinkColor}
          >
            Google’s Privacy Policy
          </a>
          .
        </li>
        <li>
          <strong>Meta Pixel (formerly Facebook Pixel):</strong> We use Meta
          Pixel to measure the effectiveness of our advertising on Facebook and
          Instagram. This cookie allows us to track actions on our site after
          viewing or interacting with our ads, helping us understand the
          performance of our marketing campaigns. For more information, please
          visit{' '}
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noreferrer"
            style={sxLinkColor}
          >
            Meta’s Privacy Policy
          </a>
          .
        </li>
      </ul>

      <p>
        You can manage your cookie preferences by adjusting your browser
        settings or using our{' '}
        <TextLink
          title="cookie management tool"
          onClick={() => {
            setIsOpen(true);
          }}
        />
        .
      </p>

      <h2 style={sxH2}>9. Transfers of Data</h2>
      <p>
        If we transfer your personal data outside of the European Economic Area
        (EEA), we ensure that appropriate safeguards are in place, such as using
        Standard Contractual Clauses, to protect your data in accordance with
        the GDPR.
      </p>

      <h2 style={sxH2}>10. Children’s Privacy</h2>
      <p>
        Our services are not intended for children under the age of 16. We do
        not knowingly collect personal data from individuals under 16. If we
        become aware that we have collected such data, we will take steps to
        delete it.
      </p>

      <h2 style={sxH2}>11. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, with the updated effective date. We encourage you
        to review this Privacy Policy periodically.
      </p>

      <h2 style={sxH2}>12. Contact Us</h2>
      <p>
        If you have any questions or concerns about how we process your personal
        data or wish to exercise your rights under the GDPR or CCPA, please
        contact us at:
      </p>
      <ul>
        <li>
          <strong>Company Name: EVENTMAKER, SOCIEDAD LIMITADA</strong>
        </li>
        <li>
          <strong>Registered At: BARCELONA, SPAIN</strong>
        </li>
        <li>
          <strong>
            Email Address:{' '}
            <a style={sxLinkColor} href="mailto:support@eventmaker.app">
              support@eventmaker.app
            </a>
          </strong>
        </li>
      </ul>

      <h3>CCPA-Specific Instructions:</h3>
      <p>
        To exercise your <strong>Right to Know</strong>,{' '}
        <strong>Right to Deletion</strong>, or <strong>Right to Opt-Out</strong>{' '}
        under CCPA, please submit a request to our email{' '}
        <a style={sxLinkColor} href="mailto:support@eventmaker.app">
          support@eventmaker.app
        </a>
        .
      </p>
      <p>
        We will respond to your request in accordance with CCPA guidelines,
        usually within 45 days.
      </p>
    </Page>
  );
};

export default PrivacyPage;
