import { isPlatform } from '@ionic/react';

export const IS_SAFARI =
  /^((?!chrome|android|crios|fxios|opr|edge).)*safari/i.test(
    navigator.userAgent
  ) && (navigator.vendor ? /Apple/i.test(navigator.vendor) : true);

const getSafariVersion = () => {
  const userAgent = navigator.userAgent;

  // Check if Safari is the browser
  const safariMatch = userAgent.match(/Version\/(\d+(\.\d+)*).*Safari/);

  if (safariMatch) {
    return safariMatch[1]; // Return the version number
  } else {
    return '0'; // Not Safari or not a valid version
  }
};

export const IS_SAFARI_MOBILE = isPlatform('mobile') && IS_SAFARI;
export const IS_SAFARI_DESKTOP = isPlatform('desktop') && IS_SAFARI;
export const IS_SAFARI_NEW = IS_SAFARI ? getSafariVersion() > '16' : null;

if (IS_SAFARI) {
  console.log('SAFARI_VERSION', getSafariVersion());
}

export const IS_IOS_PWA = isPlatform('pwa') && isPlatform('ios');

export const IS_IOS_NATIVE = isPlatform('capacitor') && isPlatform('ios');
export const IS_ANDROID_NATIVE =
  isPlatform('capacitor') && isPlatform('android');

export const IS_INSTAGRAM = /Instagram/.test(navigator.userAgent);
