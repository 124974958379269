import clsx from 'clsx';
import s from './TextLink.module.scss';

export type TextLinkProps = {
  title: string;
  onClick?: () => void;
  color?: 'black' | 'white';
};

export const TextLink = ({ title, onClick, color }: TextLinkProps) => {
  return (
    <span
      className={clsx(
        s.TextLink,
        color === 'black' ? s.TextLink__black : undefined,
        color === 'white' ? s.TextLink__white : undefined
      )}
      onClick={onClick}
    >
      {title}
    </span>
  );
};
