import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { animationSlide } from '../../../utils/animation';
import EventsPage from '../../EventsPage';
import { MessagesPage } from '../../MessagesPage';

const UserEventsRoutes: React.FC = () => {
  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path="/events/messages">
        <MessagesPage />
      </Route>

      <Route exact path="/events">
        <EventsPage />
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserEventsRoutes);
