import clsx from 'clsx';
import s from './AuthRequired.module.scss';
import React, { useMemo } from 'react';
import Button from '../../Buttons/Button/Button';
import { useTypedDispatch } from '../../../redux/hooks';
import { authActionCreators } from '../../../redux/slices/auth/actionCreators';
import Page from '../../Page/Page';
import CardAuth from '../CardAuth/CardAuth';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isPlatform } from '@ionic/core';
import { BUSINESS_LANDING_PAGE_URL } from '../../../pages/landing/contstants';

//
// If user is unathorized and trying to login into some specific
// private page - we need to show this screen firstly
//
const AuthRequired: React.FC = () => {
  const dispatch = useTypedDispatch();

  const location = useLocation();
  const flow = useMemo(() => {
    const search = new URLSearchParams(location.search);
    return search.get('flow');
  }, [location.search]);

  return (
    <Page
      enableImageBackground
      enableVerticalCentralization
      pageLimitSize="300px"
    >
      <CardAuth>
        {flow === '1' ? (
          <>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
              Joining Eventmaker is easy:
            </h3>

            <h4 style={{ textAlign: 'center', marginBottom: '5px' }}>
              1. Login or signup
            </h4>

            <h4 style={{ textAlign: 'center', marginBottom: '5px' }}>
              2. Register business account
            </h4>

            <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
              3. Add your services to the platform
            </h4>
          </>
        ) : (
          <>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
              Please login
            </h3>

            <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
              This page available only for registered users
            </h4>
          </>
        )}

        {!isPlatform('capacitor') && (
          <Link
            to={{
              pathname: '/',
              state: { allowVisitLandning: true },
            }}
          >
            <Button
              text={'About Eventmaker'}
              fill="solid"
              type="button"
              color="primary"
              maxWidth="200px"
              width="90%"
              className={clsx(s.Button_unique)}
            />
          </Link>
        )}

        <Button
          text={flow === '1' ? 'Continue' : 'Login'}
          fill="solid"
          type="button"
          color="secondary"
          onClick={() =>
            dispatch(authActionCreators.changeAuthPhase('signUp_Page'))
          }
          maxWidth="200px"
          width="90%"
        />
      </CardAuth>
    </Page>
  );
};

export default AuthRequired;
