export type CheckedSubcategoriesType = {
  [key: string]: boolean;
};

export type CheckedCategoriesType = {
  [key: string]: boolean | CheckedSubcategoriesType;
};

export type CheckboxAddress = {
  categoryId: string;
  subcategoryId?: string;
};

type ActionType =
  | 'changeChecked'
  | 'toggleAll'
  | 'setAllToTrue'
  | 'changeCheckedToTrue'
  | 'reset';

type Payload = {
  action: ActionType;
  checkboxAddress?: CheckboxAddress;
};

export function areAllNestedValuesDesired(obj: any, desired: boolean) {
  if (typeof obj !== 'object' || obj === null) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object') {
        if (!areAllNestedValuesDesired(value, desired)) {
          return false;
        }
      } else if (value !== desired) {
        return false;
      }
    }
  }

  return true;
}

function setAllNestedValuesTo(obj: any, to: boolean) {
  if (typeof obj !== 'object' || obj === null) return;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === 'object') {
        setAllNestedValuesTo(value, to);
      } else {
        obj[key] = to;
      }
    }
  }
}

const deepClone = (state: CheckedCategoriesType) =>
  JSON.parse(JSON.stringify(state)) as CheckedCategoriesType;

export const checkedCategoriesReducerWrapper = (
  updateProductCategoriesInForm: (
    productCategories: CheckedCategoriesType,
    options?: { shouldTouch?: boolean }
  ) => void,
  initialProductCategories: CheckedCategoriesType
) => {
  const initialValue = deepClone(initialProductCategories);

  const checkedCategoriesReducer = (
    _state: CheckedCategoriesType,
    payload: Payload
  ): CheckedCategoriesType => {
    const state = deepClone(_state); // to avoid readonly errors

    switch (payload.action) {
      case 'reset': {
        return initialValue;
      }
      case 'changeChecked': {
        if (!payload.checkboxAddress)
          throw new Error('Parameter checkboxAddres is required');

        const { categoryId, subcategoryId } = payload.checkboxAddress;

        if (typeof subcategoryId !== 'undefined') {
          // @ts-ignore
          state[categoryId][subcategoryId] =
            // @ts-ignore
            !state[categoryId][subcategoryId];
        } else {
          const subcategories = state[categoryId];
          if (typeof subcategories === 'boolean') {
            state[categoryId] = !state[categoryId];
          } else {
            const isAllChecked = areAllNestedValuesDesired(subcategories, true);
            setAllNestedValuesTo(subcategories, !isAllChecked);
          }
        }

        updateProductCategoriesInForm(state, { shouldTouch: true });
        return state;
      }
      case 'changeCheckedToTrue': {
        if (!payload.checkboxAddress)
          throw new Error('Parameter checkboxAddres is required');

        const { categoryId, subcategoryId } = payload.checkboxAddress;

        const subcategories = state[categoryId];
        const isEmptyParentCategory =
          typeof subcategoryId === 'undefined' &&
          typeof subcategories === 'boolean';
        if (!isEmptyParentCategory) {
          throw new Error('Action allowed only for "isEmptyParentCategory"');
        }

        state[categoryId] = true;

        updateProductCategoriesInForm(state);
        return state;
      }
      case 'toggleAll': {
        const isAllChecked = areAllNestedValuesDesired(state, true);
        setAllNestedValuesTo(state, !isAllChecked);
        updateProductCategoriesInForm(state, { shouldTouch: true });
        return state;
      }
      case 'setAllToTrue': {
        setAllNestedValuesTo(state, true);
        updateProductCategoriesInForm(state);
        return state;
      }
      default:
        throw Error('Unexpected error.');
    }
  };

  return checkedCategoriesReducer;
};

export type CheckedCategoriesDispatcherType = React.Dispatch<Payload>;
