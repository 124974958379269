import {
  informationOutline,
  peopleOutline,
  pricetagOutline,
} from 'ionicons/icons';
import { Product } from '../../models/product';
import { Icons } from '../../icons';
import { TagType } from './TaggedText';

export const getProductTags = ({
  details,
  peopleFrom,
  peopleTo,
  suitableEventCategories,
  priceDescription,
}: Product) => {
  const productTags: TagType[] = [];

  if (details) {
    productTags.push({
      icon: informationOutline,
      title: 'Description',
      value: details,
    });
  }

  // if (suitableEventCategories) {
  //   productTags.push({
  //     icon: Icons.like,
  //     title: 'Event categories',
  //     value: suitableEventCategories.map(ec => ec.name).join(', '),
  //   });
  // }

  // if (peopleFrom || peopleTo) {
  //   const fromPeopleCapacity = peopleFrom ? `from ${peopleFrom}` : '';
  //   const toPeopleCapacity = peopleTo ? `to ${peopleTo}` : '';
  //   productTags.push({
  //     icon: peopleOutline,
  //     title: 'Optimal people capacity',
  //     value: `${fromPeopleCapacity} ${toPeopleCapacity} persons`,
  //   });
  // }

  if (priceDescription) {
    productTags.push({
      icon: pricetagOutline,
      title: 'Price details',
      value: priceDescription,
    });
  }

  // if (square) {
  //   productTags.push({
  //     icon: squareOutline,
  //     title: 'Площадь:',
  //     value: `${square} м2`,
  //     priority: 2,
  //   });
  // }

  // if (location) {
  //   productTags.push({
  //     icon: locationOutline,
  //     title: 'Локация:',
  //     value: 'м. Комсомольская',
  //     priority: 1,
  //   });
  // }

  // if (address) {
  //   productTags.push({
  //     icon: homeOutline,
  //     title: 'Точный адрес:',
  //     value: 'г. Москва, ул. Ленина д.2',
  //     priority: 2,
  //   });
  // }

  // if (openingHoursFrom && openingHoursUntil) {
  //   productTags.push({
  //     icon: timeOutline,
  //     title: 'Часы работы:',
  //     value: `${openingHoursFrom}-${openingHoursUntil}`,
  //     priority: 2,
  //   });
  // }

  // if (noiseHoursFrom && noiseHoursUntil) {
  //   productTags.push({
  //     icon: musicalNotesOutline,
  //     title: 'Можно шуметь:',
  //     value: `${noiseHoursFrom}-${noiseHoursUntil}`,
  //     priority: 2,
  //   });
  // }

  // if (allowedWithAnimals) {
  //   productTags.push({
  //     icon: pawOutline,
  //     title: 'Можно с животными:',
  //     value: allowedWithAnimals ? 'Да' : 'Нет',
  //     priority: 2,
  //   });
  // }

  // if (accessibleForDisabledPeople) {
  //   productTags.push({
  //     icon: bodyOutline,
  //     title: 'Доступно для людей с инвалидностью:',
  //     value: accessibleForDisabledPeople ? 'Да' : 'Нет',
  //     priority: 2,
  //   });
  // }

  // if (allowedWithOwnDrinksAndFood) {
  //   productTags.push({
  //     icon: fastFoodOutline,
  //     title: 'Можно со своими напитками:',
  //     value: allowedWithOwnDrinksAndFood ? 'Да' : 'Нет',
  //     priority: 2,
  //   });
  // }

  return productTags;
};
