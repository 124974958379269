import React from 'react';
import s_Customer from './Numbers_Customer.module.scss';
import s_Business from './Numbers_Business.module.scss';
import { ActionButton } from '../../ActionButton/ActionButton';
import { useLandingPageContext } from '../../../context';
import { getAchievementsConfig } from './config';

export const Numbers = () => {
  const { source } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const dataPoints = getAchievementsConfig({ source });

  return (
    <section
      section-order={source === 'customer' ? 4 : 2}
      className={s.SectionAchivements}
    >
      {source === 'customer' ? (
        <h3 data-animation>
          Simple way <span>to plan</span> events
        </h3>
      ) : (
        <h3 data-animation>
          Why you should register <span>today</span>
        </h3>
      )}
      <div className={s.Achivements}>
        {dataPoints.map((data, index) => (
          <div key={index} data-animation className={s.Achivement}>
            <h5>{data.value}</h5>
            <p className={s.Achivement__title}>{data.title}</p>
            <p className={s.Achivement__body}>{data.body}</p>
          </div>
        ))}
      </div>

      <ActionButton animation />
    </section>
  );
};
