import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router';
import UserProductPage from './UserProductPage';
import { animationOpacity } from '../../../utils/animation';
import UserBusinessPage from './UserBusinessPage';
import UserAssistancePage from './UserAssistancePage';
import { usePointLocation } from '../../../hooks/hook.location';
import LoadingPage from '../../LoadingPage';
import UserCatalogResolver from './UserCatalogResolver';

const UserCatalogRoutes: React.FC = () => {
  const pointLocation = usePointLocation();

  return (
    <IonRouterOutlet ionPage animation={animationOpacity}>
      <Route exact path="/catalog/assistance">
        <UserAssistancePage />
      </Route>

      <Route exact path="/catalog/:locationName/business/:businessId">
        <UserBusinessPage />
      </Route>

      <Route exact path="/catalog/:locationName/:productId">
        <UserProductPage />
      </Route>

      {/* TODO Apply resolver on other pages as well */}
      <Route exact path={'/catalog/:locationName'}>
        <UserCatalogResolver />
      </Route>

      <Route exact path={'/catalog'}>
        {pointLocation ? (
          <Redirect to={`/catalog/${pointLocation.seoLine}`} />
        ) : (
          <LoadingPage />
        )}
      </Route>
    </IonRouterOutlet>
  );
};

export default React.memo(UserCatalogRoutes);
