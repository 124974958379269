import { LocationFullType } from '../components/Location/config';
import {
  convertProductCategoriesToQueryObject,
  convertQueryObjectToProductCategories,
  getInitialCheckedCategories,
} from '../components/ProductCategories/helpers';
import {
  SearchFormValues,
  SearchTypeEnum,
  SortByEnum,
} from '../components/SearchBlock/interface';
import { EventCategory } from '../models/event';
import { ProductCategory } from '../models/product';
import {
  BaseQueryParamsType,
  ByEventCategoryArgs,
  ByProductCategoryArgs,
  BySearchTermArgs,
  ProductCategoriesQueryParamsType,
  SearchArgsType,
} from './_types';

export const convertSearchArgsToQueryParams = (args: SearchArgsType) => {
  const baseQueryParams: BaseQueryParamsType = {
    type: args.type,
    place_id: args.location?.place_id,
  };

  // TODO ASAP: Encode location to URL

  // if (args.location) {
  //   baseQueryParams['city_id'] = args.city?.id;
  // }

  // "BY_KEYWORDS"
  if (args.type === SearchTypeEnum.BY_KEYWORDS) {
    baseQueryParams['search_term'] = args.searchTerm;
    if (args.businessId) {
      baseQueryParams['business_id'] = args.businessId;
    }
    return new URLSearchParams(baseQueryParams);
  }

  // "BY_EVENT_CATEGORY" or "BY_PRODUCT_CATEGORY"
  let productCategoriesQueryParams:
    | ProductCategoriesQueryParamsType
    | undefined = undefined;

  if (args.type === SearchTypeEnum.BY_EVENT_CATEGORY) {
    productCategoriesQueryParams = convertProductCategoriesToQueryObject(
      args.productCategories
    );
    if (args.eventCategory?.id) {
      baseQueryParams['event_category_id'] = args.eventCategory.id;
    }
  } else if (args.type === SearchTypeEnum.BY_PRODUCT_CATEGORY) {
    productCategoriesQueryParams = convertProductCategoriesToQueryObject({
      [args.productCategory.id]:
        args.productCategories[args.productCategory.id],
    });
    baseQueryParams['product_category_id'] = args.productCategory.id;
  }

  baseQueryParams['sort_by'] = args.sortBy;

  if (args.peopleCapacity) {
    baseQueryParams['people_capacity'] = String(args.peopleCapacity);
  }

  if (args.eventDuration && args.sortBy === SortByEnum.BY_PRICE) {
    baseQueryParams.event_duration = String(args.eventDuration);
  }

  return new URLSearchParams({
    ...baseQueryParams,
    ...productCategoriesQueryParams,
  });
};

export const convertQueryParamsToSearchArgs = (
  queryParams: BaseQueryParamsType & ProductCategoriesQueryParamsType,
  eventCategories: EventCategory[],
  productCategories: ProductCategory[],
  pointLocation: LocationFullType,
  urlPointLocation: LocationFullType,
  shouldUseUrlLocation: boolean
): SearchArgsType => {
  const searchType = queryParams.type || SearchTypeEnum.BY_EVENT_CATEGORY;

  const location = shouldUseUrlLocation ? urlPointLocation : pointLocation;

  let baseArgs: ByEventCategoryArgs | ByProductCategoryArgs | BySearchTermArgs =
    /* default */
    {
      type: SearchTypeEnum.BY_EVENT_CATEGORY,
      eventCategory: null,
    };

  if (searchType === SearchTypeEnum.BY_EVENT_CATEGORY) {
    const eventCategory = queryParams.event_category_id
      ? eventCategories.find(ec => ec.id === queryParams.event_category_id)
      : undefined;

    baseArgs = {
      type: searchType,
      eventCategory: eventCategory || null,
    } satisfies ByEventCategoryArgs;
  } else if (searchType === SearchTypeEnum.BY_PRODUCT_CATEGORY) {
    const productCategory = queryParams.product_category_id
      ? productCategories.find(pc => pc.id === queryParams.product_category_id)
      : undefined;

    if (productCategory) {
      baseArgs = {
        type: searchType,
        productCategory,
      } satisfies ByProductCategoryArgs;
    }
  } else if (searchType === SearchTypeEnum.BY_KEYWORDS) {
    baseArgs = {
      type: searchType,
      searchTerm: queryParams.search_term || '',
    } satisfies BySearchTermArgs;
  }

  /* EXTRA */
  const initialCheckedCategories =
    getInitialCheckedCategories(productCategories);

  /* All query params which looks like: "c_ProductVenue", "c_ProductDecoration"... */
  const queryParamsPresentingCategories = Object.entries(queryParams)
    .filter(
      ([key, value]) =>
        key.startsWith('c_') && key.slice(2) in initialCheckedCategories
    )
    .reduce((accum, [key, value]) => {
      accum[key] = value;
      return accum;
    }, {} as ProductCategoriesQueryParamsType);

  const checkedProductCategories = convertQueryObjectToProductCategories(
    queryParamsPresentingCategories,
    initialCheckedCategories,
    baseArgs.type === SearchTypeEnum.BY_PRODUCT_CATEGORY &&
      !!baseArgs.productCategory
  );

  // Alternatively it can be done through dedicated query param
  const isSubcategoriesFilterApplied =
    JSON.stringify(
      convertProductCategoriesToQueryObject(checkedProductCategories)
    ) !== '{}';

  const searchFormValues: SearchFormValues = {
    sortBy: queryParams.sort_by || SortByEnum.DEFAULT,
    peopleCapacity: Number(queryParams.people_capacity) || null,
    eventDuration: Number(queryParams.event_duration) || null,
    productCategories: checkedProductCategories,
    applySubCategoriesFilter: isSubcategoriesFilterApplied,
  };

  return { ...baseArgs, ...searchFormValues, location };
};
