import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { usePointLocation } from '../../../hooks/hook.location';
import LoadingPage from '../../LoadingPage';
import UserCatalogPage from './UserCatalogPage';
import { lockedLocation } from '../../../components/Location/PlacesAutocomplete';
import { LocationFullType } from '../../../components/Location/config';
import { useIonRouter } from '@ionic/react';
import { useTypedDispatch } from '../../../redux/hooks';
import { locationSlice } from '../../../redux/slices/location/slice';
import { sleep } from '../../../utils/sleep';

const UserCatalogResolver = () => {
  const router = useIonRouter();
  const location = useLocation();
  const match = useRouteMatch<{ locationName: string }>();
  const locationName = match.params.locationName;

  const dispatch = useTypedDispatch();

  const pointLocation = usePointLocation();
  const [previousPointLocation, setPreviousPointLocation] =
    useState<LocationFullType>();
  useEffect(() => {
    if (pointLocation) setPreviousPointLocation(pointLocation);
  }, [pointLocation]);

  const [urlPointLocation, setUrlPointLocation] = useState<LocationFullType>();

  // Url location used only once when CATALOG loaded first time
  const shouldUseUrlPointLocation = useRef(true);

  useEffect(() => {
    // if (!pointLocation) {
    //   return; // wait until loaded
    // }

    if (
      !!pointLocation &&
      !!previousPointLocation &&
      previousPointLocation.place_id !== pointLocation.place_id
    ) {
      const newPathname = location.pathname.replace(
        /[^/]+$/,
        pointLocation.seoLine
      );
      router.push(newPathname + location.search);
      return;
    }

    if (
      locationName &&
      locationName !== pointLocation?.seoLine &&
      shouldUseUrlPointLocation.current
    ) {
      // Async simulation
      // TODO ASAP: Contact google map API to get address based on "locationName"
      sleep(0).then(() => {
        const defaultPoint = {
          ...lockedLocation,
          structured_formatting: {
            ...lockedLocation.structured_formatting,
            main_text: 'Bal!',
          },
          coordinates: { lat: -8.3405, lng: 115.092 },
          countryCode: 'ID',
          seoLine: locationName,
        } satisfies LocationFullType;

        setUrlPointLocation(defaultPoint);
        dispatch(locationSlice.actions.setSelectedLocation(defaultPoint));
      });
    } else {
      setUrlPointLocation(pointLocation || undefined);
    }
  }, [pointLocation, locationName]);

  if (!locationName || !urlPointLocation) return <LoadingPage />;

  return (
    <UserCatalogPage
      urlPointLocation={urlPointLocation}
      shouldUseUrlPointLocation={shouldUseUrlPointLocation}
    />
  );
};

export default React.memo(UserCatalogResolver);
