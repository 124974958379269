import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { animationSlide } from '../../../utils/animation';
import { Route, useRouteMatch } from 'react-router';
import NotificationsPage from '../../NotificationsPage';

const BusinessNotificationsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <IonRouterOutlet ionPage animation={animationSlide}>
      <Route exact path={match.path}>
        <NotificationsPage isBusiness />
      </Route>
    </IonRouterOutlet>
  );
};

export default BusinessNotificationsRoutes;
