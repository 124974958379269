import React, { useCallback, useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { About } from './Components/Sections/About/About';
import { AreYouBusiness } from './Components/Sections/AreYouBusiness/AreYouBusiness';
import { EventTypes } from './Components/Sections/EventTypes/EventTypes';
import { EventTypesMobile } from './Components/Sections/EventTypes/EventTypesMobile';
import { Reviews } from './Components/Sections/Reviews/Reviews';
import { Ways } from './Components/Sections/Ways/Ways';
import { Numbers } from './Components/Sections/Numbers/Numbers';
import { FAQ } from './Components/Sections/FAQ/FAQ';
import { Footer } from './Components/Sections/Footer/Footer';
import { Form } from './Components/Sections/Form/Form';
import { HowItWorks } from './Components/Sections/HowItWorks/HowItWorks';
import { Header } from './Components/Sections/Header/Header';
import { HeaderMobile } from './Components/Sections/HeaderMobile/HeaderMobile';
import { processAnchorClick } from './Components/Sections/HeaderMobile/config';
import { IS_INSTAGRAM } from '../../utils/browser';
import { useIsBigScreen } from '../../hooks/hook.big-screen';
import { findMostVisibleSection } from './helpers';
import { IonContentCustomEvent, ScrollBaseDetail } from '@ionic/core';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { useLandingPageContext } from './context';
import debounce from 'lodash/debounce';

import './Styles/_index.scss';

export const CommonLanding = () => {
  useEffect(() => {
    const navHash = window.location.hash?.replace('#', '') as any;
    if (navHash) {
      setTimeout(() => {
        processAnchorClick(navHash, 'auto');
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname
        );
      }, 50);
    }
  }, []);

  const { isBigScreen } = useIsBigScreen();
  const { source } = useLandingPageContext();

  useEffect(() => {
    const Animation: IntersectionObserverCallback = entry => {
      entry.forEach(change => {
        if (change.isIntersecting) {
          change.target.classList.add('show-animation');
        }
      });
    };

    let observer = new IntersectionObserver(Animation, {
      threshold: [0.4],
    });
    let elements = document.querySelectorAll('[data-animation]');

    if (IS_INSTAGRAM) {
      elements.forEach(e => e.classList.add('show-animation'));
    } else {
      elements.forEach(elm => observer.observe(elm));
    }
  }, [isBigScreen]);

  const [activeSection, setActiveSection] = useState<HTMLElement>();
  const updateActiveSection = useCallback(
    debounce((event: IonContentCustomEvent<ScrollBaseDetail>) => {
      const section = findMostVisibleSection();
      setActiveSection(section || undefined);
    }, 200), // avoid activeSection change when anchor clicked
    []
  );

  const activeSectionNumber = activeSection?.getAttribute('section-order');

  useEffect(() => {
    (async function () {
      if (activeSectionNumber)
        await trackEvent(AnalyticEventsEnum.VIEW__LANDING_SECTION, {
          source,
          sectionOrder: activeSectionNumber,
        });
    })();
  }, [activeSectionNumber, source]);

  return (
    <IonPage className="myIonPage bodyBackground">
      <IonContent
        scrollEvents={true}
        onIonScrollStart={updateActiveSection}
        onIonScrollEnd={updateActiveSection}
      >
        {isBigScreen ? <Header /> : <HeaderMobile />}

        <About />

        {isBigScreen ? <EventTypes /> : <EventTypesMobile />}

        {source === 'customer' ? null : <Numbers />}
        {source === 'customer' ? <Reviews /> : <HowItWorks />}

        <Form />

        <Ways />

        {source === 'customer' ? <Numbers /> : null}

        <FAQ />

        <AreYouBusiness />

        <Footer />
      </IonContent>
    </IonPage>
  );
};
