import { UploadedFile } from '../@shared/file';
import { NullableNumber } from '../utils/types';
import { EventCategory } from './event';

export interface ProductCategory {
  id: string; // resourcetype
  polymorphicCtype: number; // id of model in db
  name: string;
  subcategories: [string, string][] | null;
}

export enum PriceTypeEnum {
  PER_HOUR = 'per_hour',
  PER_DAY = 'per_day',
}

export enum CurrenciesEnum {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD',
  AED = 'AED',
  IDR = 'IDR',
  THB = 'THB',
  RUB = 'RUB',
}

export const CURRENCIES_CONFIG: { [key in CurrenciesEnum]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  RUB: '₽',
  IDR: 'Rp',
  AED: 'Dh',
  THB: '฿',
  CAD: 'C$',
  AUD: 'A$',
};

export interface Product<T = { plain: false }> {
  id: string;
  business: string;
  category: T extends { plain: true } ? string : ProductCategory;
  subcategories: string[] | null | undefined;
  uploadedFiles: T extends { plain: true } ? string[] : UploadedFile[];
  title: string;
  details: string;
  priceCurrency: CurrenciesEnum;
  priceType: PriceTypeEnum | null;
  priceTo: NullableNumber;
  priceFrom: NullableNumber;
  priceMinRentTime: NullableNumber;
  priceDescription: string | null;
  peopleFrom: NullableNumber;
  peopleTo: NullableNumber;
  suitableEventCategories: T extends { plain: true }
    ? string[]
    : EventCategory[];

  // specific per category
  // square?: number | null;
  // location?: string | null;
  // address?: string | null;
  // openingHoursFrom?: string | null;
  // openingHoursUntil?: string | null;
  // noiseHoursFrom?: string | null;
  // noiseHoursUntil?: string | null;
  // allowedWithAnimals?: boolean | null;
  // accessibleForDisabledPeople?: boolean | null;
  // allowedWithOwnDrinksAndFood?: boolean | null;
}
