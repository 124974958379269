import clsx from 'clsx';
import s from './Input.module.scss';
import { IonInput } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import React, { useEffect, useRef, useState } from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { IS_SAFARI_MOBILE } from '../../../utils/browser';

/* Necessary for workaround */
import './index.scss';

interface InputProps {
  type: TextFieldTypes;
  autocomplete?: 'email' | 'current-password' | 'new-password' | 'off';
  inputmode:
    | 'email'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  maxlength?: number;
  applyWorkaround?: boolean;
  className?: string;
  className__active?: string;
  labelClassName?: string;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
  _setValue?: UseFormSetValue<any>;
}

const Input: React.FC<InputProps> = props => {
  const [active, setActive] = useState(false);
  const _registered = props._register(props._name);

  /* 
  This workaround help to fix bugs on Safari mobile...
  A - onIonChange
  1) Visit login page
  2) Click on email and select autosuggested value
  3) Keyboard offset does not disappear
  B - onBlur
  1) Visit signup page
  2) Click and release "password2" field realy fast
  3) Keyboard offset does not disappear
  */
  const applyWorkaround = props.applyWorkaround && IS_SAFARI_MOBILE;
  const [isDisabledByWorkaround, setIsDisabledByWorkaround] = useState(false);
  const contentRef = useRef<HTMLIonContentElement>();
  useEffect(() => {
    const content = document.getElementsByClassName(
      'myIonContent'
    )[0] as HTMLIonContentElement;
    contentRef.current = content;
  }, [contentRef]);

  return (
    <InputWrapper
      label={props.label}
      errorText={props._errorText}
      labelClassName={props.labelClassName}
      active={active}
    >
      <IonInput
        disabled={props.disabled || isDisabledByWorkaround}
        label=""
        className={clsx(
          s.IonInput,
          active ? s.IonInput_active : undefined,
          props.className,
          active ? props.className__active : undefined
        )}
        color="primary"
        inputmode={props.inputmode}
        autocomplete={props.autocomplete || 'off'}
        enterkeyhint="done"
        autofocus={false}
        type={props.type}
        placeholder={props.placeholder}
        maxlength={props.maxlength || 50}
        clearOnEdit={false}
        onKeyDown={e => {
          /* Safari IOS close keyboard by enter click */
          if (e.keyCode === 13) {
            let activeElement = document.activeElement as HTMLElement;
            activeElement && activeElement.blur && activeElement.blur();
          }
        }}
        onIonChange={() => {
          if (applyWorkaround) {
            setTimeout(() => {
              contentRef.current?.style.setProperty('--keyboard-offset', '0px');
            }, 100);
          }
        }}
        onBlur={e => {
          _registered.onBlur(e);
          if (applyWorkaround) {
            setIsDisabledByWorkaround(true);
            contentRef.current?.style.setProperty('--keyboard-offset', '0px');
            setTimeout(() => {
              setIsDisabledByWorkaround(false);
            }, 1000);
          }

          setActive(false);
        }}
        onFocus={() => setActive(true)}
        /* react-hook-form */
        name={_registered.name}
        ref={_registered.ref}
        onIonInput={_registered.onChange}
      />
    </InputWrapper>
  );
};

export default Input;
