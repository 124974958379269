import React from 'react';
import { useLandingPageContext } from '../../../context';
import clsx from 'clsx';
import { TextLink } from '../../../../../components/TextLink/TextLink';
import { ContactUsButton } from '../Form/Subforms/ContactUsButton';
import { ActionButton } from '../../ActionButton/ActionButton';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../utils/phoneNumber';
import { useTextContentContext } from '../../../../../components/TextContentModal/context';
import { ContentReviews } from '../../../../../components/ContentText/ContentReviews';
import { ContentExplanation } from '../../../../../components/ContentText/ContentExplanation';
import { AnalyticEventsEnum, trackEvent } from '../../../../../Analytics';
import { useIsBigScreen } from '../../../../../hooks/hook.big-screen';

import s from './Ways.module.scss';
import sg from '../../../../../styles/global.module.scss';

export const Ways = () => {
  const { isBigScreen } = useIsBigScreen();

  const { setInfo } = useTextContentContext();

  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  const content = `Hello, can you organize the event for me?`;
  const link = `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(
    content
  )}`;

  return (
    <section className={s.section} section-order={3}>
      <div className={s.container}>
        <h3
          data-animation
          className={s.section__title}
          style={{ whiteSpace: 'pre-wrap', lineHeight: 1.2 }}
        >
          All your <span>event</span>
          {`\n`}needs here:
        </h3>
        <div className={s.benefits}>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>1. Services</h4>

            <span className={s.benefit__body}>
              Venues, decor, catering and other services from different
              businesses
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <ActionButton
                  style={{ marginTop: isBigScreen ? '10px' : 0 }}
                  text="Explore Catalog"
                  ctaDetail="catalog"
                />
              </div>
            </span>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>2. Organizers</h4>

            <span className={s.benefit__body}>
              They will plan wedding, business conference or birthday party for
              you
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <ActionButton
                  style={{ marginTop: isBigScreen ? '10px' : 0 }}
                  text="Find Event Agency"
                  ctaDetail="agencies"
                />
              </div>
            </span>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>3. VIP concierge</h4>
            <span className={s.benefit__body}>
              Share your vision, and we'll bring your event to life in the best
              possible way
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink
                  title="▸ How we work"
                  onClick={() =>
                    setInfo({
                      block: 'explanations',
                      content: <ContentExplanation />,
                      placement: 'landing',
                    })
                  }
                />
                <TextLink
                  title="▸ Reviews & Instagram"
                  onClick={() =>
                    setInfo({
                      block: 'reviews',
                      content: <ContentReviews />,
                      placement: 'landing',
                    })
                  }
                />
                <ContactUsButton
                  style={{ marginTop: '25px' }}
                  onClick={async () => {
                    await trackEvent(AnalyticEventsEnum.LEAD);
                    await trackEvent(AnalyticEventsEnum.WHATS_APP_LEAD);
                    window.open(link);
                  }}
                />
              </div>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
