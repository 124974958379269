import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationFullType } from '../../../components/Location/config';

export enum LocationStorageKeys {
  DETECTED = 'location_detected',
  SELECTED = 'location_selected',
}

export interface LocationStoreState {
  isDetecting: boolean;
  detectedLocation: LocationFullType | null;
  selectedLocation: LocationFullType | null;
}

const initiaSubjectsState: LocationStoreState = {
  isDetecting: true,
  detectedLocation: localStorage.getItem(LocationStorageKeys.DETECTED)
    ? JSON.parse(localStorage.getItem(LocationStorageKeys.DETECTED)!)
    : null,
  selectedLocation: localStorage.getItem(LocationStorageKeys.SELECTED)
    ? JSON.parse(localStorage.getItem(LocationStorageKeys.SELECTED)!)
    : null,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState: initiaSubjectsState,
  reducers: {
    setIsDetecting(state: LocationStoreState, action: PayloadAction<boolean>) {
      state.isDetecting = action.payload;
    },
    setDetectedLocation(
      state: LocationStoreState,
      action: PayloadAction<LocationFullType>
    ) {
      state.detectedLocation = action.payload;
      localStorage.setItem(
        LocationStorageKeys.DETECTED,
        JSON.stringify(action.payload)
      );
    },
    setSelectedLocation(
      state: LocationStoreState,
      action: PayloadAction<LocationFullType>
    ) {
      state.selectedLocation = action.payload;
      localStorage.setItem(
        LocationStorageKeys.SELECTED,
        JSON.stringify(action.payload)
      );
    },
  },
});
