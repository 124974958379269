import {
  getSeoString,
  LocationFullType,
} from '../../components/Location/config';
import { convertLocationToBackendData } from './utils';

export function convertToOtherCase(o: any, convertTo: 'camel' | 'snake') {
  let newO: any;
  let origKey;
  let newKey;
  let value;

  if (typeof o === 'string') {
    return convertTo === 'camel' ? toCamelCase(o) : toSnakeCase(o);
  } else if (o instanceof Array) {
    return o.map(value => {
      if (typeof value === 'object') {
        value = convertToOtherCase(value, convertTo);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (Object.hasOwnProperty.call(o, origKey)) {
        //
        // From BE structure to "location" field
        if (origKey.startsWith('location_') && convertTo === 'camel') {
          if (origKey === 'location_place_id') {
            const mainText = o['location_main_text'];
            const secondaryText = o['location_secondary_text'];

            newO['location'] = {
              coordinates: o['location_point'],
              countryCode: o['location_country_code'],
              place_id: o['location_place_id'],
              seoLine: getSeoString({ mainText, secondaryText }),
              description: mainText + ', ' + secondaryText,
              structured_formatting: {
                main_text: o['location_main_text'],
                secondary_text: o['location_secondary_text'],
                main_text_matched_substrings: [],
              },
              matched_substrings: [],
              terms: [],
              types: [],
            } satisfies LocationFullType;
          } else {
            // No need, everything handled when iterate through "location_place_id";
          }
          continue;
        }

        // From "location" field to BE structure
        if (
          origKey === 'location' &&
          convertTo === 'snake' &&
          Object.hasOwnProperty.call(o[origKey], 'place_id')
        ) {
          const location = o[origKey] as LocationFullType;
          const backendLocation = convertLocationToBackendData(location);
          newO = { ...newO, ...backendLocation };
          continue; // Process rest fields
        }

        newKey =
          convertTo === 'camel' ? toCamelCase(origKey) : toSnakeCase(origKey);

        value = o[origKey];

        if (
          value &&
          (value instanceof Array ||
            (value !== null && value.constructor === Object))
        ) {
          value = convertToOtherCase(value, convertTo);
        }
        newO[newKey] = value;
      }
    }
  }

  return newO;
}

const toCamelCase = (s: string) => {
  return s
    .toLowerCase()
    .replace(/[-_][a-z0-9]/g, group => group.slice(-1).toUpperCase());
};

const toSnakeCase = (s: string) => {
  return s.replace(/[A-Z0-9]/g, letter => `_${letter.toLowerCase()}`);
};
