import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '../Buttons/Button/Button';
import { Stack } from '@mui/material';
import { IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';
import { IS_INSTAGRAM } from '../../utils/browser';
import { Text } from '../atoms/Text/Text';
import { TextLink } from '../TextLink/TextLink';
import { CookieCategoryToggle } from './CookieCategoryToggle';
import {
  CONSENT_KEY,
  ConsentState,
  essentialConsent,
  maximumConsent,
  onConsentChange,
} from './helpers';

import s from '../ConfirmationModal/Common.module.scss';

export type CookiesState = {
  necessary: boolean;
  preferences: boolean;
  marketing: boolean;
  analytics: boolean;
};

type Props = {
  isOpen: boolean;
  close: () => void;
  consentRequired: boolean;
  isInsidePrivacyPolicy?: boolean;
};

export const CookiesModal: React.FC<Props> = ({
  isOpen,
  close,
  consentRequired,
  isInsidePrivacyPolicy,
}) => {
  const savedConsetMode = JSON.parse(
    localStorage.getItem(CONSENT_KEY) || '{}'
  ) as Partial<ConsentState>;

  const [cookies, setCookies] = useState<CookiesState>({
    necessary: true,
    preferences:
      savedConsetMode.personalization_storage === 'granted' ||
      (!savedConsetMode.personalization_storage && !consentRequired),
    marketing:
      savedConsetMode.ad_storage === 'granted' ||
      (!savedConsetMode.ad_storage && !consentRequired),
    analytics:
      savedConsetMode.analytics_storage === 'granted' ||
      (!savedConsetMode.analytics_storage && !consentRequired),
  });

  return (
    <IonModal
      backdropDismiss={false}
      keepContentsMounted
      animated={!IS_INSTAGRAM}
      handle={false}
      isOpen={isOpen}
      className={clsx(s.IonModal)}
      style={{
        '--height': '70%',
        '--background': 'linear-gradient(to bottom, #fff, #ddd)',
      }}
    >
      <IonHeader>
        <IonToolbar mode="ios">
          <p className={clsx(s.Text__Header)} style={{ color: 'black' }}>
            Cookie Settings
          </p>
        </IonToolbar>
      </IonHeader>

      <IonContent className="withScrollbar">
        <Stack spacing={1} padding={1}>
          {!isInsidePrivacyPolicy && (
            <Text
              color="black"
              extraStyle={{ margin: '10px 0' }}
              fontSize="14px"
            >
              To understand how we use your data, please read our{' '}
              <TextLink
                color="black"
                title="privacy policy"
                onClick={() => {
                  window.open('/privacy', '_blank', 'noopener,noreferrer');
                }}
              />
              .
            </Text>
          )}
          <CookieCategoryToggle
            checked={true}
            title="Required cookies"
            description="Functionality and security cookies are necessary. The
                  website won't function properly without these cookies, they
                  are enabled by default and cannot be disabled."
          />
          <CookieCategoryToggle
            checked={cookies.preferences}
            title="Preferences"
            onChange={v => setCookies(s => ({ ...s, preferences: v }))}
            description="Preference cookies enables the web site to remember information to customize how the web site looks or behaves for each user. This may include storing selected currency, region, language or color theme."
          />
          <CookieCategoryToggle
            checked={cookies.analytics}
            title="Analytical cookies"
            onChange={v => setCookies(s => ({ ...s, analytics: v }))}
            description="Analytical cookies help us improve our website by collecting and reporting information on its usage."
          />
          <CookieCategoryToggle
            checked={cookies.marketing}
            title="Marketing cookies"
            onChange={v => setCookies(s => ({ ...s, marketing: v }))}
            description="Marketing cookies are used to track visitors across websites to allow publishers to display relevant and engaging advertisements. By enabling marketing cookies, you grant permission for personalized advertising across various platforms."
          />
        </Stack>
      </IonContent>

      <Stack spacing={0.5} padding={1}>
        <Button
          width="100%"
          text="Save"
          color="secondary"
          fill="solid"
          onClick={() => {
            onConsentChange({
              functionality_storage: 'granted',
              security_storage: 'granted',
              ad_storage: cookies.marketing ? 'granted' : 'denied',
              ad_user_data: cookies.marketing ? 'granted' : 'denied',
              ad_personalization: cookies.marketing ? 'granted' : 'denied',
              analytics_storage: cookies.analytics ? 'granted' : 'denied',
              personalization_storage: cookies.preferences
                ? 'granted'
                : 'denied',
            });
            close();
          }}
        />
        <Stack direction="row" spacing={1}>
          <Button
            width="100%"
            text="Reject non essential"
            color="primary"
            fill="solid"
            onClick={() => {
              onConsentChange(essentialConsent);
              close();
            }}
          />
          <Button
            width="100%"
            text="Accept all cookies"
            color="primary"
            fill="solid"
            onClick={() => {
              onConsentChange(maximumConsent);
              close();
            }}
          />
        </Stack>
      </Stack>
    </IonModal>
  );
};
