import React from 'react';
import s from './Reviews.module.scss';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { useLandingPageContext } from '../../../context';
import { ReviewsBlock } from './ReviewsBlock';
import { ActionButton } from '../../ActionButton/ActionButton';

export const Reviews = () => {
  const { source } = useLandingPageContext();

  if (source !== 'customer') return null;

  return (
    <section
      section-order={5}
      id={buildAnchorId(AnchorEnum.REVIEWS)}
      className={s.section}
    >
      <p className={s.textTop} data-animation>
        Reviews
      </p>

      <ReviewsBlock />

      <ActionButton />
    </section>
  );
};
