import { useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { globalSlice } from '../../redux/slices/global/slice';
import { ToastPriorityEnum } from '../../utils/enums';
import { close } from 'ionicons/icons';

const Toast: React.FC = () => {
  const [present] = useIonToast();
  const dispatch = useTypedDispatch();

  const toastQueue = useTypedSelector(s => s.global.toastQueue);
  const [isToastBusy, setIsToastBusy] = useState(false);

  useEffect(() => {
    if (!isToastBusy) {
      const nextPriority = toastQueue[ToastPriorityEnum.HIGH][0]
        ? ToastPriorityEnum.HIGH
        : toastQueue[ToastPriorityEnum.MEDIUM][0]
        ? ToastPriorityEnum.MEDIUM
        : toastQueue[ToastPriorityEnum.LOW][0]
        ? ToastPriorityEnum.LOW
        : null;

      if (nextPriority) {
        setIsToastBusy(true);
        const toast = toastQueue[nextPriority][0];
        present({
          position: 'top',
          buttons: [
            {
              icon: close,
              htmlAttributes: {
                'aria-label': 'close',
              },
            },
          ],
          duration: 5000,
          onDidDismiss: () => {
            dispatch(globalSlice.actions.shiftToastFormQueue(nextPriority));
            setIsToastBusy(false);
          },
          ...toast,
        });
      }
    }
  }, [isToastBusy, toastQueue, present, dispatch]);

  return null;
};

export default Toast;
