import { createContext, useContext } from 'react';
import { useIonRouter } from '@ionic/react';

export type LandingPageContextType = {
  source: 'customer' | 'business';
  onActionClick: () => void;
  actionButtonName: string;
  actionButtonShortName: string;
};

const LandingPageContext = createContext<LandingPageContextType | undefined>(
  undefined
);

/* Provider */
export const LandingPageContextProvider: React.FC<{
  source: 'business' | 'customer';
  children: React.ReactElement;
}> = ({
  children,
  source,
}: {
  children: React.ReactNode;
  source: 'customer' | 'business';
}) => {
  const router = useIonRouter();

  const actionButtonName =
    source === 'customer' ? 'Explore Catalog' : 'Register Now';
  const actionButtonShortName = source === 'customer' ? 'Explore' : 'Register';

  const onActionClick = () => {
    router.push(
      source === 'customer' ? '/catalog' : '/settings/register-business?flow=1'
    );
  };

  return (
    <LandingPageContext.Provider
      value={{
        source,
        onActionClick,
        actionButtonName,
        actionButtonShortName,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

/* Usage */
export const useLandingPageContext = () => {
  const context = useContext(LandingPageContext);

  if (!context) {
    throw new Error('"LandingPageContext" is not provided');
  }

  return context;
};
