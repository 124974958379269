import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import s from './Common.module.scss';
import { IS_INSTAGRAM } from '../../utils/browser';
import { CustomerHelpForm } from '../../pages/landing/Components/Sections/Form/CustomerHelpForm';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

type Props = {
  isOpen: boolean;
  close: () => void;
  placement: 'product' | 'assistance';
};

export const CustomerHelpModal: React.FC<Props> = ({
  isOpen,
  close,
  placement,
}) => {
  useEffect(() => {
    if (isOpen)
      (async function () {
        await trackEvent(AnalyticEventsEnum.VIEW__CONSULTATION_MODAL, {
          placement,
        });
      })();
  }, [isOpen, placement]);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={isOpen}
      onWillDismiss={close}
      className={clsx(s.IonModal)}
    >
      <IonHeader>
        <IonToolbar>
          <p
            className={clsx(s.Text__Header)}
            style={{ fontWeight: 600, fontFamily: 'Montserrat' }}
          >
            How we can help you?
          </p>
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* 
      Instead of IonContent:
      https://stackoverflow.com/questions/78862032/ion-content-causing-modal-height-to-collapse-to-0 
      */}
      <div
        style={{
          maxHeight: 'calc(100vh - 50px)',
          overflow: 'auto',
        }}
      >
        <CustomerHelpForm />
      </div>
    </IonModal>
  );
};
