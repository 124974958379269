import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { isPlatform } from '@ionic/react';
import { Link } from 'react-router-dom';

import s from './CardItem.module.scss';

export interface CardItemProps {
  children: React.ReactNode;
  routerLink: string;
  onClick?: () => void;
  flex?: boolean;
}

const CardItem: React.FC<CardItemProps> = ({
  children,
  routerLink,
  onClick,
  flex,
}) => {
  const history = useHistory();
  const hideBusinessInfo =
    history.location.pathname.includes('/products/business');

  return (
    <Link
      to={{ pathname: routerLink, state: { hideBusinessInfo } }}
      style={
        flex
          ? {
              flexFlow: 'column',
              display: 'flex',
              height: '100%',
            }
          : {}
      }
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        if (routerLink) {
          history.push(routerLink, { hideBusinessInfo });
        }
        if (onClick) onClick();
      }}
    >
      <div
        className={clsx(
          s.CardItem,
          isPlatform('desktop') ? s.CardItem__hover_animation : undefined,
          flex ? s.CardItem__flex : undefined
        )}
      >
        {children}
      </div>
    </Link>
  );
};

export default CardItem;
