import React, { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from '../../redux/hooks';
import OneCategory from './OneCategory';
import {
  CheckedCategoriesDispatcherType,
  CheckedCategoriesType,
  areAllNestedValuesDesired,
} from './dispatcher';
import { UseFormRegister } from 'react-hook-form';
import InputWrapper from '../Inputs/InputWrapper/InputWrapper';
import InputYAnchor from '../Inputs/InputYAnchor/InputYAnchor';
import { ProductCategory } from '../../models/product';
import { IS_SAFARI_MOBILE } from '../../utils/browser';
import Checkbox from '../Checkbox/Checkbox';

interface AllCategoriesProps {
  checkedCategoriesDispatcher: CheckedCategoriesDispatcherType;
  checkedCategories: CheckedCategoriesType;
  selectedProductCategory: ProductCategory | undefined;
  /* react-hook-form */
  _name: string;
  _register: UseFormRegister<any>;
  _errorText?: string;
  /* "productCategories" input field */
  __updateProductCategoriesInForm: (
    productCategories: CheckedCategoriesType
  ) => void;
}

const AllCategories: React.FC<AllCategoriesProps> = ({
  selectedProductCategory,
  checkedCategories,
  ...props
}) => {
  const categories = useTypedSelector(s => s.choices.productCategorySet);
  const [openedCategoryId, setOpenedCategoryId] = useState<string>();

  const _registered = props._register(props._name);

  const categoriesForChoose = useMemo(() => {
    if (selectedProductCategory) {
      return categories.filter(c => c.id === selectedProductCategory.id);
    } else {
      return categories;
    }
  }, [categories, selectedProductCategory]);

  useEffect(() => {
    if (
      selectedProductCategory &&
      selectedProductCategory.subcategories &&
      selectedProductCategory.subcategories.length
    ) {
      setOpenedCategoryId(selectedProductCategory.id);
    } else {
      setOpenedCategoryId(undefined);
    }
  }, [selectedProductCategory, setOpenedCategoryId]);

  const isEmptyParentCategory =
    !!selectedProductCategory && !selectedProductCategory.subcategories;

  const areAllTrue = useMemo(
    () => areAllNestedValuesDesired(checkedCategories, true),
    [checkedCategories]
  );

  const areAllFalse = useMemo(
    () => areAllNestedValuesDesired(checkedCategories, false),
    [checkedCategories]
  );

  return (
    <>
      <InputWrapper
        errorText={props._errorText}
        style={{ marginLeft: selectedProductCategory ? '-5px' : '5px' }}
      >
        <div style={{ paddingLeft: IS_SAFARI_MOBILE ? '15px' : '0px' }}>
          {!selectedProductCategory && (
            <Checkbox
              disabled={isEmptyParentCategory}
              indeterminate={!areAllTrue && !areAllFalse}
              text="All"
              isChecked={areAllTrue}
              onChange={e => {
                e.stopPropagation();
                props.checkedCategoriesDispatcher({
                  action: 'toggleAll',
                });
              }}
            />
          )}

          <div style={{ marginLeft: '10px', marginTop: '5px' }}>
            {categoriesForChoose.map(category => (
              <OneCategory
                key={category.id}
                isEmptyParentCategory={isEmptyParentCategory}
                isParentProductCategory={!!selectedProductCategory}
                category={category}
                isOpened={openedCategoryId === category.id}
                setOpenedCategoryId={setOpenedCategoryId}
                checkedCategories={checkedCategories}
                checkedCategoriesDispatcher={props.checkedCategoriesDispatcher}
                selectedProductCategory={selectedProductCategory}
              />
            ))}
          </div>
        </div>
      </InputWrapper>

      <InputYAnchor refCallback={_registered.ref} />
    </>
  );
};

export default AllCategories;
